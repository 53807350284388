import UserInterface from "../../interfaces/UserInterface";
import { ActionInterface } from "../Actions";
import { ActionTypes } from "../ActionTypes";

export default function UserProfile(state: UserInterface|null = null, action: ActionInterface<UserInterface>) {
  switch (action.type) {
    case ActionTypes.SetProfile:
      state = action.payload
      break

    case ActionTypes.Logout:
      state = null
      break

    default:
  }

  return state
}