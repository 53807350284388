import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"

const API2 = process.env.REACT_APP_API2 ?? 'http://localhost:8080/api/v1'

interface SpecialityListItem {
  id: number
  img: string|null
  title: string
}

interface AssignedSpecialityListItem {
  id?: number
  category_id: number
  online: 1|0
  offline: 1|0
  price_online: number
  price_offline: number
}

function SpecialityList(props: StoreProps) {
  const { token } = props

  const [specialityList, setSpecialityList] = useState<Array<SpecialityListItem>>([])
  const [assignedSpeciality, setAssignedSpeciality] = useState<Array<AssignedSpecialityListItem>>([])
  const [updateTimestamp, setUpdateTimesetamp] = useState<number>(Date.now())

  const updateOnline = (id: number, value: boolean) => {
    const assignedSpecialityListCopy = [...assignedSpeciality]
    const index = assignedSpecialityListCopy.findIndex(data => data.category_id === id)
    const foundSpecialityData = specialityList.find(data => data.id === id)

    if (index < 0 && foundSpecialityData) {
      assignedSpecialityListCopy.push({
        category_id: foundSpecialityData.id,
        online: 1,
        offline: 0,
        price_online: 0,
        price_offline: 0
      })
    } else {
      assignedSpecialityListCopy[index].online = value ? 1 : 0
    }

    setAssignedSpeciality(assignedSpecialityListCopy)
  }

  const updateOffline = (id: number, value: boolean) => {
    const assignedSpecialityListCopy = [...assignedSpeciality]
    const index = assignedSpecialityListCopy.findIndex(data => data.category_id === id)
    const foundSpecialityData = specialityList.find(data => data.id === id)

    if (index < 0 && foundSpecialityData) {
      assignedSpecialityListCopy.push({
        category_id: foundSpecialityData.id,
        online: 0,
        offline: 1,
        price_online: 0,
        price_offline: 0
      })
    } else {
      assignedSpecialityListCopy[index].offline = value ? 1 : 0
    }

    setAssignedSpeciality(assignedSpecialityListCopy)
  }

  const updatePriceOnline = (id: number, value: string) => {
    const assignedSpecialityListCopy = [...assignedSpeciality]
    const index = assignedSpecialityListCopy.findIndex(data => data.category_id === id)
    const foundSpecialityData = specialityList.find(data => data.id === id)

    if (index < 0 && foundSpecialityData) {
      assignedSpecialityListCopy.push({
        category_id: foundSpecialityData.id,
        online: 1,
        offline: 1,
        price_online: Number(value),
        price_offline: 0
      })
    } else {
      assignedSpecialityListCopy[index].price_online = Number(value)
    }

    setAssignedSpeciality(assignedSpecialityListCopy)
  }

  const updatePriceOffline = (id: number, value: string) => {
    const assignedSpecialityListCopy = [...assignedSpeciality]
    const index = assignedSpecialityListCopy.findIndex(data => data.category_id === id)
    const foundSpecialityData = specialityList.find(data => data.id === id)

    if (index < 0 && foundSpecialityData) {
      assignedSpecialityListCopy.push({
        category_id: foundSpecialityData.id,
        online: 1,
        offline: 1,
        price_online: 0,
        price_offline: Number(value)
      })
    } else {
      assignedSpecialityListCopy[index].price_offline = Number(value)
    }

    setAssignedSpeciality(assignedSpecialityListCopy)
  }

  const submitSpeciality = () => {
    if (!token) {
      return
    }

    const form = new URLSearchParams()
    
    assignedSpeciality.map(data => {
      form.append(`speciality[${data.category_id}][online]`, String(data.online))
      form.append(`speciality[${data.category_id}][offline]`, String(data.offline))
      form.append(`speciality[${data.category_id}][price_online]`, String(data.price_online))
      form.append(`speciality[${data.category_id}][price_offline]`, String(data.price_offline))

      return undefined
    })

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: form
    }

    fetch(`${API2}/nutritionist/speciality`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.message === 'ERROR') {
          throw new Error("Gagal menyimpan data!")
        }

        toast('Berhasil menyimpan data!', {type: 'success'})
        setUpdateTimesetamp(Date.now())
      })
      .catch(() => toast('Gagal menyimpan data!', {type: 'error'}))
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitopt: RequestInit = {
      headers: {
        Authorization: token
      }
    }

    const getSpecialityList = fetch(`${API2}/nutritionist/speciality`)
    const getAssignedSpeciality = fetch(`${API2}/nutritionist/assigned-speciality`, fetchInitopt)

    Promise.all([getSpecialityList, getAssignedSpeciality])
      .then(async responses => await Promise.all(responses.map(response => response.json())))
      .then(responses => {
        const [specialityResponse, assignedSpecialityResponse] = responses

        setSpecialityList(specialityResponse)
        setAssignedSpeciality(assignedSpecialityResponse)
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }, [token, updateTimestamp])

  return (
    <div className="mt-3">
      <div className="columns is-centered">
        <div className="column is-8">
          <div className="box">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h4 className="title is-size-4">Daftar Speciality</h4>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <button className="button is-small is-primary" onClick={submitSpeciality}>
                    <div className="icon is-small is-left">
                      <FontAwesomeIcon icon={faCheckCircle}/>
                    </div>
                    <span>Simpan</span>
                  </button>
                </div>
              </div>
            </div>
            <div style={{overflowX: 'auto'}}>
              <table className="table is-bordered is-fullwidth" style={{whiteSpace: 'nowrap'}}>
                <thead>
                  <tr>
                    <th>Speciality</th>
                    <th>Online</th>
                    <th>Offline</th>
                    <th style={{width: 170}}>Harga Online</th>
                    <th style={{width: 170}}>Harga Offline</th>
                  </tr>
                </thead>
                <tbody>
                  {specialityList.map(speciality => (
                    <tr key={speciality.id}>
                      <td>{speciality.title}</td>
                      <td>
                        <div className="checkbox">
                          <input
                            type="checkbox"
                            checked={assignedSpeciality.find(data => data.category_id === speciality.id && data.online === 1) !== undefined}
                            onChange={ev => updateOnline(speciality.id, ev.target.checked)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="checkbox">
                          <input
                            type="checkbox"
                            checked={assignedSpeciality.find(data => data.category_id === speciality.id && data.offline === 1) !== undefined}
                            onChange={ev => updateOffline(speciality.id, ev.target.checked)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="field has-addons">
                          <div className="control">
                            <button className="button is-static">Rp</button>
                          </div>
                          <div className="control">
                            <input
                              type="number"
                              className="input"
                              min={0}
                              value={assignedSpeciality.find(data => data.category_id === speciality.id)?.price_online ?? 0}
                              onChange={ev => updatePriceOnline(speciality.id, ev.target.value)}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="field has-addons">
                          <div className="control">
                            <button className="button is-static">Rp</button>
                          </div>
                          <div className="control">
                            <input
                              type="number"
                              className="input"
                              min={0}
                              value={assignedSpeciality.find(data => data.category_id === speciality.id)?.price_offline ?? 0}
                              onChange={ev => updatePriceOffline(speciality.id, ev.target.value)}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoreConnector(SpecialityList)