import { faCheckCircle, faMap, faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DateTime } from "luxon"
import { Fragment, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"
import ConvertStatus from "../../Tools.ts/ConvertStatus"

const API2 = process.env.REACT_APP_API2

interface CateringOrderItem {
  id: number
  vendor_name: string
  full_name: string
  phone: string
  email: string
  photo_selfie?: string
  food_name: string
  food_description: string
  price: number
  payment_fee: number
  session_start_date: string
  longitude: string
  latitude: string
  voucher_code: string
  payment_method: string
  status: string
}

function CateringOrder(props: StoreProps) {
  const { token } = props
  const history = useHistory()

  const [cateringOrderList, setCateringOrderList] = useState<Array<CateringOrderItem>>([])
  const [focusedId, setFocusedId] = useState<number>(0)
  const [focusedOrder, setFocusedOrder] = useState<CateringOrderItem>()
  const [updateStatusModal, setUpdateStatusModal] = useState<boolean>(false)
  const [detailModal, setDetailModal] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('')
  const [updateData, setUpdateData] = useState<number>(Date.now())

  const displayDetail = (id: number) => {
    const foundOrder = cateringOrderList.find(order => order.id === id)
    setDetailModal(true)
    setFocusedOrder(foundOrder)
  }

  const updateStatus = (id: number) => {
    setFocusedId(id)
    setUpdateStatusModal(true)
  }

  const submitStatus = () => {
    if (!token) {
      return
    }

    const form = new URLSearchParams()
    form.append('id', String(focusedId))
    form.append('status', status)

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: form
    }

    fetch(`${API2}/vendor/food-order/status`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal menyimpan status!', {type: 'error'})
          return
        }

        toast('Status berhasil disimpan!', {type: 'success'})
        setUpdateData(Date.now())
        setUpdateStatusModal(false)
      })
      .catch(() => toast('Gagal menyimpan status!', {type: 'error'}))
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    fetch(`${API2}/vendor/food-order`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        setCateringOrderList(response)
      })
      .catch(() => toast('Gagal mengambil data!', {type: 'error'}))
  }, [token, updateData])

  useEffect(() => {
    const url = new URLSearchParams(history.location.search)
    const open = url.get('open')

    if (open !== null && cateringOrderList.length > 0) {
      setFocusedOrder(cateringOrderList.find(data => data.id === Number(open)))
      setDetailModal(true)
    }
  }, [history.location.search, cateringOrderList])

  return (
    <Fragment>
      <div className="mt-3">
        <div className="box">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h4 className="title is-size-4">Pesanan Makanan</h4>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                
              </div>
            </div>
          </div>
          <div style={{overflowX: 'auto'}}>
            <table className="table is-bordered is-fullwidth" style={{whiteSpace: 'nowrap'}}>
              <thead>
                <tr>
                  <th>Pemesan</th>
                  <th>No. WA</th>
                  <th>Email</th>
                  <th>Harga</th>
                  <th>Lokasi</th>
                  <th>Status</th>
                  <th style={{width: 200}}>Aksi</th>
                </tr>
              </thead>
              <tbody>
                {cateringOrderList.map(order => (
                  <tr key={order.id}>
                    <td>{order.full_name}</td>
                    {/* <td>{order.phone}</td> */}
                    <td>
                      <a href={`https://wa.me/${order.phone.match(/^62/) !== null ? order.phone : '62' + order.phone.substring(1)}`} target="_blank" rel="noreferrer">{order.phone}</a>
                    </td>
                    <td>{order.email}</td>
                    <td>{order.price}</td>
                    <td>{order.longitude !== null ? <a href={`https://www.google.com/maps/search/?api=1&query=${order.latitude},${order.longitude}&zoom=12`} target="_blank" rel="noreferrer">Google Maps <FontAwesomeIcon icon={faMap}/></a> : '-'}</td>
                    <td>{ConvertStatus(order.status)}</td>
                    <td>
                      <button className="button is-small is-primary" onClick={() => displayDetail(order.id)}>
                        <span>Detail</span>
                      </button>
                      <button className="button is-small is-primary ml-1" onClick={() => updateStatus(order.id)}>
                        <div className="icon is-small is-left">
                          <FontAwesomeIcon icon={faPencilAlt}/>
                        </div>
                        <span>Update Status</span>
                      </button>
                    </td>
                  </tr>
                ))}
                {cateringOrderList.length < 1 && (
                  <tr>
                    <td colSpan={8} style={{textAlign: 'center'}}>Belum ada order</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {updateStatusModal && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setUpdateStatusModal(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">Ubah Status</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-small" onClick={() => setUpdateStatusModal(false)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTimes}/>
                      </div>
                      <span>Batal</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Pilih Status</label>
                <div className="select is-fullwidth">
                  <select onChange={ev => setStatus(ev.target.value)}>
                    <option value="">Pilih status</option>
                    <option value="PROCESSED">Diproses</option>
                    <option value="SENT">Dikirim</option>
                    <option value="DONE">Selesai</option>
                  </select>
                </div>
              </div>
              <div className="has-text-centered">
                <button className="button is-small is-primary ml-1" onClick={submitStatus}>
                  <div className="icon is-small is-left">
                    <FontAwesomeIcon icon={faCheckCircle}/>
                  </div>
                  <span>Terapkan</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {detailModal && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setDetailModal(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">Detail Order Catering</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-small is-default" onClick={() => setDetailModal(false)}>
                      <span>Tutup</span>
                    </button>
                  </div>
                </div>
              </div>
              <table className="table">
                <tbody>
                  <tr>
                    <td style={{width: 200}}>Nama Vendor</td>
                    <td className="is-narrow">:</td>
                    <td>{focusedOrder?.vendor_name}</td>
                  </tr>
                  <tr>
                    <td>Nama Customer</td>
                    <td>:</td>
                    <td>{focusedOrder?.full_name}</td>
                  </tr>
                  <tr>
                    <td>No. WA Customer</td>
                    <td>:</td>
                    <td>
                      <a href={`https://wa.me/${focusedOrder?.phone.match(/^62/) !== null ? focusedOrder?.phone : '62' + focusedOrder.phone.substring(1)}`}>{focusedOrder?.phone}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Email Customer</td>
                    <td>:</td>
                    <td>
                      {focusedOrder?.email}
                    </td>
                  </tr>
                  <tr>
                    <td>Menu</td>
                    <td>:</td>
                    <td>{focusedOrder?.food_name}</td>
                  </tr>
                  <tr>
                    <td>Lokasi</td>
                    <td>:</td>
                    <td><a href={`https://www.google.com/maps/search/?api=1&query=${focusedOrder?.latitude},${focusedOrder?.longitude}&zoom=12`} target="_blank" rel="noreferrer">Google Maps <FontAwesomeIcon icon={faMap}/></a></td>
                  </tr>
                  <tr>
                    <td>Tanggal</td>
                    <td>:</td>
                    <td>{DateTime.fromJSDate(new Date(focusedOrder?.session_start_date ?? '')).setLocale('id-ID').toLocaleString(DateTime.DATE_FULL)}</td>
                  </tr>
                  <tr>
                    <td>Voucher</td>
                    <td>:</td>
                    <td>{focusedOrder?.voucher_code}</td>
                  </tr>
                  <tr>
                    <td>Metode Pembayaran</td>
                    <td>:</td>
                    <td>{focusedOrder?.payment_method}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>:</td>
                    <td>{ConvertStatus(focusedOrder?.status ?? '-')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default StoreConnector(CateringOrder)