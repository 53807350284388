import { faCheckCircle, faEye, faPencilAlt, faPlusCircle, faTimesCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"

const API2 = process.env.REACT_APP_API2

export interface TrainerListItemInterface {
  id?: number
  name: string
  email: string
  description: string
  gender: 'M'|'F',
  address: string
  latitude: string
  longitude: string
  no_ktp: string
  no_npwp: string
  no_rek: string
  phone: string
  photo_cover?: string
  photo_trainer?: string
  photo_ktp?: string
  photo_npwp?: string
  user_id?: number
  bank_id?: number
  link_zoom?: string
}

interface BankListItem {
  id: number
  name: string
}

interface TrainerFormResponse extends TrainerListItemInterface {
  password: string
  uploadedPhotoKTP?: File
  uploadedPhotoNPWP?: File
  uploadedCover?: File
  uploadedPhotoTrainer?: File
}

interface TrainerFormProps extends StoreProps {
  trainer?: TrainerListItemInterface
  onSubmit: (data: TrainerFormResponse) => void
  onCancel: () => void
}

function TrainerFormEl(props: TrainerFormProps) {
  const { token, trainer, onCancel, onSubmit } = props

  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [description, setDesscription] = useState<string>('')
  const [gender, setGender] = useState<string>('M')
  const [address, setAddress] = useState<string>('')
  const [latitude, setLatitude] = useState<string>('')
  const [longitude, setLongitude] = useState<string>('')
  const [noKTP, setNoKTP] = useState<string>('')
  const [noNPWP, setNoNPWP] = useState<string>('')
  const [noRek, setNoRek] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [photoCover, setPhotoCover] = useState<string>()
  const [photoTrainer, setPhotoTrainer] = useState<string>()
  const [photoKTP, setPhotoKTP] = useState<string>()
  const [photoNPWP, setPhotoNPWP] = useState<string>()
  const [bankId, setBankId] = useState<number>()
  const [linkZoom, setLinkZoom] = useState<string>()

  const [uploadedPhotoKTP, setUploadedPhotoKTP] = useState<File>()
  const [uploadedPhotoNPWP, setUploadedPhotoNPWP] = useState<File>()
  const [uploadedCover, setUploadedCover] = useState<File>()
  const [uploadedPhotoTrainer, setUploadedPhotoTrainer] = useState<File>()

  const [bankList, setBankList] = useState<Array<BankListItem>>([])
  const [emailValid, setEmailValid] = useState<boolean>(true)

  const uploadHandler = (
    file: FileList,
    previewHandler: Dispatch<SetStateAction<string|undefined>>,
    stateHandler: Dispatch<SetStateAction<File|undefined>>
  ) => {
    const reader = new FileReader()

    if (file[0].size > 1000000) {
      toast('Ukuran file maksimal adalah 1MB', {type: 'error'})
      return
    }

    reader.onload = (ev: ProgressEvent<FileReader>) => {
      const result = ev.target?.result

      if (!result) {
        return
      }

      previewHandler(result.toString())
      stateHandler(file[0])
    }

    reader.readAsDataURL(file[0])
  }

  const submit = () => {
    if (!emailValid && trainer === undefined) {
      toast('Email sudah digunakan akun lain!', {type: 'error'})
      return
    }

    onSubmit({
      name: name,
      email: email,
      phone: phone,
      description: description,
      password: password,
      gender: gender === 'M' ? 'M' : 'F',
      address: address,
      latitude: latitude,
      longitude: longitude,
      no_ktp: noKTP,
      no_npwp: noNPWP,
      no_rek: noRek,
      bank_id: bankId,
      uploadedPhotoKTP: uploadedPhotoKTP,
      uploadedPhotoNPWP: uploadedPhotoNPWP,
      uploadedCover: uploadedCover,
      uploadedPhotoTrainer: uploadedPhotoTrainer,
      link_zoom: linkZoom
    })
  }

  useEffect(() => {
    if (trainer === undefined) {
      return
    }

    setName(trainer.name)
    setEmail(trainer.email)
    setDesscription(trainer.description)
    setGender(trainer.gender)
    setAddress(trainer.address)
    setLatitude(trainer.latitude)
    setLongitude(trainer.longitude)
    setNoKTP(trainer.no_ktp)
    setNoNPWP(trainer.no_npwp)
    setNoRek(trainer.no_rek)
    setPhone(trainer.phone)
    setPhotoCover(trainer.photo_cover)
    setPhotoTrainer(trainer.photo_trainer)
    setPhotoKTP(trainer.photo_ktp)
    setPhotoNPWP(trainer.photo_npwp)
    setBankId(trainer.bank_id)
    setLinkZoom(trainer.link_zoom)
  }, [trainer])

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        Authorization: token
      }
    }

    fetch(`${API2}/administration/bank`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal mengambil data!', {type: 'error'})
          return
        }

        setBankList(response)
      })
      .catch(() => toast('Terjadi kesalahan jaringan!', {type: 'error'}))
  }, [token])

  useEffect(() => {
    fetch(`${API2}/check-email?email=${email}`)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal memverifikasi email!', {type: 'error'})
          return
        }

        setEmailValid(!response.result.exists)
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }, [email])

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={onCancel}></div>
      <div className="modal-content">
        <div className="box">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h4 className="title is-size-4">{trainer === undefined ? 'Tambah' : 'Ubah'} Trainer</h4>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <button className="button is-small is-primary" onClick={submit}>
                  <div className="icon is-small is-left">
                    <FontAwesomeIcon icon={faCheckCircle}/>
                  </div>
                  <span>Simpan</span>
                </button>
                <button className="button is-small is-default ml-1" onClick={onCancel}>
                  <div className="icon is-small is-left">
                    <FontAwesomeIcon icon={faTimesCircle}/>
                  </div>
                  <span>Batal</span>
                </button>
              </div>
            </div>
          </div>
          <div className="field">
            <label htmlFor="">Nama</label>
            <div className="control">
              <input type="text" className="input" value={name} onChange={ev => setName(ev.target.value)}/>
            </div>
          </div>
          <div className="field">
            <label htmlFor="">Email</label>
            <div className="control">
              <input type="email" className="input" value={email} onChange={ev => setEmail(ev.target.value)} />
            </div>
          </div>
          <div className="field">
            <label htmlFor="">Password</label>
            <div className="control">
              <input type="password" className="input" onChange={ev => setPassword(ev.target.value)} />
            </div>
          </div>
          <div className="field">
            <label htmlFor="">Deskripsi</label>
            <div className="control">
              <textarea className="textarea" value={description} onChange={ev => setDesscription(ev.target.value)}/>
            </div>
          </div>
          <div className="field">
            <label htmlFor="">Link Zoom</label>
            <div className="contro">
              <input type="text" className="input" value={linkZoom ?? ''} onChange={ev => setLinkZoom(ev.target.value)} />
            </div>
          </div>
          <div className="field">
            <label htmlFor="">Gender</label>
            <div className="select is-fullwidth">
              <select value={gender} onChange={ev => setGender(ev.target.value)}>
                <option value="M">Pria</option>
                <option value="F">Wanita</option>
              </select>
            </div>
          </div>
          <div className="field">
            <label htmlFor="">Alamat</label>
            <div className="control">
              <input type="text" className="input" value={address} onChange={ev => setAddress(ev.target.value)} />
            </div>
          </div>
          <div className="columns mt-0">
            <div className="column">
              <label htmlFor="">Latitude</label>
              <div className="control">
                <input
                  type="number"
                  className="input"
                  value={latitude}
                  onChange={ev => setLatitude(ev.target.value)}
                  step={0.000001}
                />
              </div>
            </div>
            <div className="column">
              <label htmlFor="">Longitude</label>
              <div className="control">
                <input
                  type="number"
                  className="input"
                  value={longitude}
                  onChange={ev => setLongitude(ev.target.value)}
                  step={0.000001}
                />
              </div>
            </div>
          </div>
          <div className="field">
            <label htmlFor="">No. HP</label>
            <div className="control">
              <input type="text" className="input" value={phone} onChange={ev => setPhone(ev.target.value)}/>
            </div>
          </div>
          <div className="columns mt-1">
            <div className="column">
              <div className="field">
                <label htmlFor="">No. Rekening</label>
                <div className="control">
                  <input type="text" className="input" value={noRek} onChange={ev => setNoRek(ev.target.value)} />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label htmlFor="">Bank</label>
                <div className="select is-fullwidth">
                  <select value={bankId} onChange={ev => setBankId(Number(ev.target.value))}>
                    <option value="">Pilih Bank</option>
                    {bankList.map(bank => (
                      <option value={bank.id} key={bank.id}>{bank.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="columns mt-0">
            <div className="column">
              <div className="field">
                <label htmlFor="">No. KTP</label>
                <div className="control">
                  <input type="text" className="input" value={noKTP} onChange={ev => setNoKTP(ev.target.value)} />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label htmlFor="">No. NPWP</label>
                <div className="control">
                  <input type="text" className="input" value={noNPWP} onChange={ev => setNoNPWP(ev.target.value)} />
                </div>
              </div>
            </div>
          </div>
          <div className="columns mt-0">
            <div className="column">
              <div className="field">
                <label htmlFor="">Foto KTP</label>
                <div className="control">
                  <input
                    type="file"
                    className="input"
                    accept="image/*"
                    onChange={ev => ev.target.files !== null && uploadHandler(ev.target.files, setPhotoKTP, setUploadedPhotoKTP)}
                  />
                </div>
              </div>
              {photoKTP && (
                <img src={photoKTP} alt="" className="image mt-1" />
              )}
            </div>
            <div className="column">
              <div className="field">
                <label htmlFor="">Foto NPWP</label>
                <div className="control">
                  <input
                    type="file"
                    className="input"
                    accept="image/*"
                    onChange={ev => ev.target.files !== null && uploadHandler(ev.target.files, setPhotoNPWP, setUploadedPhotoNPWP)}
                  />
                </div>
              </div>
              {photoNPWP && (
                <img src={photoNPWP} alt="" className="image mt-1" />
              )}
            </div>
          </div>
          <div className="columns mt-0">
            <div className="column">
              <div className="field">
                <label htmlFor="">Cover</label>
                <div className="control">
                  <input
                    type="file"
                    className="input"
                    accept="image/*"
                    onChange={ev => ev.target.files !== null && uploadHandler(ev.target.files, setPhotoCover, setUploadedCover)}
                  />
                </div>
              </div>
              {photoCover && (
                <img src={photoCover} alt="" className="image mt-1" />
              )}
            </div>
            <div className="column">
              <div className="field">
                <label htmlFor="">Foto Trainer</label>
                <div className="control">
                  <input
                    type="file"
                    className="input"
                    accept="image/*"
                    onChange={ev => ev.target.files !== null && uploadHandler(ev.target.files, setPhotoTrainer, setUploadedPhotoTrainer)}
                  />
                </div>
              </div>
              {photoTrainer && (
                <img src={photoTrainer} alt="" className="image mt-1" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const TrainerForm = StoreConnector(TrainerFormEl)

function TrainerList(props: StoreProps) {
  const { token } = props

  const [trainerList, setTrainerList] = useState<Array<TrainerListItemInterface>>([])
  const [focusedTrainer, setFocusedTrainer] = useState<number>()
  const [findQuery, setFindQuery] = useState<string>('')
  const [showTrainerForm, setTrainerFormVisibility] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(Date.now())

  const trainerForm = (id?: number) => {
    setFocusedTrainer(id)
    setTrainerFormVisibility(true)
  }

  const submitTrainer = (trainer: TrainerFormResponse) => {
    if (!token) {
      return
    }

    const {
      name,
      email,
      password,
      address,
      latitude,
      longitude,
      description,
      gender,
      no_ktp,
      no_npwp,
      no_rek,
      bank_id,
      phone,
      uploadedCover,
      uploadedPhotoKTP,
      uploadedPhotoTrainer,
      uploadedPhotoNPWP,
      link_zoom
    } = trainer

    const form = uploadedCover || uploadedPhotoKTP || uploadedPhotoTrainer || uploadedPhotoNPWP ? new FormData() : new URLSearchParams()

    if (focusedTrainer !== undefined) {
      form.append('id', String(focusedTrainer))
    }

    form.append('name', name)
    form.append('email', email)
    form.append('password', password)
    form.append('address', address)
    form.append('latitude', latitude)
    form.append('longitude', longitude)
    form.append('description', description)
    form.append('gender', gender)
    form.append('no_ktp', no_ktp)
    form.append('no_npwp', no_npwp)
    form.append('no_rek', no_rek)
    form.append('bank_id', String(bank_id))
    form.append('phone', phone)

    if (link_zoom) {
      form.append('link_zoom', link_zoom)
    }

    if (uploadedCover) {
      form.append('cover', uploadedCover)
    }

    if (uploadedPhotoTrainer) {
      form.append('photo_trainer', uploadedPhotoTrainer)
    }

    if (uploadedPhotoKTP) {
      form.append('photo_ktp', uploadedPhotoKTP)
    }

    if (uploadedPhotoNPWP) {
      form.append('photo_npwp', uploadedPhotoNPWP)
    }

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: form
    }

    fetch(`${API2}/administration/trainer`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal menyimpan data!', {type: 'error'})
          return
        }

        toast('Data berhasil disimpan!', {type: 'success'})
        setTrainerFormVisibility(false)
        setUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }

  const removeTrainer = (id?: number) => {
    if (!window.confirm('Anda yakin menghapus data ini?') || !token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'DELETE',
      headers: {
        Authorization: token
      }
    }

    fetch(`${API2}/administration/trainer/${id}`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal menghapus data!', {type: 'error'})
          return
        }

        toast('Berhasil menghapus data!', {type: 'success'})
        setUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        Authorization: token
      }
    }

    fetch(`${API2}/administration/trainer`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal mengambil data!', {type: 'error'})
          return
        }

        setTrainerList(response)
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }, [token, updateTimestamp])

  useEffect(() => {
    setPage(0)
  }, [findQuery])

  return (
    <Fragment>
      <div className="mt-3">
        <div className="box">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h4 className="title is-size-4">Daftar Trainer</h4>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <input type="text" className="input is-small mr-1" placeholder="Cari" value={findQuery} onChange={ev => setFindQuery(ev.target.value)} />
                <button className="button is-small is-primary" onClick={() => trainerForm()}>
                  <div className="icon is-small is-left">
                    <FontAwesomeIcon icon={faPlusCircle}/>
                  </div>
                  <span>Tambah Trainer</span>
                </button>
              </div>
            </div>
          </div>
          <div style={{overflowX: 'auto'}}>
            <table className="table is-bordered is-fullwidth" style={{whiteSpace: 'nowrap'}}>
              <thead>
                <tr>
                  <th>Nama</th>
                  <th>Email</th>
                  <th>No. HP</th>
                  <th>Gender</th>
                  <th style={{width: 170}}>Action</th>
                </tr>
              </thead>
              <tbody>
                {trainerList
                  .filter(trainer => trainer.name.match(new RegExp(findQuery, 'ig')) || trainer.email.match(new RegExp(findQuery, 'ig')))
                  .slice(page * 20, (page + 1) * 20)
                  .map(trainer => (
                    <tr key={trainer.id}>
                      <td>{trainer.name}</td>
                      <td>{trainer.email}</td>
                      <td>{trainer.phone}</td>
                      <td>{trainer.gender === 'F' ? 'Wanita' : 'Pria'}</td>
                      <td>
                        <Link to={`/admin/do-monitor/trainer/${trainer.user_id}?token=${token}`} className="button is-small is-primary" target="_blank">
                          <div className="icon is-small is-left">
                            <FontAwesomeIcon icon={faEye}/>
                          </div>
                          <span>Monitor</span>
                        </Link>
                        <button className="button is-small is-primary ml-1" onClick={() => trainerForm(trainer.id)}>
                          <div className="icon is-small is-left">
                            <FontAwesomeIcon icon={faPencilAlt}/>
                          </div>
                          <span>Ubah</span>
                        </button>
                        <button className="button is-small is-danger ml-1" onClick={() => removeTrainer(trainer.id)}>
                          <div className="icon is-small is-left">
                            <FontAwesomeIcon icon={faTrashAlt}/>
                          </div>
                          <span>Hapus</span>
                        </button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            {trainerList.length > 20 && (
              <div className="field has-addons">
                <p className="control">
                  <button className="button is-small" onClick={() => page > 0 && setPage(page - 1)}>
                    &lt;&lt;
                  </button>
                </p>
                {Object.keys(Array(Math.ceil(trainerList.filter(trainer => trainer.name.match(new RegExp(findQuery, 'ig')) || trainer.email.match(new RegExp(findQuery, 'ig'))).length / 20)).fill(undefined)).map(key => (
                  <p className="control" key={key}>
                    <button className={`button is-small ${page === Number(key) ? 'is-primary' : 'is-default'}`} onClick={() => setPage(Number(key))}>
                      {Number(key) + 1}
                    </button>
                  </p>
                ))}
                <p className="control">
                  <button className="button is-small" onClick={() => page < Math.ceil(trainerList.filter(trainer => trainer.name.match(new RegExp(findQuery, 'ig')) || trainer.email.match(new RegExp(findQuery, 'ig'))).length / 20) - 1 && setPage(page + 1)}>
                    &gt;&gt;
                  </button>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {showTrainerForm && (
        <TrainerForm
          trainer={trainerList.find(trainer => trainer.id === focusedTrainer)}
          onSubmit={submitTrainer}
          onCancel={() => setTrainerFormVisibility(false)}
        />
      )}
    </Fragment>
  )
}

export default StoreConnector(TrainerList)