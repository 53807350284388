import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { StoreConnector, StoreProps } from "../redux/Actions"
import "./Dashboard.sass"

export interface MenuListInterface {
  label: string
  links: Array<{
    path: string
    title: string
    order?: number
  }>
}

interface DashboardProps extends StoreProps {
  menuList: Array<MenuListInterface>
  children: JSX.Element
}

function AccountButton(props: StoreProps) {
  const { setLogout } = props

  const logout = () => {
    setLogout()
  }

  return (
    <Fragment>
      <button className="button is-small is-danger" onClick={logout}>
        <div className="icon is-small is-left">
          <FontAwesomeIcon icon={faSignOutAlt} />
        </div>
        <span>Keluar</span>
      </button>
    </Fragment>
  )
}

const AccountButtonComponent = StoreConnector(AccountButton)

function Dashboard(props: DashboardProps): JSX.Element {
  const {
    children,
    menuList,
    token
  } = props

  const history = useHistory()

  const [mobileSidebarVisible, setMobileSidebarVisibility] = useState(false)

  useEffect(() => {
    if (!token) {
      history.push('/auth')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <Fragment>
      <nav className="navbar has-shadow is-fixed-top" role="navigation">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <h4 className="title is-size-4">MUFIT</h4>
          </Link>

          <div
            className="navbar-burger burger"
            data-target="#navbar-menu"
            onClick={() => setMobileSidebarVisibility(!mobileSidebarVisible)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navbar-menu" className="navbar-menu">
          <div className="navbar-end">
            <div className="navbar-item">
              <AccountButtonComponent />
            </div>
          </div>
        </div>
      </nav>

      <div className="columns is-fullheight mx-0 mb-0 mt-6">
        <div
          className={`column p-4 is-2-widescreen is-3-desktop is-4-tablet is-fullwidth-mobile is-sidebar-menu ${
            !mobileSidebarVisible ? "is-hidden-mobile" : null
          }`}
        >
          <aside className="menu p-4">
            {mobileSidebarVisible && (
              <Fragment>
                <AccountButtonComponent />
                <hr/>
              </Fragment>
            )}
            {menuList.map((menu, idx) => (
              <Fragment key={idx}>
                <p className="menu-label">{ menu.label }</p>
                <ul className="menu-list">
                  {menu.links.map((link, linkIdx) => (
                    <li key={linkIdx}>
                      <Link to={ link.path } onClick={() => setMobileSidebarVisibility(false)}>{ link.title }</Link>
                    </li>
                  ))}
                </ul>
              </Fragment>
            ))}
            <div style={{height: 150}}></div>
          </aside>
        </div>
        <div
          className={`column is-main-content is-fullheight ${
            mobileSidebarVisible ? "is-hidden-mobile" : null
          }`}
        >
          {children}
          <div style={{ height: 100 }}></div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  )
}

export default StoreConnector(Dashboard)