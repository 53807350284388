import { DateTime } from "luxon"
import { FormEvent, Fragment, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"

const API2 = process.env.REACT_APP_API2

interface OffDayListItemInterface {
  id: number
  description: string
  message: string
  repeat: number
  date: string
  days: string
}

function VendorOffDay(props: StoreProps) {
  const { token } = props
  const [offDayList, setOffDayList] = useState<Array<OffDayListItemInterface>>([])
  const [offDayFormVisible, setOffDayFormVisibility] = useState<boolean>(false)
  const [id, setId] = useState<number>()
  const [description, setDescription] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [repeat, setRepeat] = useState<boolean>(false)
  const [date, setDate] = useState<string>(new Date().toISOString())
  const [day, setDay] = useState<Array<number>>([])
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(Date.now())
  const formRef = useRef<HTMLFormElement>(null)

  const setDays = (dayIndex: number, checked: boolean) => {
    const dayCopy = [...day]
    const foundIndex = dayCopy.findIndex(dayItem => dayItem === dayIndex)

    if (foundIndex < 0 && checked) {
      dayCopy.push(dayIndex)
    }

    if (foundIndex > -1 && !checked) {
      dayCopy.splice(foundIndex, 1)
    }

    setDay(dayCopy)
  }

  const offDayForm = (id?: number) => {
    setOffDayFormVisibility(true)
    const foundOffDay = offDayList.find(data => data.id === id)

    setId(id)
    setDescription(foundOffDay?.description ?? '')
    setMessage(foundOffDay?.message ?? '')
    setRepeat(foundOffDay?.repeat === 1 ?? false)
    setDate(foundOffDay?.date ?? DateTime.now().toISODate())
    setDay(JSON.parse(foundOffDay?.days ?? '[]') as Array<number>)
  }

  const submitOffday = () => {
    if (!token || !formRef.current?.reportValidity()) {
      return
    }

    const form = new URLSearchParams()

    if (id) {
      form.append('id', String(id))
    }

    form.append('description', description)
    form.append('message', message)
    form.append('repeat', repeat ? 'yes' : 'no')
    form.append('day', JSON.stringify(day))
    form.append('date', date)

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: form
    }

    fetch(`${API2}/vendor/off-day`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal simpan data libur!', {type: 'error'})
          return
        }

        toast('Berhasil simpan data libur!', {type: 'success'})
        setOffDayFormVisibility(false)
        setUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    fetch(`${API2}/vendor/off-day`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal menyimpan status!', {type: 'error'})
          return
        }

        setOffDayList(response)
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }, [token, updateTimestamp])

  return (
    <Fragment>
      <div className="mt-3">
        <div className="box">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h4 className="title is-size-4">Daftar Hari Libur</h4>
              </div>
            </div>
            <div className="level-right">
              <div className="levle-item">
                <button className="button is-small" onClick={() => offDayForm()}>
                  Tambah Hari Libur
                </button>
              </div>
            </div>
          </div>
          <div style={{overflowX: 'auto'}}>
            <table className="table is-bordered is-fullwidth" style={{whiteSpace: 'nowrap'}}>
              <thead>
                <tr>
                  <td>Deskripsi</td>
                  <td>Hari / Tanggal</td>
                  <td>Berulang</td>
                  <td>Catatan</td>
                  <td style={{width: 100}}>Aksi</td>
                </tr>
              </thead>
              <tbody>
                {offDayList.map(offDay => (
                  <tr key={offDay.id}>
                    <td>{offDay.description}</td>
                    <td>{offDay.repeat === 1 ? (JSON.parse(offDay.days) as Array<number>) : offDay.date}</td>
                    <td>{offDay.repeat === 1 ? 'Ya' : 'Tidak'}</td>
                    <td>{offDay.description}</td>
                    <td>
                      <button className="button is-small is-primary mr-1" onClick={() => offDayForm(offDay.id)}>
                        <span>Ubah</span>
                      </button>
                      <button className="button is-small is-danger mr-1">
                        <span>Hapus</span>
                      </button>
                    </td>
                  </tr>
                ))}
                {offDayList.length < 1 && (
                  <tr>
                    <td colSpan={5} className="has-text-centered">
                      Belum ada pengaturan libur
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {offDayFormVisible && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setOffDayFormVisibility(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">Form Hari Libur</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-small is-primary" onClick={submitOffday}>
                      <span>Simpan</span>
                    </button>
                    <button className="button is-small is-default" onClick={() => setOffDayFormVisibility(false)}>
                      <span>Tutup</span>
                    </button>
                  </div>
                </div>
              </div>
              <form ref={formRef}>
                <div className="field">
                  <label htmlFor="">Deskripsi</label>
                  <div className="control">
                    <input
                      type="text"
                      className="input"
                      value={description}
                      onChange={ev => setDescription(ev.target.value)}
                    />
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="">Pesan Ke Pelanggan</label>
                  <div className="control">
                    <input
                      type="text"
                      className="input"
                      value={message}
                      onChange={ev => setMessage(ev.target.value)}
                    />
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="">Berulang</label>
                  <div className="select is-fullwidth">
                    <select value={repeat ? 'yes' : 'no'} onChange={ev => setRepeat(ev.target.value === 'yes')}>
                      <option value="yes">Ya</option>
                      <option value="no">Tidak</option>
                    </select>
                  </div>
                </div>
                {repeat && (
                  <div className="box">
                    <div className="field">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          checked={day.includes(1)}
                          onChange={ev => setDays(1, ev.target.checked)}
                        /> Senin
                      </label>
                    </div>
                    <div className="field">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          checked={day.includes(2)}
                          onChange={ev => setDays(2, ev.target.checked)}
                        /> Selasa
                      </label>
                    </div>
                    <div className="field">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          checked={day.includes(3)}
                          onChange={ev => setDays(3, ev.target.checked)}
                        /> Rabu
                      </label>
                    </div>
                    <div className="field">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          checked={day.includes(4)}
                          onChange={ev => setDays(4, ev.target.checked)}
                        /> Kamis
                      </label>
                    </div>
                    <div className="field">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          checked={day.includes(5)}
                          onChange={ev => setDays(5, ev.target.checked)}
                        /> Jum'at
                      </label>
                    </div>
                    <div className="field">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          checked={day.includes(6)}
                          onChange={ev => setDays(6, ev.target.checked)}
                        /> Sabtu
                      </label>
                    </div>
                    <div className="field">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          checked={day.includes(7)}
                          onChange={ev => setDays(7, ev.target.checked)}
                        /> Minggu
                      </label>
                    </div>
                  </div>
                )}
                {!repeat && (
                  <div className="field">
                    <label htmlFor="">Pilih Tanggal</label>
                    <div className="control">
                      <input
                        type="date"
                        className="input"
                        value={date}
                        onChange={ev => setDate(ev.target.value)}
                      />
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default StoreConnector(VendorOffDay)
