import { faCheckCircle, faPencilAlt, faPlusCircle, faTimesCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react"
import { ReactSortable } from "react-sortablejs"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"

const API2 = process.env.REACT_APP_API2

interface NutritionistSpecialityListItem {
  id: number
  title: string
  description: string
  img?: string
}

function NutritionistSpeciality(props: StoreProps) {
  const { token } = props

  const [specialityList, setSpecialityList] = useState<Array<NutritionistSpecialityListItem>>([])
  const [focusedSpeciality, setFocusedSpeciality] = useState<number>()
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [img, setImg] = useState<File>()
  const [previewImg, setPreviewImg] = useState<string>()
  const [showEditSpecialityModal, setEditSpecialityModalVisibility] = useState<boolean>(false)
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(Date.now())

  const [findQuery, setFindQuery] = useState<string>('')

  const specialithForm = (id?: number) => {
    const foundData = specialityList.find(data => data.id === id)
    
    setFocusedSpeciality(id)
    setTitle(foundData?.title ?? '')
    setDescription(foundData?.description ?? '')
    setPreviewImg(foundData?.img)
    setImg(undefined)
    setEditSpecialityModalVisibility(true)
  }

  const uploadImagehandler = (file: FileList|null, preview: Dispatch<SetStateAction<string|undefined>>, storeTo: Dispatch<SetStateAction<File|undefined>>) => {
    if (file === null) {
      return
    }

    if (file.length < 1) {
      return
    }

    if (file[0].size > 1000000) {
      toast('Ukuran file maksimal adalah 1MB', {type: 'error'})
      return
    }

    const reader = new FileReader()

    reader.onload = (ev: ProgressEvent<FileReader>) => {
      const result = ev.target?.result

      if (!result) {
        return
      }

      preview(result.toString())
    }
    
    reader.readAsDataURL(file[0])
    storeTo(file[0])
  }

  const removeSpeciality = (id: number) => {
    if (!window.confirm('Anda yakin menghapus data ini?') || !token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'DELETE',
      headers: {
        Authorization: token
      }
    }

    fetch(`${API2}/administration/nutritionist-speciality/${id}`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal menyimpan data!', {type: 'error'})
          return
        }

        toast('Data berhasil dihapus!', {type: 'success'})
        setUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Terjadi kegagaln jaringan!', {type: 'error'}))
  }

  const submitSpeciality = () => {
    if (!token) {
      return
    }

    const form = img ? new FormData() : new URLSearchParams()

    form.append('id', String(focusedSpeciality))
    form.append('title', title)
    form.append('description', description)
    
    if (img) {
      // @ts-ignore
      form.append('img', img)
    }

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: form
    }

    fetch(`${API2}/administration/nutritionist-speciality`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal menyimpan data!', {type: 'error'})
          return
        }

        toast('Berhasil menyimpan data!', {type: 'success'})
        setUpdateTimestamp(Date.now())
        setEditSpecialityModalVisibility(false)
      })
      .catch(() => toast('Gagal menyimpan data!', {type: 'error'}))
  }

  const sortHandler = () => {
    if (!token) {
      return
    }

    const form = new URLSearchParams()

    specialityList.map((speciality, index) => form.append(`sorted_speciality[${index}]`, String(speciality.id)))

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: form
    }

    fetch(`${API2}/administration/sort-nutritionist-speciality`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.message === 'ERROR') {
          toast('Gagal memperbarui urutan!', {type: 'error'})
          return
        }

        setUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        Authorization: token
      }
    }

    fetch(`${API2}/administration/nutritionist-speciality`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.message === 'ERROR') {
          toast('Gagal mengambil data!', {type: 'error'})
          return
        }

        setSpecialityList(response)
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }, [token, updateTimestamp])

  return (
    <Fragment>
      <div className="mt-3">
        <div className="columns is-centered">
          <div className="column is-6">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">Nutritionist Speciality List</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <input type="text" className="input is-small mr-1" placeholder="Cari" value={findQuery} onChange={ev => setFindQuery(ev.target.value)} />
                    <button className="button is-small is-primary" onClick={() => specialithForm()}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faPlusCircle}/>
                      </div>
                      <span>Tambah</span>
                    </button>
                  </div>
                </div>
              </div>
              <ReactSortable list={specialityList} setList={setSpecialityList} onEnd={sortHandler}>
                {specialityList.map(speciality => (
                  <div className="box" key={speciality.id} onDrag={ev => ev.currentTarget.className = 'box has-background-warning'} style={{cursor: 'move'}}>
                    <div className="columns">
                      <div className="column is-narrow">
                        <img src={speciality.img ?? 'https://via.placeholder.com/100x100?text=No Image'} alt="" style={{width: 150, height: 150, objectFit: 'contain'}} />
                      </div>
                      <div className="column">
                        <h6 className="title is-size-6">{speciality.title}</h6>
                        <p className="my-3">{speciality.description}</p>
                        <button className="button is-small is-primary" onClick={() => specialithForm(speciality.id)}>
                          <div className="icon is-small is-left">
                            <FontAwesomeIcon icon={faPencilAlt}/>
                          </div>
                          <span>Ubah</span>
                        </button>
                        <button className="button is-danger is-small ml-1" onClick={() => removeSpeciality(speciality.id)}>
                          <div className="icon is-small is-left">
                            <FontAwesomeIcon icon={faTrashAlt}/>
                          </div>
                          <span>Hapus</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </ReactSortable>
            </div>
          </div>
        </div>
      </div>
      {showEditSpecialityModal && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setEditSpecialityModalVisibility(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">Form Speciality</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-small is-primary" onClick={submitSpeciality}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faCheckCircle}/>
                      </div>
                      <span>Simpan</span>
                    </button>
                    <button className="button is-small is-default ml-1" onClick={() => setEditSpecialityModalVisibility(false)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTimesCircle}/>
                      </div>
                      <span>Batal</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Nama</label>
                <div className="control">
                  <input type="text" className="input" value={title} onChange={ev => setTitle(ev.target.value)} />
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Description</label>
                <div className="control">
                  <textarea className="textarea" value={description} onChange={ev => setDescription(ev.target.value)} />
                </div>
              </div>
              <div className="columns mt-0">
                <div className="column is-6">
                  <div className="field">
                    <label htmlFor="">Background</label>
                    <div className="control">
                      <input
                        type="file"
                        className="input"
                        onChange={ev => uploadImagehandler(ev.target.files, setPreviewImg, setImg)}
                        accept="image/*"
                      />
                    </div>
                    {previewImg !== undefined && (
                      <img src={previewImg} alt="" className="image mt-1" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default StoreConnector(NutritionistSpeciality)