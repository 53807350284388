import { faCheckCircle, faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"

const API2 = process.env.REACT_APP_API2

interface ScheduleListItemInterface {
  id?: number
  day: number
  start: string
  finish: string
  online: number
  offline: number
  deleted: 1|0
}

function ShiftList(props: StoreProps) {
  const { token } = props

  const [scheduleList, setScheduleList] = useState<Array<ScheduleListItemInterface>>([])
  const [focusedDay, setFocusedDay] = useState<number>()
  const [focusedShiftId, setFocusedShiftId] = useState<number>()
  const [startShift, setStartShift] = useState<string>('')
  const [finishShift, setFinishShift] = useState<string>('')
  const [online, setOnline] = useState<number>(0)
  const [offline, setOffline] = useState<number>(0)
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(Date.now())
  const [editScheduleModalVisible, setEditScheduleModalVisibility] = useState<boolean>(false)
  
  const updateShift = (day: number, shiftId?: number) => {
    setFocusedDay(day)
    setFocusedShiftId(shiftId)
    setEditScheduleModalVisibility(true)
    
    const foundShift = scheduleList.find(schedule => schedule.day === day && schedule.id === shiftId)
    setStartShift(foundShift?.start ?? '')
    setFinishShift(foundShift?.finish ?? '')
    setOnline(foundShift?.online ?? 0)
    setOffline(foundShift?.offline ?? 0)
  }

  const doUpdateShift = () => {
    if (focusedDay === undefined) {
      return
    }

    const scheduleListCopy = [...scheduleList]

    if (focusedShiftId === undefined) {
      scheduleListCopy.push({
        id: 0 - scheduleList.length,
        day: focusedDay,
        start: startShift,
        finish: finishShift,
        online: 1,
        offline: 0,
        deleted: 0
      })
    } else {
      const foundScheduleIndex = scheduleListCopy.findIndex(data => data.day === focusedDay && data.id === focusedShiftId)
      scheduleListCopy[foundScheduleIndex] = {
        ...scheduleListCopy[foundScheduleIndex],
        start: startShift,
        finish: finishShift,
        online: online,
        offline: offline,
      }
    }

    setScheduleList(scheduleListCopy)
    setEditScheduleModalVisibility(false)
    setFocusedShiftId(undefined)
    setFocusedDay(undefined)
  }

  const submitChanges = () => {
    if (!token) {
      return
    }

    const form = new URLSearchParams()

    scheduleList.map(data => {
      form.append(`schedule[${data.id}][id]`, String(data.id))
      form.append(`schedule[${data.id}][day]`, String(data.day))
      form.append(`schedule[${data.id}][start]`, data.start)
      form.append(`schedule[${data.id}][finish]`, data.finish)
      form.append(`schedule[${data.id}][deleted]`, String(data.deleted))
      form.append(`schedule[${data.id}][online]`, String(data.online))
      form.append(`schedule[${data.id}][offline]`, String(data.offline))

      return undefined
    })

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: form
    }

    fetch(`${API2}/nutritionist/shift`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.message === 'ERROR') {
          toast('Gagal menyimpan data!', {type: 'error'})
          return
        }

        toast('Berhasil menyimpan data!', {type: 'success'})
      })
      .catch(() => toast('Gagal menyimpan data!', {type: 'error'}))
      .finally(() => setUpdateTimestamp(Date.now()))
  }

  const removeShift = () => {
    if (focusedDay === undefined || focusedShiftId === undefined) {
      return
    }

    const scheduleListCopy = [...scheduleList]
    const foundScheduleIndex = scheduleListCopy.findIndex(data => data.day === focusedDay && data.id === focusedShiftId)

    if (foundScheduleIndex < 0) {
      return
    }

    scheduleListCopy[foundScheduleIndex].deleted = 1

    setScheduleList(scheduleListCopy)
    setFocusedDay(undefined)
    setFocusedShiftId(undefined)
    setEditScheduleModalVisibility(false)
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        Authorization: token
      }
    }

    fetch(`${API2}/nutritionist/all-schedule`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        setScheduleList(response)
      })
      .catch(() => toast('Gagal mengambil data!', {type: 'error'}))
  }, [token, updateTimestamp])

  return (
    <Fragment>
      <div className="mt-3">
        <div className="box">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h4 className="title is-size-4">Daftar Shift</h4>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <button className="button is-small is-primary" onClick={submitChanges}>
                  <div className="icon is-small is-left">
                    <FontAwesomeIcon icon={faCheckCircle}/>
                  </div>
                  <span>Simpan Perubahan</span>
                </button>
              </div>
            </div>
          </div>
          <div style={{overflowX: 'auto'}}>
            <table className="table is-bordered is-fullwidth" style={{whiteSpace: 'nowrap'}}>
              <thead>
                <tr>
                  <th style={{width: 100}}>Hari</th>
                  <th>Jadwal</th>
                </tr>
              </thead>
              <tbody>
                {['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'].map((day, dayIndex) => (
                  <tr key={day}>
                    <td>{day}</td>
                    <td>
                      {scheduleList.filter(schedule => schedule.day === dayIndex + 1).filter(schedule => schedule.deleted === 0).map(schedule => (
                        <button className="button is-small is-primary m-1" onClick={() => updateShift(dayIndex + 1, schedule.id)}>
                          <span>{schedule.start.substring(0, 5)} - {schedule.finish.substring(0, 5)} {schedule.online === 1 ? 'ONLINE' : ''} {schedule.offline === 1 ? 'OFFLINE' : ''}</span>
                        </button>
                      ))}
                      <button className="button is-small is-primary m-1" onClick={() => updateShift(dayIndex + 1)}>
                        <div className="icon is-small">
                          <FontAwesomeIcon icon={faPlusCircle}/>
                        </div>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {editScheduleModalVisible && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setEditScheduleModalVisibility(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">{focusedShiftId !== undefined ? 'Update' : 'Tambah'} Jadwal Hari {['', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'][focusedDay ?? 0]}</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-small is-primary" onClick={doUpdateShift}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faCheckCircle}/>
                      </div>
                      <span>Terapkan</span>
                    </button>
                    {focusedShiftId !== undefined && (
                      <button className="button is-small is-danger ml-1" onClick={removeShift}>
                        <div className="icon is-small is-left">
                          <FontAwesomeIcon icon={faTimesCircle}/>
                        </div>
                        <span>Hapus</span>
                      </button>
                    )}
                    <button className="button is-small is-default ml-1" onClick={() => setEditScheduleModalVisibility(false)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTimesCircle}/>
                      </div>
                      <span>Batal</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label htmlFor="">Shift Mulai</label>
                    <div className="control">
                      <input
                        type="time"
                        className="input"
                        value={startShift}
                        onChange={ev => setStartShift(ev.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label htmlFor="">Shift Selesai</label>
                    <div className="control">
                      <input
                        type="time"
                        className="input"
                        value={finishShift}
                        onChange={ev => setFinishShift(ev.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-narrow">
                  <div className="field">
                    <label>
                      <input type="checkbox" className="mr-3" checked={online === 1} onChange={ev => setOnline(ev.target.checked ? 1 : 0)} /> Online
                    </label>
                  </div>
                </div>
                <div className="column is-narrow">
                  <div className="field">
                    <label>
                      <input type="checkbox" className="mr-3" checked={offline === 1} onChange={ev => setOffline(ev.target.checked ? 1 : 0)} /> Offline
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default StoreConnector(ShiftList)