import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { StoreConnector, StoreProps } from "../redux/Actions"

function Main(prop: StoreProps) {
  const { token } = prop
  const history = useHistory()

  useEffect(() => {
    if (!token) {
      history.push('/auth')
    }
  }, [history, token])

  return (
    <div>
      <h1 className="title is-size-1">Main Page!</h1>
    </div>
  )
}

export default StoreConnector(Main)