import { combineReducers, createStore } from "redux"
import Auth from "./reducer/Auth"
import UrlPrefix from "./reducer/UrlPrefix"
import UserProfile from "./reducer/UserProfile"
import Privilege from "./reducer/Privilege"

const reducer = combineReducers({
  Auth,
  UrlPrefix,
  UserProfile,
  Privilege
})

export default createStore(reducer)