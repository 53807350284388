import { faCheckCircle, faDownload, faFileDownload, faFileExcel, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { StoreConnector, StoreProps } from "../../redux/Actions";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

const API2 = process.env.REACT_APP_API2

interface ICompleteCourses {
  id: number
  user_id: number
  email: string
  course_id: number
  course_title: string
  voucher_id?: number
  voucher_code?: string
  voucher_file?: string
  nominal?: number
  expire_date?: string
}

interface IUserCompletedCourse {
  course_id: number
  user_id: number
  username: string
  full_name: string
  course_title: string
}

interface CourseList {
  course_id: number
  course_title: string
}

interface ParticipantList {
  course_id: number
  course_title: string
  user_id: number
  email: string
}

interface ImportExcelItem extends ParticipantList {
  voucher: string
  nominal: number
  expired: string
}

function VoucherSodexo(props: StoreProps) {
  const { token } = props
  const [voucherList, setVoucherList] = useState<ICompleteCourses[]>([])
  const [voucherDialogVisible, setVoucherDialogVisibility] = useState<boolean>(false)
  const [voucherUpdateTimestamp, setVoucherUpdateTimestamp] = useState<number>(0)
  const [userCompleteCourseList, setUserCompleteCourseList] = useState<IUserCompletedCourse[]>([])
  const [selectedUserAndCourse, setSelectedUserAndCourse] = useState<string>('0-0')
  const [mode, setMode] = useState<'create'|'update'>('create')
  const [id, setId] = useState<number>(0)
  const [voucherCode, setVoucherCode] = useState<string>('')
  const [nominal, setNominal] = useState<number>(0)
  const [expireDate, setExpireDate] = useState<string>('')
  const [voucherFile, setVoucherFile] = useState<File | null>(null)
  const [voucherImportDialogVisible, setVoucherImportDialogVisibility] = useState<boolean>(false)
  const [courseListDialogVisible, setCourseListDialogVisibility] = useState<boolean>(false)
  const [courseList, setCourseList] = useState<CourseList[]>([])
  const [selectedCourse, setSelectedCourse] = useState<number>()
  const [importItemList, setImportItemList] = useState<ImportExcelItem[]>([])
  const fileFieldRef = useRef<HTMLInputElement>(null)

  const submit = useCallback(() => {
    if (!token) {
      return
    }

    const formData = new FormData()

    formData.append('mode', mode)

    if (mode === 'update') {
      formData.append('completed_course_id', id.toString())
    }

    if (mode === 'create') {
      const [courseId, userId] = selectedUserAndCourse.split('-').map(Number)
      formData.append('completed_course_id', courseId.toString())
      formData.append('user_id', userId.toString())
    }
    
    formData.append('voucher_code', voucherCode)
    formData.append('nominal', nominal.toString())
    formData.append('expire_date', expireDate)
    if (voucherFile) {
      formData.append('voucher_file', voucherFile)
    }

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData
    }

    fetch(`${API2}/sodexo/apply-voucher`, fetchInitOpt)
      .then(res => res.json())
      .then(() => {
        setVoucherUpdateTimestamp(Date.now())
        setVoucherDialogVisibility(false)
      })
      .catch(() => toast('Gagal menerapkan voucher', {type: 'error'}))
  }, [token, mode, voucherCode, nominal, expireDate, voucherFile, id, selectedUserAndCourse])

  const downloadTemplate = useCallback(() => {
    if (!selectedCourse) {
      return
    }
    const fetchInitOpt: RequestInit = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    fetch(`${API2}/eduqat/participant/${selectedCourse}`, fetchInitOpt)
      .then(response => response.json())
      .then((response: ParticipantList[]) => {
        const workbook = new Workbook()
        const worksheet = workbook.addWorksheet()

        worksheet.addRow([
          'Course ID',
          'Course Title',
          'User ID',
          'Email',
          'Voucher',
          'Nominal',
          'Kadaluarsa'
        ])

        response.forEach(item => worksheet.addRow([
          item.course_id,
          item.course_title,
          item.user_id,
          item.email
        ]))

        workbook.xlsx.writeBuffer()
          .then(data => saveAs(new Blob([data]), 'Template Voucher.xlsx'))
          .catch(() => toast('Gagal membuat file excel', {type: 'error'}))
      })
      .catch(() => toast('Gagal mengambil data participant!', {type: 'error'}))
  }, [selectedCourse, token])

  const importExcel = useCallback((files: FileList | null) => {
    const file = files && files[0]

    if (!file || typeof file === 'string') {
      return
    }

    const reader = new FileReader()

    reader.onload = (val: ProgressEvent<FileReader>) => {
      const result = val.target?.result

      if (!result || typeof result === 'string') {
        return
      }

      const workbook = new Workbook()
      
      workbook.xlsx.load(result)
        .then(data => {
          const worksheet = data.getWorksheet(1)
          const items: ImportExcelItem[] = []

          for (let i = 2; i <= worksheet.rowCount; i++) {
            const row = worksheet.getRow(i)

            items.push({
              course_id: parseInt(row.getCell(1).value?.toString() ?? '0'),
              course_title: row.getCell(2).value?.toString() ?? '-',
              user_id: parseInt(row.getCell(3).value?.toString() ?? '0'),
              email: row.getCell(4).value?.toString() ?? '-',
              voucher: row.getCell(5).value?.toString() ?? '',
              nominal: parseInt(row.getCell(6).value?.toString() ?? '0'),
              expired: new Date(row.getCell(7).value?.toString() ?? '').toISOString().substring(0, 10)
            })
          }
          
          setImportItemList(items)
          setVoucherImportDialogVisibility(true)
        })
        .catch(error => {
          console.error(error)
          toast('Failed to parse excel!')
        })
    }

    reader.readAsArrayBuffer(file)
  }, [])

  const doImportExcel = useCallback(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({items: importItemList})
    }

    fetch(`${API2}/sodexo/import-voucher`, fetchInitOpt)
      .then(response => response.json())
      .then(() => {
        toast('Berhasil import data!')
        setVoucherImportDialogVisibility(false)
        setVoucherUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Gagal import data!', {type: 'error'}))
  }, [token, importItemList])

  const removeVoucher = useCallback((id: number) => {
    if (!token || !window.confirm('Apakah Anda yakin?')) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    fetch(`${API2}/sodexo/remove-voucher/${id}`, fetchInitOpt)
      .then(res => res.json())
      .then(() => {
        setVoucherUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Gagal menghapus voucher', {type: 'error'}))
  }, [token])

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    fetch(`${API2}/sodexo/user-list`, fetchInitOpt)
      .then(res => res.json())
      .then(setVoucherList)
      .catch(() => toast('Gagal mengambil data peserta', {type: 'error'}))
  }, [token, voucherUpdateTimestamp])

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    fetch(`${API2}/eduqat/course-list`, fetchInitOpt)
      .then(response => response.json())
      .then(setCourseList)
      .catch(() => toast('Gagal mengambil daftar course'))
  }, [token])

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    fetch(`${API2}/eduqat/user-completed-course`, fetchInitOpt)
      .then(res => res.json())
      .then(setUserCompleteCourseList)
      .catch(() => toast('Gagal mengambil data peserta', {type: 'error'}))
  }, [token])

  return (
    <>
      <input ref={fileFieldRef} accept=".xlsx" style={{display: 'none'}} type="file" onChange={ev => importExcel(ev.target.files)} />
      <div className="mt-3">
        <div className="box">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h3 className="title is-3">Voucher Sodexo</h3>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <button
                  className="button is-small is-primary mr-1"
                  onClick={() => {
                    setVoucherDialogVisibility(true)
                    setMode('create')
                    setSelectedUserAndCourse('0-0')
                    setId(0)
                    setVoucherCode('')
                    setNominal(0)
                    setExpireDate('')
                    setVoucherFile(null)
                  }}
                >
                  <div className="icon is-small is-left">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </div>
                  <span>Buat Voucher</span>
                </button>
                <button className="button is-small is-primary mr-1" onClick={() => fileFieldRef.current?.click()}>
                  <div className="icon is-small is-left">
                    <FontAwesomeIcon icon={faFileExcel} />
                  </div>
                  <span>Import Excel</span>
                </button>
                <button className="button is-small is-primary" onClick={() => setCourseListDialogVisibility(true)}>
                  <div className="icon is-small is-left">
                    <FontAwesomeIcon icon={faFileDownload} />
                  </div>
                  <span>Download Template</span>
                </button>
              </div>
            </div>
          </div>
          <div style={{overflowX: 'auto'}}>
            <table className="table is-fullwidth is-striped is-hoverable">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Email</th>
                  <th>Nama Kursus</th>
                  <th>Kode Voucher</th>
                  <th>Nominal</th>
                  <th>Tgl. Kadaluarsa</th>
                  <th style={{width: 180}}>Aksi</th>
                </tr>
              </thead>
              <tbody>
                {voucherList.map((voucher, idx) => (
                  <tr key={voucher.id}>
                    <td>{idx + 1}</td>
                    <td>{voucher.email}</td>
                    <td>{voucher.course_title}</td>
                    <td>{voucher.voucher_code ?? '-'}</td>
                    <td>{voucher.nominal ?? '-'}</td>
                    <td>{voucher.expire_date ? DateTime.fromISO(voucher.expire_date).toLocaleString() : '-'}</td>
                    <td>
                      <button
                        className="button is-small is-primary mr-1"
                        onClick={() => {
                          setMode('update')
                          setId(voucher.id)
                          setVoucherCode(voucher.voucher_code ?? '')
                          setNominal(voucher.nominal ?? 0)
                          setExpireDate(voucher.expire_date ?? '')
                          setVoucherFile(null)
                          setVoucherDialogVisibility(true)
                        }}
                      >
                        <div className="icon is-small is-left">
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </div>
                        <span>Ubah</span>
                      </button>
                      <button
                        className="button is-small is-danger"
                        onClick={() => removeVoucher(voucher.id)}
                      >
                        <div className="icon is-small is-left">
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </div>
                        <span>Hapus</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {voucherDialogVisible && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setVoucherDialogVisibility(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h3 className="title is-3">Form Voucher</h3>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-small is-primary" onClick={submit}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </div>
                      <span>Simpan</span>
                    </button>
                    <button className="button is-small ml-1" onClick={() => setVoucherDialogVisibility(false)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                      <span>Batal</span>
                    </button>
                  </div>
                </div>
              </div>

              {mode === 'create' && (
                <div className="field">
                  <label className="label">Peserta</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        value={selectedUserAndCourse}
                        onChange={e => setSelectedUserAndCourse(e.target.value)}
                      >
                        <option>Pilih Peserta</option>
                        {userCompleteCourseList.map((user, idx) => (
                          <option key={`${user.course_id}-${user.user_id}`} value={`${user.course_id}-${user.user_id}`}>{user.full_name} ({user.username}) - {user.course_title}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}

              <div className="field">
                <label className="label">File voucher</label>
                <div className="control">
                  <input type="file" accept=".pdf,image/*" className="input"
                    onChange={ev => {
                      const file = ev.target.files?.[0]
                      if (file) {
                        setVoucherFile(file)
                      }
                    }} />
                </div>
              </div>

              <div className="field">
                <label className="label">Kode Voucher</label>
                <div className="control">
                  <input type="text" className="input"
                    value={voucherCode} onChange={ev => setVoucherCode(ev.target.value)}
                    disabled={mode === 'update'} />
                </div>
              </div>

              <div className="field">
                <label className="label">Nominal</label>
                <div className="control">
                  <input type="number" className="input"
                    value={nominal} onChange={ev => setNominal(Number(ev.target.value))} />
                </div>
              </div>

              <div className="field">
                <label className="label">Tgl. Kadaluarsa</label>
                <div className="control">
                  <input type="date" className="input"
                    value={expireDate} onChange={ev => setExpireDate(ev.target.value)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {voucherImportDialogVisible && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setVoucherImportDialogVisibility(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h3 className="title is-3">Form Voucher</h3>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-small is-primary" onClick={doImportExcel}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </div>
                      <span>Simpan</span>
                    </button>
                    <button className="button is-small ml-1" onClick={() => setVoucherImportDialogVisibility(false)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                      <span>Batal</span>
                    </button>
                  </div>
                </div>
              </div>

              <div style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
                <table className="table is-bordered is-fullwidth" style={{whiteSpace: 'nowrap'}}>
                  <thead>
                    <tr>
                      <th>Course ID</th>
                      <th>Course Title</th>
                      <th>User ID</th>
                      <th>Email</th>
                      <th>Voucher</th>
                      <th>Nominal</th>
                      <th>Kadaluarsa</th>
                    </tr>
                  </thead>
                  <tbody>
                    {importItemList.map(item => (
                      <tr key={item.user_id}>
                        <td>{item.course_id}</td>
                        <td>{item.course_title}</td>
                        <td>{item.user_id}</td>
                        <td>{item.email}</td>
                        <td>{item.voucher}</td>
                        <td>{item.nominal}</td>
                        <td>{item.expired}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {courseListDialogVisible && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setCourseListDialogVisibility(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="field">
                <select className="select is-fullwidth" onChange={ev => setSelectedCourse(parseInt(ev.target.value))}>
                  <option value="">Pilih kursus</option>
                  {courseList.map(course => (
                    <option value={course.course_id} key={course.course_id}>
                      {course.course_title}
                    </option>
                  ))}
                </select>
                <button className="button is-small is-primary mt-1" onClick={downloadTemplate}>
                  <div className="icon is-small is-left">
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                  <span>Download</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default StoreConnector(VoucherSodexo)
