import { faArrowCircleLeft, faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { API2Response } from "../../interfaces/APIResponse"
import { StoreConnector, StoreProps } from "../../redux/Actions"
import { FoodItemListInterface } from "./FoodList"

const API2 = process.env.REACT_APP_API2

interface AssignedFoodItemInterface {
  id: number
  vendor_food_id: number
  deleted: 'Y'|'N'
}

function FoodPackageForm(props: StoreProps) {
  const { token, profile } = props
  const { id } = useParams<{id?: string}>()
  const history = useHistory()

  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [isActive, setIsActive] = useState<number>(1)
  const [assignedFood, setAssignedFood] = useState<Array<AssignedFoodItemInterface>>([])
  const [foodListItem, setFoodListItem] = useState<Array<FoodItemListInterface>>([])

  const updateAssignedFood = (food: number, isAssigned: boolean) => {
    const assignedFoodCopy = [...assignedFood]
    const foundIndex = assignedFoodCopy.findIndex(data => data.vendor_food_id === food)

    if (foundIndex < 0) {
      assignedFoodCopy.push({
        id: 0 - assignedFoodCopy.length,
        deleted: 'N',
        vendor_food_id: food
      })
    } else {
      assignedFoodCopy[foundIndex].deleted = isAssigned ? 'N' : 'Y'
    }

    setAssignedFood(assignedFoodCopy)
  }

  const submitPackage = () => {
    if (!token) {
      return
    }

    const form = new URLSearchParams()
    
    if (id) {
      form.append('id', id)
    }

    form.append('name', name)
    form.append('description', description)
    form.append('isActive', String(isActive))

    assignedFood.map((data, index) => {
      form.append(`assigned[${index}][id]`, String(data.id))
      form.append(`assigned[${index}][food]`, String(data.vendor_food_id))
      form.append(`assigned[${index}][deleted]`, data.deleted)

      return undefined
    })

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: form
    }

    fetch(`${API2}/vendor/catering/menu`, fetchInitOpt)
      .then(response => response.json())
      .then((response: API2Response) => {
        if (response.error) {
          toast('Gagal menyimpan data!', {type: 'error'})
          return
        }

        toast('Berhasil menyimpan data!', {type: 'success'})
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }

  useEffect(() => {
    if (!profile?.vendor_id) {
      return
    }

    fetch(`${API2}/catering/food/${profile.vendor_id}`)
      .then(response => response.json())
      .then(response => {
        setFoodListItem(response)
      })
      .catch(() => toast('Gagal mengambil data makanan!', {type: 'error'}))

    if (id !== undefined) {
      const tasks: Array<string> = [
        `/catering/menu/${profile.vendor_id}/${id}`,
        `/catering/menu-item/${id}/all`,
      ]
  
      Promise.all(tasks.map(task => fetch(API2 + task)))
        .then(async responses => await Promise.all(responses.map(response => response.json())))
        .then((responses) => {
          const [menu, items] = responses
  
          if (menu.message !== 'ERROR') {
            setName(menu.name)
            setDescription(menu.description)
            setIsActive(menu.is_active)
          }
  
          if (items.message !== 'ERROR') {
            setAssignedFood(items)
          }
        })
        .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
    }

  }, [id, profile?.vendor_id])

  return (
    <div className="mt-3">
      <div className="box">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h4 className="title is-size-4">Form Paket Makanan</h4>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <button className="button is-small is-primary" onClick={submitPackage}>
                <div className="icon is-small is-left">
                  <FontAwesomeIcon icon={faCheckCircle}/>
                </div>
                <span>Simpan</span>
              </button>
              <button className="button ml-1 is-small" onClick={() => history.goBack()}>
                <div className="icon is-small is-left">
                  <FontAwesomeIcon icon={faArrowCircleLeft}/>
                </div>
                <span>Kembali</span>
              </button>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-4">
            <div className="field">
              <label htmlFor="">Nama Paket</label>
              <div className="control">
                <input type="text" className="input" value={name} onChange={ev => setName(ev.target.value)}/>
              </div>
            </div>
            <div className="field">
              <label htmlFor="">Deskripsi</label>
              <div className="control">
                <input type="text" className="input" value={description} onChange={ev => setDescription(ev.target.value)}/>
              </div>
            </div>
            <div className="field">
              <label htmlFor="">Status</label>
              <div className="select is-fullwidth">
                <select value={isActive} onChange={ev => setIsActive(Number(ev.target.value))}>
                  <option value="1">Aktif</option>
                  <option value="0">Tidak Aktif</option>
                </select>
              </div>
            </div>
          </div>
          <div className="column is-8">
            <div style={{overflowX: 'auto'}}>
              <table className="table is-bordered is-fullwidth" style={{whiteSpace: "nowrap"}}>
                <thead>
                  <tr>
                    <th></th>
                    <th>Nama</th>
                    <th>Deskripsi</th>
                    <th>Harga</th>
                  </tr>
                </thead>
                <tbody>
                  {foodListItem.map(food => (
                    <tr key={food.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={assignedFood.find(data => data.vendor_food_id === food.id && data.deleted === 'N') !== undefined}
                          onChange={ev => updateAssignedFood(food.id, ev.target.checked)}
                        />
                      </td>
                      <td>{food.name}</td>
                      <td>{food.description}</td>
                      <td>Rp {Intl.NumberFormat('id-ID').format(food.price)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoreConnector(FoodPackageForm)