import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StoreConnector, StoreProps } from "../redux/Actions";
import { faCheckCircle, faPlusCircle, faTimesCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { v4 as UUIDv4 } from "uuid"
import { lib as CryptoLib } from "crypto-js"
import CompanyListItemInterface from "../interfaces/CompanyListItemInterface";

interface Oauth2ListItemInterface {
  id: number
  companyId: number
  companyName: string
  clientId: string
  clientSecret: string
  redirectUri: string
}

const API2 = process.env.REACT_APP_API2

function Oauth2Management(props: StoreProps) {
  const { token } = props

  const [showOauth2Form, setShowOauth2Form] = useState<boolean>(false)
  const [focusedOauth2Id, setFocusedOauth2Id] = useState<number>()
  const [clientId, setClientId] = useState<string>('')
  const [clientSecret, setClientSecret] = useState<string>('')
  const [redirectUri, setRedirectUri] = useState<string>('')
  const [companyId, setCompanyId] = useState<number>(0)
  const [oauth2List, setOauth2List] = useState<Array<Oauth2ListItemInterface>>([])
  const [companyList, setCompanyList] = useState<Array<CompanyListItemInterface>>([])
  const [showRemoveOauth2Confirmation, setShowRemoveOauth2Confirmation] = useState<boolean>(false)
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(0)
  const oauthForm = useRef<HTMLFormElement>(null)

  const submitOauth2 = () => {
    if (!oauthForm.current?.reportValidity()) {
      return
    }

    const form = new URLSearchParams()

    if (focusedOauth2Id) {
      form.append('id', focusedOauth2Id.toString())
    }

    form.append('company_id', companyId.toString())
    form.append('client_id', clientId)
    form.append('client_secret', clientSecret)
    form.append('redirect_uri', redirectUri)

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: form
    }

    fetch(`${API2}/oauth2-client`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.message !== 'OK') {
          toast('Failed to save oauth2 client!', {type: 'error'})
          return
        }

        toast('Oauth2 client saved!', {type: 'success'})
        setShowOauth2Form(false)
        setUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }

  const removeOauth2 = () => {
    const fetchInitOpt: RequestInit = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    fetch(`${API2}/oauth2-client/${focusedOauth2Id}`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.message !== 'OK') {
          toast('Failed to remove oauth2 client!', {type: 'error'})
          return
        }

        toast('Oauth2 client removed!', {type: 'success'})
        setShowRemoveOauth2Confirmation(false)
        setUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }

  useEffect(() => {
    if (!token) {
      return
    }

    fetch(`${API2}/company`)
      .then(response => response.json())
      .then(response => {
        if (response.message !== 'OK') {
          toast('Failed to get the company list!', {type: 'error'})
          return
        }

        setCompanyList(response.result)
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))

    const fetchInitOpt: RequestInit = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    fetch(`${API2}/oauth2-client`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.message !== 'OK') {
          toast('Failed to get oauth2 client list!', {type: 'error'})
          return
        }

        setOauth2List(response.result)
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }, [token, updateTimestamp])

  return (
    <>
      <div className="mt-3">
        <div className="box">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <div className="title is-size-4">Oauth 2 Management</div>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <button
                  className="button is-small is-primary"
                  onClick={() => {
                    setShowOauth2Form(true)
                    setFocusedOauth2Id(undefined)
                    setClientId(UUIDv4())
                    setClientSecret(CryptoLib.WordArray.random(16).toString())
                    setCompanyId(0)
                    setRedirectUri('')
                  }}
                >
                  <span className="icon is-small is-left">
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </span>
                  <span>Add New</span>
                </button>
              </div>
            </div>
          </div>
          <div className="table-container">
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Client ID</th>
                  <th>Client Secret</th>
                  <th>Redirect URI</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {oauth2List.map(item => (
                  <tr key={item.id}>
                    <td>{item.companyName}</td>
                    <td>{item.clientId}</td>
                    <td>{item.clientSecret}</td>
                    <td>{item.redirectUri}</td>
                    <td>
                      <button
                        className="button is-small is-primary mr-1"
                        onClick={() => {
                          setShowOauth2Form(true)
                          setFocusedOauth2Id(item.id)
                          setClientId(item.clientId)
                          setClientSecret(item.clientSecret)
                          setCompanyId(item.companyId)
                          setRedirectUri(item.redirectUri)
                        }}
                      >
                        <span className="icon is-small is-left">
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </span>
                        <span>Edit</span>
                      </button>
                      <button
                        className="button is-small is-danger"
                        onClick={() => {
                          setShowRemoveOauth2Confirmation(true)
                          setFocusedOauth2Id(item.id)
                        }}
                      >
                        <span className="icon is-small is-left">
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </span>
                        <span>Delete</span>
                      </button>
                    </td>
                  </tr>
                ))}
                {oauth2List.length < 1 && (
                  <tr>
                    <td colSpan={5}>
                      No data found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className={'modal ' + (showOauth2Form ? 'is-active' : '')}>
        <div className="modal-background" onClick={() => setShowOauth2Form(false)} />
        <div className="modal-content">
          <div className="box">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <div className="title is-size-4">OAuth 2 Form</div>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <button className="button is-small is-primary" onClick={submitOauth2}>
                    <div className="icon is-small is-left">
                      <FontAwesomeIcon icon={faCheckCircle}/>
                    </div>
                    <span>Simpan</span>
                  </button>
                  <button className="button is-small is-default ml-1" onClick={() => setShowOauth2Form(false)}>
                    <div className="icon is-small is-left">
                      <FontAwesomeIcon icon={faTimesCircle}/>
                    </div>
                    <span>Batal</span>
                  </button>
                </div>
              </div>
            </div>
            <form ref={oauthForm}>
              <div className="field is-fullwidth">
                <label className="label">Company</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={companyId}
                      onChange={event => setCompanyId(parseInt(event.target.value))}
                      required
                    >
                      <option value={0}>Pilih Company</option>
                      {companyList.map(item => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="field is-fullwidth">
                <label className="label">Client ID</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Client ID"
                    value={clientId}
                    disabled
                  />
                </div>
              </div>
              <div className="field is-fullwidth">
                <label className="label">Client Secret</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Client Secret"
                    value={clientSecret}
                    disabled
                  />
                </div>
              </div>
              <div className="field is-fullwidth">
                <label className="label">Redirect URI</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Redirect URI"
                    value={redirectUri}
                    onChange={event => setRedirectUri(event.target.value)}
                    required
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className={'modal ' + (showRemoveOauth2Confirmation ? 'is-active' : '')}>
        <div className="modal-background" onClick={() => setShowRemoveOauth2Confirmation(false)} />
        <div className="modal-content">
          <div className="box">
            <div className="title is-size-4">Remove Oauth2 Client</div>
            <div className="content">
              <p>Are you sure to remove this oauth2 client?</p>
              <button className="button is-small is-danger" onClick={removeOauth2}>
                <div className="icon is-small is-left">
                  <FontAwesomeIcon icon={faTrashAlt}/>
                </div>
                <span>Continue Remove</span>
              </button>
              <button className="button is-small is-default ml-1" onClick={() => setShowRemoveOauth2Confirmation(false)}>
                <div className="icon is-small is-left">
                  <FontAwesomeIcon icon={faTimesCircle}/>
                </div>
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StoreConnector(Oauth2Management)
