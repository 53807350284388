import { faCheckCircle, faPencilAlt, faPlusCircle, faTimesCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useEffect, useState } from "react"
import { ReactSortable } from "react-sortablejs"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"

const API2 = process.env.REACT_APP_API2

interface CategoryListItem {
  id: number
  title: string
  img?: string
}

function FoodCategory(props: StoreProps) {
  const { token } = props

  const [categoryList, setCategoryList] = useState<Array<CategoryListItem>>([])
  const [focusedCategory, setFocusedCategory] = useState<number>()
  const [title, setTitle] = useState<string>('')
  const [img, setImg] = useState<string>()
  const [uploadedImg, setUploadedImg] = useState<File>()
  const [categoryFormVisible, setCategoryFormVisibility] = useState<boolean>(false)
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(Date.now())
  const [findQuery, setFindQuery] = useState<string>('')

  const categoryForm = (id?: number) => {
    setCategoryFormVisibility(true)
    setFocusedCategory(id)

    const foundCategory = categoryList.find(category => category.id === id)
    setTitle(foundCategory?.title ?? '')
    setImg(foundCategory?.img ?? '')
    setUploadedImg(undefined)
  }

  const uploadImageHandler = (files: FileList) => {
    if (files.length < 1) {
      return
    }

    if (files[0].size > 1000000) {
      toast('Ukuran file maksimal adalah 1MB', {type: 'error'})
      return
    }

    const reader = new FileReader()

    reader.onload = (ev: ProgressEvent<FileReader>) => {
      const result = ev.target?.result

      if (!result) {
        return
      }

      setUploadedImg(files[0])
      setImg(result.toString())
    }

    reader.readAsDataURL(files[0])
  }

  const submitCategory = () => {
    if (!token) {
      return
    }

    const form = uploadedImg ? new FormData() : new URLSearchParams()

    form.append('title', title)

    if (focusedCategory) {
      form.append('id', String(focusedCategory))
    }

    if (uploadedImg) {
      form.append('img', uploadedImg)
    }

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: form
    }

    fetch(`${API2}/administration/food-category`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal menyimpan data!', {type: 'error'})
          return
        }

        toast('Berhasil menyimpan data!', {type: 'success'})
        setCategoryFormVisibility(false)
        setUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }

  const removeCategory = (id: number) => {
    if (!token || !window.confirm('Anda yakin akan menghapus data ini?')) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'DELETE',
      headers: {
        Authorization: token
      }
    }

    fetch(`${API2}/administration/food-category/${id}`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal menghapus data!', {type: 'error'})
          return
        }

        toast('Data berhasil dihapus!', {type: 'success'})
        setUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }

  const sortHandler = () => {
    if (!token) {
      return
    }

    const form = new URLSearchParams()
    
    categoryList.map((category, index) => form.append(`sorted_category[${index}]`, String(category.id)))

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: form
    }

    fetch(`${API2}/administration/sort-food-category`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.message === 'ERROR') {
          toast('Gagal memperbarui urutan!', {type: 'error'})
          return
        }

        setUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        Authorization: token
      }
    }

    fetch(`${API2}/administration/food-category`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal mengambil data!', {type: 'error'})
          return
        }

        setCategoryList(response)
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }, [token, updateTimestamp])

  return (
    <Fragment>
      <div className="mt-3">
        <div className="columns is-centered">
          <div className="column is-6">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">Kategori Makanan</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <input type="text" className="input is-small mr-1" placeholder="Cari" value={findQuery} onChange={ev => setFindQuery(ev.target.value)} />
                    <button className="button is-small is-primary" onClick={() => categoryForm()}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faPlusCircle}/>
                      </div>
                      <span>Tambah Kategory</span>
                    </button>
                  </div>
                </div>
              </div>
              <ReactSortable list={categoryList} setList={setCategoryList} onEnd={sortHandler}>
                {categoryList.map(category => (
                  <div className="box" key={category.id} onDrag={ev => ev.currentTarget.className = 'box has-background-warning'} style={{cursor: 'move'}}>
                    <div className="columns">
                      <div className="column is-narrow">
                        <img src={category.img ?? 'https://via.placeholder.com/100x100?text=No Image'} alt="" style={{width: 150, height: 150, objectFit: 'contain'}} />
                      </div>
                      <div className="column">
                        <h6 className="title is-size-6">{category.title}</h6>
                        <button className="button is-small is-primary" onClick={() => categoryForm(category.id)}>
                          <div className="icon is-small is-left">
                            <FontAwesomeIcon icon={faPencilAlt}/>
                          </div>
                          <span>Ubah</span>
                        </button>
                        <button className="button is-small is-danger ml-1" onClick={() => removeCategory(category.id)}>
                          <div className="icon is-small is-left">
                            <FontAwesomeIcon icon={faTrashAlt}/>
                          </div>
                          <span>Hapus</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </ReactSortable>
            </div>
          </div>
        </div>
      </div>
      {categoryFormVisible && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setCategoryFormVisibility(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">Form Kategori</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-small is-primary" onClick={submitCategory}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faCheckCircle}/>
                      </div>
                      <span>Simpan</span>
                    </button>
                    <button className="button is-small is-default ml-1" onClick={() => setCategoryFormVisibility(false)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTimesCircle}/>
                      </div>
                      <span>Batal</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Nama</label>
                <div className="control">
                  <input type="text" className="input" value={title} onChange={ev => setTitle(ev.target.value)} />
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Img</label>
                <div className="control">
                  <input
                    type="file"
                    className="input"
                    accept="image/*"
                    onChange={ev => ev.target.files !== null && uploadImageHandler(ev.target.files)}
                  />
                </div>
              </div>
              {img && (
                <img src={img} width={200} height={200} style={{objectFit: 'contain'}} alt="" className="image" />
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default StoreConnector(FoodCategory)