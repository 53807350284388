import {
  faCheckCircle,
  faPlusCircle,
  faTimesCircle,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"

const API2 = process.env.REACT_APP_API2 ?? 'http://localhost:8080'

interface ShiftInterface {
  id: number
  start_time: string
  end_time: string
  deleted: 1|0
  shift_range: number
  online: 1|0
  offline: 1|0
  speciality: number
  price: number
}

interface DayListItemInterface {
  id: number
  day_index: number
  deleted: 1|0
  name: string
  shifts: Array<ShiftInterface>
}

interface SpecialityListItem {
  id: number
  deleted: 1|0
  max: number
  name: string
  background: string|null
  cover: string|null
}

function ShiftList(props: StoreProps) {
  const { token } = props

  const [dayList, setDayList] = useState<Array<DayListItemInterface>>([])
  const [showEditShiftModal, setEditShiftModalVisibility] = useState<boolean>(false)
  const [focusedDay, setFocusedDay] = useState<number>()
  const [focusedShift, setFocusedShift] = useState<number>()
  const [startTime, setStartTime] = useState<string>('')
  const [endTime, setEndTime] = useState<string>('')
  const [scheduleType, setScheduleType] = useState<string>('')
  const [speciality, setSpeciality] = useState<number>()
  const [price, setPrice] = useState<number>(0)
  const [specialityList, setSpecialityList] = useState<Array<SpecialityListItem>>([])
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(Date.now())

  const updateShift = (dayId: number, shiftId?: number) => {
    setEditShiftModalVisibility(true)
    setFocusedDay(dayId)
    setFocusedShift(shiftId)

    const foundShift = dayList.find(day => day.id === dayId)?.shifts.find(shift => shift.id === shiftId)

    setStartTime(foundShift?.start_time ?? '')
    setEndTime(foundShift?.end_time ?? '')
    setScheduleType(foundShift?.online === 1 ? 'online' : 'offline')
    setSpeciality(foundShift?.speciality ?? 0)
    setPrice(foundShift?.price ?? 0)
  }

  const doUpdateShift = () => {
    const dayListCopy = [...dayList]
  
    const foundDayIndex = dayList.findIndex(day => day.id === focusedDay)
  
    if (foundDayIndex < 0) {
      return
    }
  
    const foundShiftIndex = dayList[foundDayIndex].shifts.findIndex(shift => shift.id === focusedShift)
    const shiftRange = 1
  
    if (foundShiftIndex < 0) {
      dayListCopy[foundDayIndex].shifts.push({
        id: 0 - dayList[foundDayIndex].shifts.length,
        deleted: 0,
        start_time: startTime,
        end_time: endTime,
        shift_range: shiftRange,
        online: scheduleType === 'online' ? 1 : 0,
        offline: scheduleType === 'offline' ? 1 : 0,
        speciality: speciality ?? 0,
        price: price
      })
    } else {
      dayListCopy[foundDayIndex].shifts[foundShiftIndex] = {
        ...dayList[foundDayIndex].shifts[foundShiftIndex],
        deleted: 0,
        start_time: startTime,
        end_time: endTime,
        shift_range: shiftRange,
        online: scheduleType === 'online' ? 1 : 0,
        offline: scheduleType === 'offline' ? 1 : 0,
        speciality: speciality ?? 0,
        price: price
      }
    }

    setDayList(dayListCopy)
    setEditShiftModalVisibility(false)
  }

  const removeShift = () => {
    const dayListCopy = [...dayList]
  
    const foundDayIndex = dayList.findIndex(day => day.id === focusedDay)
  
    if (foundDayIndex < 0) {
      return
    }
  
    const foundShiftIndex = dayList[foundDayIndex].shifts.findIndex(shift => shift.id === focusedShift)

    if (foundShiftIndex > -1) {
      dayListCopy[foundDayIndex].shifts[foundShiftIndex].deleted = 1
    }

    setDayList(dayListCopy)
    setEditShiftModalVisibility(false)
  }

  const submit = () => {
    if (!token) {
      return
    }

    const form = new URLSearchParams()

    dayList.map((day, dayIndex) => day.shifts.map((shift, shiftIndex) => {
      form.append(`shift[${dayIndex}][${shiftIndex}][id]`, String(shift.id))
      form.append(`shift[${dayIndex}][${shiftIndex}][day_id]`, String(day.id))
      form.append(`shift[${dayIndex}][${shiftIndex}][start]`, shift.start_time)
      form.append(`shift[${dayIndex}][${shiftIndex}][end]`, shift.end_time)
      form.append(`shift[${dayIndex}][${shiftIndex}][scheduleType]`, shift.online === 1 ? 'online' : 'offline')
      form.append(`shift[${dayIndex}][${shiftIndex}][price]`, String(shift.price))

      if (shift.speciality) {
        form.append(`shift[${dayIndex}][${shiftIndex}][speciality]`, String(shift.speciality))
      }
 
      form.append(`shift[${dayIndex}][${shiftIndex}][deleted]`, String(shift.deleted))

      return undefined
    }))

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: form
    }

    fetch(`${API2}/trainer/shift`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('gagal menyimpan data shift!', {type: 'error'})
          return
        }

        toast('Berhasil menyimpan data shift!', {type: 'success'})
        setUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        Authorization: token
      }
    }

    const tasks = [
      '/trainer/shift',
      '/trainer/speciality/'
    ]

    Promise.all(tasks.map(task => fetch(API2 + task, fetchInitOpt)))
      .then(async responses => await Promise.all(responses.map(response => response.json())))
      .then(responses => {
        const [dayListResponse, specialityListResponse] = responses

        setDayList(dayListResponse)
        setSpecialityList(specialityListResponse)
      })
      .catch(() => toast('Gagal mengambil data!', {type: 'error'}))
  }, [token, updateTimestamp])

  return (
    <Fragment>
      <div className="mt-3">
        <div className="box">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h4 className="title is-size-4">Jadwal Trainer</h4>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <button className="button is-small is-primary" onClick={submit}>
                  <div className="icon is-small is-left">
                    <FontAwesomeIcon icon={faCheckCircle}/>
                  </div>
                  <span>Simpan</span>
                </button>
              </div>
            </div>
          </div>
          <div style={{overflowX: 'auto'}}>
            <table className="table is-bordered is-fullwidth" style={{whiteSpace: 'nowrap'}}>
              <thead>
                <tr>
                  <th style={{width: 120}}>Hari</th>
                  <th>Jadwal</th>
                </tr>
              </thead>
              <tbody>
                {dayList.sort((prev, next) => prev.day_index - next.day_index).filter(day => day.deleted === 0).map(day => (
                  <tr key={day.id}>
                    <td>{day.name}</td>
                    <td>
                      {day.shifts.filter(shift => shift.deleted === 0).map(shift => (
                        <button key={shift.id} className="button is-small is-primary m-1" onClick={() => updateShift(day.id, shift.id)}>
                          <span>{shift.start_time.substring(0, 5)} - {shift.end_time.substring(0, 5)} {shift.online === 1 ? 'ONLINE' : 'OFFLINE'} - {specialityList.find(data => data.id === shift.speciality)?.name}</span>
                        </button>
                      ))}
                      <button className="button is-small is-primary m-1" onClick={() => updateShift(day.id)}>
                        <div className="icon is-small">
                          <FontAwesomeIcon icon={faPlusCircle}/>
                        </div>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showEditShiftModal && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setEditShiftModalVisibility(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">{focusedShift !== undefined ? 'Ubah' : 'Buat'} Data Jadwal</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-small is-primary" onClick={doUpdateShift}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faCheckCircle}/>
                      </div>
                      <span>Simpan</span>
                    </button>
                    {focusedShift !== undefined && (
                      <button className="button is-small is-danger ml-1" onClick={removeShift}>
                        <div className="icon is-small is-left">
                          <FontAwesomeIcon icon={faTrashAlt}/>
                        </div>
                        <span>Hapus</span>
                      </button>
                    )}
                    <button className="button is-small is-default ml-1" onClick={() => setEditShiftModalVisibility(false)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTimesCircle}/>
                      </div>
                      <span>Batal</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label htmlFor="">Mulai</label>
                    <div className="control">
                      <input
                        type="time"
                        className="input"
                        value={startTime}
                        onChange={ev => setStartTime(ev.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label htmlFor="">Selesai</label>
                    <div className="control">
                      <input
                        type="time"
                        className="input"
                        value={endTime}
                        onChange={ev => setEndTime(ev.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns is-multiline">
                <div className="column is-narrow">
                  <label htmlFor="">Jenis jadwal</label>
                  <div className="field">
                    <div className="select">
                      <select value={scheduleType} onChange={ev => setScheduleType(ev.target.value)}>
                        <option value="">Pilih Jenis Jadwal</option>
                        <option value="online">Online</option>
                        <option value="offline">Offline</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="column is-narrow">
                  <label htmlFor="">Speciality</label>
                  <div className="field">
                    <div className="select">
                      <select value={speciality} onChange={ev => setSpeciality(Number(ev.target.value))}>
                        <option value="">Pilih speciality</option>
                        {specialityList.map(speciality => (
                          <option value={speciality.id} key={speciality.id}>{speciality.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="column is-narrow">
                  <label htmlFor="">Harga</label>
                  <div className="control">
                    <input type="number" className="input" min="0" value={price} onChange={ev => setPrice(Number(ev.target.value))} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default StoreConnector(ShiftList)