export default function ConvertStatus(status: string) {
  switch (status) {
    case 'AWAITING_PAYMENT':
      return 'Menunggu Pembayaran'

    case 'PAID':
      return 'Dibayar'

    case 'ACCEPT':
      return 'Diterima'

    case 'REJECT':
      return 'Ditolak'

    case 'ON_GOING':
      return 'Sedang Berlangsung'

    case 'SENT':
      return 'Dikirim'
    
    case 'DONE':
      return 'Selesai'

    case 'PROCESSED':
      return 'Diproses'

    default:
      return status
  }
}
