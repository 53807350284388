import { useEffect } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import Dashboard, { MenuListInterface } from "../layouts/Dashboard"
import { StoreConnector, StoreProps } from "../redux/Actions"
import NutritionistOrder from "./nutritionist/NutritionistOrder"
import NutritionistProfile from "./nutritionist/NutritionistProfile"
import ShiftList from "./nutritionist/ShiftList"
import SpecialityList from "./nutritionist/SpecialityList"

function NutritionistDashboard(props: StoreProps) {
  const { privilege } = props

  const menuList: Array<MenuListInterface> = [
    {
      label: 'Management',
      links: [
        {
          title: 'Speciality',
          path: '/nutritionist/speciality'
        },
        {
          title: 'Shift',
          path: '/nutritionist/shift'
        }
      ]
    },
    {
      label: 'Order',
      links: [
        {
          title: 'Online Order',
          path: '/nutritionist/order/online'
        },
        {
          title: 'Offline Order',
          path: '/nutritionist/order/offline'
        }
      ]
    },
    {
      label: 'Account',
      links: [
        {
          title: 'Profile',
          path: '/nutritionist/profile'
        }
      ]
    }
  ]

  useEffect(() => {
    window.document.title = 'Nutritionist Dashboard'
  }, [])

  if (privilege !== 'nutritionist') {
    return (
      <Redirect to="/auth" />
    )
  }

  return (
    <Dashboard menuList={menuList}>
      <Switch>
        <Route path="/nutritionist/speciality">
          <SpecialityList/>
        </Route>
        <Route path="/nutritionist/shift">
          <ShiftList/>
        </Route>
        <Route path="/nutritionist/order/:type">
          <NutritionistOrder/>
        </Route>
        <Route path="/nutritionist/profile">
          <NutritionistProfile/>
        </Route>
      </Switch>
    </Dashboard>
  )
}

export default StoreConnector(NutritionistDashboard)