import { useEffect, useRef, useState } from "react";
import CompanyListItemInterface from "../interfaces/CompanyListItemInterface";
import { StoreConnector, StoreProps } from "../redux/Actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport, faUpload } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import exceljs from "exceljs";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";

const API2 = process.env.REACT_APP_API2

enum Gender {
  Male = 'male',
  Female = 'female'
}

interface EmployeeInterface {
  name: string
  email: string
  gender: Gender
  birthDate: Date
  phone: string
  password: string
  status: 'in queue'|'success'|'failed'
}

declare function structuredClone<T>(obj: T): T

function ImportParticipant(props: StoreProps) {
  const { token } = props
  const [companyList, setCompanyList] = useState<Array<CompanyListItemInterface>>([])
  const [selectedCompany, setSelectedCompany] = useState<number>(0)
  const [employeeList, setEmployeeList] = useState<Array<EmployeeInterface>>([])
  const fileInputRef = useRef<HTMLInputElement>(null)

  const upload = async () => {
    if (!token) {
      return
    }

    const employeeListCopy = structuredClone(employeeList)

    for (let i = 0; i < employeeList.length; i++) {
      const form = new FormData()

      form.append('name', employeeList[i].name)
      form.append('email', employeeList[i].email.toLowerCase())
      form.append('gender', employeeList[i].gender)
      form.append('birthDate', employeeList[i].birthDate.toISOString())
      form.append('phone', employeeList[i].phone)
      form.append('company', selectedCompany.toString())
      
      const fetchInitOpt: RequestInit = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: form
      }

      await new Promise<void>((resolve) => {
        fetch(`${API2}/import/b2b`, fetchInitOpt)
          .then(res => res.json())
          .then(res => {
            if (res.message == 'ERROR') {
              employeeListCopy[i].status = 'failed'
              return
            }
  
            employeeListCopy[i].status = 'success'
          })
          .catch(_ => {
            employeeListCopy[i].status = 'failed'
          })
          .finally(() => {
            resolve()
          })
      })
    }

    setEmployeeList(employeeListCopy)
  }

  const downloadTemplate = () => {
    const workbook = new exceljs.Workbook()
    const worksheet = workbook.addWorksheet('Peserta')

    worksheet.columns = [
      { header: 'Nama', key: 'name' },
      { header: 'Email', key: 'email'},
      { header: 'Gender (M/F)', key: 'gender'},
      { header: 'Tanggal Lahir (YYYY-MM-DD)', key: 'birthDate'},
      { header: 'No. HP', key: 'phone'}
    ]

    worksheet.addRow([
      'John Doe',
      'johndoe@fakemail.com',
      'M',
      '1990-01-01',
      '081234567890'
    ])

    worksheet.addRow([
      'Jane Doe',
      'janedoe@fakemail.com',
      'F',
      '1990-01-01',
      '081234567890'
    ])

    workbook.xlsx.writeBuffer()
      .then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        saveAs(blob, 'Peserta.xlsx')
      })
      .catch(err => {
        console.error(err)
        toast.error('Gagal mengunduh template')
      })
  }

  const extractEmployee = (file: File) => {
    const reader = new FileReader()

    reader.onload = async (ev: ProgressEvent<FileReader>) => {
      if (!ev.target?.result || typeof ev.target.result !== 'object') {
        return
      }

      const buffer = Buffer.from(ev.target.result)
      const workbook = new exceljs.Workbook()
      await workbook.xlsx.load(buffer)

      const worksheet = workbook.getWorksheet(1)
      const rows = worksheet.getRows(2, worksheet.rowCount)

      const employeeList: Array<EmployeeInterface> = []
      
      rows?.forEach(row => {
        const name = row.getCell('A').text
        const email = row.getCell('B').text
        const gender = row.getCell('C').text === 'M' ? Gender.Male : Gender.Female
        const birthDate = new Date(row.getCell('D').text)
        const phone = row.getCell('E').text

        if (!name || !email || !gender || !birthDate || !phone) {
          return
        }

        employeeList.push({
          name,
          email,
          gender,
          birthDate,
          phone,
          password: 'MufitGo2023!',
          status: 'in queue'
        })
      })

      setEmployeeList(employeeList)
    }

    reader.readAsArrayBuffer(file)
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    fetch(`${API2}/company`, fetchInitOpt)
      .then(res => res.json())
      .then(res => {
        if (res.message == 'ERROR') {
          toast.error('Gagal mengambil data perusahaan')
          return
        }

        setCompanyList(res.result)
      })
      .catch(err => {
        toast.error('Gagal mengambil data perusahaan')
        console.error(err)
      })
  }, [token])

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        accept=".xlsx"
        className="is-hidden"
        onChange={ev => {
          if (!ev.target.files) {
            return
          }

          const file = ev.target.files[0]
          extractEmployee(file)
        }}
      />
      <div className="mt-3">
        <div className="box">
          <h4 className="title is-size-4">Import Peserta</h4>
          <h6 className="subtitle is-size-6">(password otomatis)</h6>
          <hr />
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <div className="field">
                  <div className="control">
                    <div className="select">
                      <select
                        onChange={e => setSelectedCompany(parseInt(e.target.value))}
                        value={selectedCompany}
                      >
                        <option>Pilih Perusahaan</option>
                        {companyList.map((company, index) => (
                          <option key={index} value={company.id}>{company.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <button className="button is-primary is-small mr-1" onClick={downloadTemplate}>
                  <span className="icon">
                    <FontAwesomeIcon icon={faFileImport} />
                  </span>
                  <span>Unduh Template</span>
                </button>
                <button className="button is-primary is-small mr-1" onClick={() => fileInputRef.current?.click()}>
                  <span className="icon">
                    <FontAwesomeIcon icon={faUpload} />
                  </span>
                  <span>Upload File</span>
                </button>
                <button className="button is-primary is-small" onClick={upload}>
                  <span className="icon">
                    <FontAwesomeIcon icon={faFileImport} />
                  </span>
                  <span>Proses Import</span>
                </button>
              </div>
            </div>
          </div>
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>Nama</th>
                <th>Email</th>
                <th>Password</th>
                <th>Jenis Kelamin</th>
                <th>Tanggal Lahir</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {employeeList.map((employee, index) => (
                <tr key={index}>
                  <td>{employee.name}</td>
                  <td>{employee.email}</td>
                  <td>{employee.password}</td>
                  <td>{employee.gender === Gender.Male ? 'Laki-laki' : 'Perempuan'}</td>
                  <td>{employee.birthDate.toDateString()}</td>
                  <td>{employee.status}</td>
                </tr>
              ))}
              {employeeList.length < 1 && (
                <tr>
                  <td colSpan={4}>Tidak ada data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default StoreConnector(ImportParticipant)
