import { faCheckCircle, faEye, faPencilAlt, faPlusCircle, faTimesCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"

const API2 = process.env.REACT_APP_API2

export interface VendorListItem {
  id?: number
  email: string
  phone: string
  description: string
  name: string
  city: string
  province: string
  longitude: string
  latitude: string
  open: string
  close: string
  img?: string
}

function VendorList(props: StoreProps) {
  const { token } = props

  const [vendorList, setVendorList] = useState<Array<VendorListItem>>([])
  const [focusedVendor, setFocusedVendor] = useState<number>()

  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [province, setProvince] = useState<string>('')
  const [latitude, setLatitude] = useState<string>('0')
  const [longitude, setLongitude] = useState<string>('0')
  const [open, setOpen] = useState<string>('08:00')
  const [close, setClose] = useState<string>('17:00')
  const [img, setImg] = useState<string>()
  const [uploadedImg, setUploadedImg] = useState<File>()
  
  const [findQuery, setFindQuery] = useState<string>('')

  const [vendorFormVisible, setVendorFormVisibility] = useState<boolean>(false)
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(Date.now())

  const [emailValid, setEmailValid] = useState<boolean>(true)
  const [page, setPage] = useState<number>(0)

  const limit = 20

  const uploadImageHandler = (files: FileList) => {
    if (files.length < 1) {
      return
    }

    if (files[0].size > 1000000) {
      toast('Ukuran file maksimal adalah 1MB', {type: 'error'})
      return
    }

    const reader = new FileReader()

    reader.onload = (ev: ProgressEvent<FileReader>) => {
      const result = ev.target?.result

      if (!result) {
        return
      }

      setImg(result.toString())
    }
    
    setUploadedImg(files[0])
    reader.readAsDataURL(files[0])
  }

  const vendorForm = (id?: number) => {
    setFocusedVendor(id)
    
    const foundVendor = vendorList.find(vendor => vendor.id === id)

    setEmail(foundVendor?.email ?? '')
    setPhone(foundVendor?.phone ?? '')
    setDescription(foundVendor?.description ?? '')
    setName(foundVendor?.name ?? '')
    setCity(foundVendor?.city ?? '')
    setProvince(foundVendor?.province ?? '')
    setLongitude(foundVendor?.longitude ?? '0')
    setLatitude(foundVendor?.latitude ?? '0')
    setOpen(foundVendor?.open ?? '08:00')
    setClose(foundVendor?.close ?? '17:00')
    setImg(foundVendor?.img)
    setPassword('')
    setUploadedImg(undefined)

    setVendorFormVisibility(true)
  }

  const submitVendor = () => {
    if (!token) {
      return
    }

    if (!emailValid && focusedVendor === undefined) {
      toast('Email sudah digunakan di akun lain!', {type: 'error'})
      return
    }

    const form = uploadedImg !== undefined ? new FormData() : new URLSearchParams()

    if (focusedVendor !== undefined) {
      form.append('id', String(focusedVendor))
    }

    form.append('email', email)
    form.append('phone', phone)
    form.append('description', description)
    form.append('name', name)
    form.append('city', city)
    form.append('province', province)
    form.append('latitude', latitude)
    form.append('longitude', longitude)
    form.append('open', open)
    form.append('close', close)
    form.append('password', password)
    
    if (uploadedImg !== undefined) {
      form.append('img', uploadedImg)
    }

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: form
    }

    fetch(`${API2}/administration/vendor`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal menyimpan data!', {type: 'error'})
          return
        }

        toast('Berhasil menyimpan data!', {type: 'success'})
        setUpdateTimestamp(Date.now())
        setVendorFormVisibility(false)
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }

  const removeVendor = (id?: number) => {
    if (!window.confirm('Anda yakin akan menghapus vendor ini?') || id === undefined || !token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'DELETE',
      headers: {
        Authorization: token
      }
    }

    fetch(`${API2}/administration/vendor/${id}`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal menghapus data!', {type: 'error'})
          return
        }
        
        toast('Data berhasil dihapus!', {type: 'success'})
        setUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        Authorization: token
      }
    }

    fetch(`${API2}/administration/vendor`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal mengambil data!', {type: 'error'})
          return
        }

        setVendorList(response)
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }, [token, updateTimestamp])

  useEffect(() => {
    fetch(`${API2}/check-email?email=${email}`)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal memverifikasi email!', {type: 'error'})
          return
        }

        setEmailValid(!response.result.exists)
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }, [email])

  useEffect(() => {
    setPage(0)
  }, [findQuery])

  return (
    <Fragment>
      <div className="mt-3">
        <div className="box">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h4 className="title is-size-4">Daftar Vendor</h4>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <input type="text" className="input is-small mr-1" placeholder="Cari" value={findQuery} onChange={ev => setFindQuery(ev.target.value)} />
                <button className="button is-small is-primary" onClick={() => vendorForm()}>
                  <div className="icon is-small is-left">
                    <FontAwesomeIcon icon={faPlusCircle}/>
                  </div>
                  <span>Tambah Vendor</span>
                </button>
              </div>
            </div>
          </div>
          <div style={{overflowX: 'auto'}}>
            <table className="table is-bordered is-fullwidth" style={{whiteSpace: 'nowrap'}}>
              <thead>
                <tr>
                  <th>Nama</th>
                  <th>Email</th>
                  <th>No. HP</th>
                  <th>Provinsi</th>
                  <th>Kota</th>
                  <th style={{width: 170}}>Aksi</th>
                </tr>
              </thead>
              <tbody>
                {vendorList
                  .slice(page * limit, (page + 1) * limit)
                  .filter(vendor => vendor.name.match(new RegExp(findQuery, 'ig')) || vendor.email.match(new RegExp(findQuery, 'ig')))
                  .map(vendor => (
                  <tr key={vendor.id}>
                    <td>{vendor.name}</td>
                    <td>{vendor.email}</td>
                    <td>{vendor.phone}</td>
                    <td>{vendor.province}</td>
                    <td>{vendor.city}</td>
                    <td>
                      <Link to={`/admin/do-monitor/vendor/${vendor.id}?token=${token}`} className="button is-small is-primary" target="_blank">
                        <div className="icon is-small is-left">
                          <FontAwesomeIcon icon={faEye}/>
                        </div>
                        <span>Monitor</span>
                      </Link>
                      <button className="button is-small is-primary ml-1" onClick={() => vendorForm(vendor.id)}>
                        <div className="icon is-small is-left">
                          <FontAwesomeIcon icon={faPencilAlt}/>
                        </div>
                        <span>Ubah</span>
                      </button>
                      <button className="button is-small is-danger ml-1" onClick={() => removeVendor(vendor.id)}>
                        <div className="icon is-small is-left">
                          <FontAwesomeIcon icon={faTrashAlt}/>
                        </div>
                        <span>Hapus</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {vendorList.length > limit && (
              <div className="field has-addons">
                <p className="control">
                  <button className="button is-small" onClick={() => page > 0 && setPage(page - 1)}>
                    &lt;&lt;
                  </button>
                </p>
                {Object.keys(Array(Math.ceil(vendorList.filter(vendor => vendor.name.match(new RegExp(findQuery, 'ig')) || vendor.email.match(new RegExp(findQuery, 'ig'))).length / limit)).fill(undefined)).map(key => (
                  <p className="control" key={key}>
                    <button className={`button is-small ${page === Number(key) ? 'is-primary' : 'is-default'}`} onClick={() => setPage(Number(key))}>
                      {Number(key) + 1}
                    </button>
                  </p>
                ))}
                <p className="control">
                  <button className="button is-small" onClick={() => page < Math.ceil(vendorList.filter(vendor => vendor.name.match(new RegExp(findQuery, 'ig')) || vendor.email.match(new RegExp(findQuery, 'ig'))).length / limit) - 1 && setPage(page + 1)}>
                    &gt;&gt;
                  </button>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {vendorFormVisible && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setVendorFormVisibility(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">{focusedVendor ? 'Ubah' : 'Tambah'} Vendor</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-small is-primary" onClick={submitVendor}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faCheckCircle}/>
                      </div>
                      <span>Simpan</span>
                    </button>
                    <button className="button is-small is-default ml-1" onClick={() => setVendorFormVisibility(false)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTimesCircle}/>
                      </div>
                      <span>Batal</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Nama</label>
                <div className="control">
                  <input type="text" className="input" name="name" value={name} onChange={ev => setName(ev.target.value)} />
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Email</label>
                <div className="control">
                  <input type="email" className="input" value={email} onChange={ev => setEmail(ev.target.value)} />
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Password</label>
                <div className="control">
                  <input type="password" className="input" onChange={ev => setPassword(ev.target.value)} />
                </div>
              </div>
              <div className="field">
                <label htmlFor="">No. HP</label>
                <div className="control">
                  <input type="text" className="input" value={phone} onChange={ev => setPhone(ev.target.value)} />
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Deskripsi</label>
                <div className="control">
                  <textarea className="textarea" value={description} onChange={ev => setDescription(ev.target.value)} />
                </div>
              </div>
              <div className="columns mt-1">
                <div className="column is-6">
                  <div className="field">
                    <label htmlFor="">Jam Buka</label>
                    <div className="control">
                      <input type="time" className="input" value={open} onChange={ev => setOpen(ev.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label htmlFor="">Jam Tutup</label>
                    <div className="control">
                      <input type="time" className="input" value={close} onChange={ev => setClose(ev.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns mt-1 is-multiline">
                <div className="column is-6">
                  <div className="field">
                    <label htmlFor="">City</label>
                    <div className="control">
                      <input type="text" className="input" value={city} onChange={ev => setCity(ev.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label htmlFor="">Province</label>
                    <div className="control">
                      <input type="text" className="input" value={province} onChange={ev => setProvince(ev.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label htmlFor="">Latitude</label>
                    <div className="control">
                      <input type="number" className="input" step="0.000001" value={latitude} onChange={ev => setLatitude(ev.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label htmlFor="">Longitude</label>
                    <div className="control">
                      <input type="number" className="input" step="0.000001" value={longitude} onChange={ev => setLongitude(ev.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Foto</label>
                <div className="control">
                  <input type="file" className="input" accept="image/*" onChange={ev => ev.target.files !== null && uploadImageHandler(ev.target.files)} />
                </div>
              </div>
              {img && (
                <img src={img} alt="" className="image" />
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default StoreConnector(VendorList)