import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { API1Response } from "../../interfaces/APIResponse"
import { StoreConnector, StoreProps } from "../../redux/Actions"

const API2 = process.env.REACT_APP_API2 ?? 'http://localhost:8080/api/v1'

interface Vendor {
  id: number
  name: string
  city: string
  province: string
  phone: string
  description: string
  email: string
  img?: string
  longitude: string
  latitude: string
}

function StoreProperties(props: StoreProps) {
  const { token } = props
  
  const [name, setName] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [province, setProvince] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [img, setImg] = useState<string>()
  const [uploadedImg, setUploadedImg] = useState<File>()
  const [longitude, setLongitude] = useState<string>('0.000000')
  const [latitude, setLatitude] = useState<string>('0.000000')
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(Date.now())

  const uploadHandler = (file: FileList|null) => {
    if (!file) {
      return
    }

    if (file[0].size > 1000000) {
      toast('Ukuran file maksimal adalah 1MB', {type: 'error'})
      return
    }

    const reader = new FileReader()

    reader.onload = (ev: ProgressEvent<FileReader>) => {
      const result = ev.target?.result

      if (!result) {
        return
      }

      setImg(result.toString())
      setUploadedImg(file[0])
    }

    reader.readAsDataURL(file[0])
  }

  const submit = () => {
    if (!token) {
      return
    }

    const form = uploadedImg ? new FormData() : new URLSearchParams()
    
    form.append('name', name)
    form.append('city', city)
    form.append('province', province)
    form.append('phone', phone)
    form.append('description', description)
    form.append('email', email)
    form.append('longitude', longitude)
    form.append('latitude', latitude)
    form.append('password', password)

    if (uploadedImg) {
      form.append('img', uploadedImg)
    }

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: form
    }

    fetch(`${API2}/vendor/properties`, fetchInitOpt)
      .then(response => response.json())
      .then((response: API1Response) => {
        if (response.message === 'ERROR') {
          toast('Gagal menyimpan data!', {type: 'error'})
          return
        }

        setUpdateTimestamp(Date.now())
        toast('Data berhasil disimpan!', {type: 'success'})
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    fetch(`${API2}/vendor/properties`, fetchInitOpt)
      .then(response => response.json())
      .then((response: API1Response<Vendor>) => {
        const { message, result } = response

        if (message === 'ERROR') {
          toast('Vendor data not found!', {type: 'error'})
          return
        }

        setName(result.name)
        setPhone(result.phone)
        setDescription(result.description)
        setEmail(result.email)
        setCity(result.city)
        setProvince(result.province)
        setImg(result.img)
        setLatitude(result.latitude)
        setLongitude(result.longitude)
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }, [token, updateTimestamp])

  return (
    <div className="mt-3">
      <div className="columns is-centered">
        <div className="column is-6">
          <div className="box">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h4 className="title is-size-4">Store Properties</h4>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <button className="button is-small is-primary" onClick={submit}>
                    <div className="icon is-small is-left">
                      <FontAwesomeIcon icon={faCheckCircle}/>
                    </div>
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="field">
              <label htmlFor="">Name</label>
              <div className="control">
                <input type="text" className="input" value={name} onChange={ev => setName(ev.target.value)}/>
              </div>
            </div>
            <div className="columns my-0">
              <div className="column">
                <div className="field">
                  <label htmlFor="">Email</label>
                  <div className="control">
                    <input type="email" className="input" value={email} onChange={ev => setEmail(ev.target.value)}/>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label htmlFor="">Phone</label>
                  <div className="control">
                    <input type="text" className="input" value={phone} onChange={ev => setPhone(ev.target.value)}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="field">
              <label htmlFor="">Password</label>
              <div className="control">
                <input type="password" className="input" onChange={ev => setPassword(ev.target.value)} />
              </div>
            </div>
            <div className="field">
              <label htmlFor="">Deskripsi</label>
              <div className="control">
                <textarea className="textarea" value={description} onChange={ev => setDescription(ev.target.value)} />
              </div>
            </div>
            <div className="columns my-0">
              <div className="column">
                <div className="field">
                  <label htmlFor="">Province</label>
                  <div className="control">
                    <input type="text" className="input" value={province} onChange={ev => setProvince(ev.target.value)}/>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label htmlFor="">City</label>
                  <div className="control">
                    <input type="text" className="input" value={city} onChange={ev => setCity(ev.target.value)}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns my-0">
              <div className="column">
                <div className="field">
                  <label htmlFor="">Photo</label>
                  <div className="control">
                    <input type="file" className="input" accept=".jpg,.png" onChange={ev => uploadHandler(ev.target.files)}/>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label htmlFor="">longitude</label>
                  <div className="control">
                    <input type="number" className="input" step={0.000001} value={Number(longitude)} onChange={ev => setLongitude(ev.target.value)}/>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label htmlFor="">Latitude</label>
                  <div className="control">
                    <input type="number" className="input" step={0.000001} value={Number(latitude)} onChange={ev => setLatitude(ev.target.value)}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns my-0">
              <div className="column is-4">
                {img && (
                  <img src={img} alt="" className="image is-fullwidth"/>
                )}
              </div>
              <div className="column is-8">
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoreConnector(StoreProperties)