import { ActionInterface } from "../Actions"
import { ActionTypes } from "../ActionTypes"

export default function UrlPrefix(state: string|null = null, action: ActionInterface<string>) {
  switch (action.type) {
    case ActionTypes.SetURLPrefix:
      state = action.payload
      break

    default:
  }

  return state
}