import { faArrowCircleLeft, faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ChangeEvent, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { API1Response } from "../../interfaces/APIResponse"
import { StoreConnector, StoreProps } from "../../redux/Actions"

const API2 = process.env.REACT_APP_API2

interface CategoryListItemInterface {
  id: number
  title: string
  img?: string
}

interface CheckedCategoryListItem {
  category: number
  checked: boolean
}

function FoodForm(props: StoreProps) {
  const { token, profile } = props
  const { id } = useParams<{id?: string}>()
  const history = useHistory()

  const [categoryList, setCategoryList] = useState<Array<CategoryListItemInterface>>([])
  const [checkedCategoryList, setCheckedCategoryList] = useState<Array<CheckedCategoryListItem>>([])
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [price, setPrice] = useState<number>(0)
  const [img, setImg] = useState<string>()
  const [uploadedImage, setUploadedImage] = useState<File>()

  const submit = () => {
    if (!token) {
      return
    }

    const form = uploadedImage !== undefined ? new FormData() : new URLSearchParams()

    if (id) {
      form.append('id', id)
    }

    form.append('name', name)
    form.append('description', description)
    form.append('price', price.toString())

    checkedCategoryList
      .filter(checked => checked.checked)
      .map(checked => form.append(`category[]`, String(checked.category)))
    
    if (uploadedImage) {
      form.append('img', uploadedImage)
    }

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: form
    }

    fetch(`${API2}/vendor/food`, fetchInitOpt)
      .then(response => response.json())
      .then((response: API1Response) => {
        if (response.message !== 'OK') {
          toast('Gagal menyimpan data!', {type: 'error'})
          return
        }

        toast('Berhasil menyimpan data!', {type: 'success'})
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }

  const uploadImage = (ev: ChangeEvent<HTMLInputElement>) => {
    if (!ev.target.files) {
      return
    }

    setUploadedImage(ev.target.files[0])
    
    const reader = new FileReader()

    reader.onload = (ev: ProgressEvent<FileReader>) => {
      if (!!!ev.target?.result) {
        return
      }

      setImg(ev.target?.result.toString())
    }

    reader.readAsDataURL(ev.target.files[0])
  }

  const checkHandler = (id: number, checked: boolean) => {
    const checkedCategoryCopy = [...checkedCategoryList]
    const foundIndex = checkedCategoryCopy.findIndex(data => data.category === id)

    if (foundIndex < 0) {
      checkedCategoryCopy.push({
        category: id,
        checked: checked
      })
    }

    if (foundIndex > -1) {
      checkedCategoryCopy[foundIndex] = {
        category: id,
        checked: checked
      }
    }

    setCheckedCategoryList(checkedCategoryCopy)
  }

  useEffect(() => {
    const tasks: Array<string> = [
      `/catering/food/${profile?.vendor_id}/${id}`,
      '/catering/category'
    ]

    Promise.all(tasks.map(task => fetch(API2 + task)))
      .then(async responses => await Promise.all(responses.map(response => response.json())))
      .then(responses => {
        const [foodResponse, categoryResponse] = responses

        if (!foodResponse.error) {
          setName(foodResponse.name)
          setDescription(foodResponse.description)
          setPrice(foodResponse.price)
          setImg(foodResponse.img)

          setCheckedCategoryList((foodResponse.category as Array<{food: number, category: number}>).map(category => ({
            category: category.category,
            checked: true
          })))
        }

        if (!categoryResponse.error) {
          setCategoryList(categoryResponse)
        }
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }, [id, profile])

  return (
    <div className="mt-3">
      <div className="columns is-centered">
        <div className="column is-10">
          <div className="box">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h4 className="title is-size-4">Form Makanan</h4>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <button className="button is-small is-primary" onClick={submit}>
                    <div className="icon is-small is-left">
                      <FontAwesomeIcon icon={faCheckCircle}/>
                    </div>
                    <span>Simpan</span>
                  </button>
                  <button className="button is-small ml-1" onClick={() => history.goBack()}>
                    <div className="icon is-small is-left">
                      <FontAwesomeIcon icon={faArrowCircleLeft}/>
                    </div>
                    <span>Kembali</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label htmlFor="">Nama</label>
                  <div className="control">
                    <input type="text" className="input" value={name} onChange={ev => setName(ev.target.value)}/>
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="">Gambar</label>
                  <div className="control">
                    <input type="file" className="input" onChange={uploadImage} accept="image/jpeg"/>
                  </div>
                  {img && (
                    <img src={img} className="image" alt="" style={{maxWidth: '100%'}}/>
                  )}
                </div>
                <div className="field">
                  <label htmlFor="">Deskripsi</label>
                  <div className="control">
                    <textarea rows={5} className="textarea" onChange={ev => setDescription(ev.target.value)} value={description}/>
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="">Harga</label>
                  <div className="control">
                    <input type="number" className="input" value={price} onChange={ev => setPrice(Number(ev.target.value))}/>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="box has-background-primary-light">
                  <h4 className="subtitle is-size-4">Pilih Kategori</h4>
                  <table className="table is-fullwidth">
                    <tbody>
                      {categoryList.map(category => (
                        <tr key={category.id}>
                          <td style={{width: 30}}>
                            <input
                              type="checkbox"
                              checked={checkedCategoryList.find(checked => checked.category === category.id)?.checked ?? false}
                              onChange={ev => checkHandler(category.id, ev.target.checked)}
                            />
                          </td>
                          <td>
                            {category.title}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoreConnector(FoodForm)