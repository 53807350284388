import { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../redux/Actions"

const API2 = process.env.REACT_APP_API2

function DoMonitor(props: StoreProps) {
  const { setUrlPrefix, setAuth, setPrivilege} = props
  const { user_id, prefix } = useParams<{user_id: string, prefix: string}>()
  const history = useHistory()

  useEffect(() => {
    const search = new URLSearchParams(history.location.search)
    const token = search.get('token')

    if (!token) {
      toast('Sesi anda telah habis!', {type: 'error'})
      return
    }

    const form = new URLSearchParams()

    form.append('id', String(user_id))
    form.append('type', prefix)

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: form
    }

    fetch(`${API2}/monitor`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          throw Error('Script error!')
        }

        
        if (prefix === 'vendor') {
          setAuth(response.result)
          setPrivilege('vendor')
        } else {
          setPrivilege(response.result.clientId)
          setAuth(response.result.accessToken)
        }

        setUrlPrefix('/' + prefix)
        history.push('/' + prefix)
      })
      .catch(() => toast('Gagal membuat token!', {type: 'error'}))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefix, user_id])

  return (
    <div>Sedang mengalihkan...</div>
  )
}

export default StoreConnector(DoMonitor)