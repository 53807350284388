import { useEffect } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import Dashboard, { MenuListInterface } from "../layouts/Dashboard"
import { StoreConnector, StoreProps } from "../redux/Actions"
import FoodCategory from "./administrator/FoodCategory"
import NutritionistList from "./administrator/NutritionistList"
import NutritionistSpeciality from "./administrator/NutritionistSpeciality"
import TrainerList from "./administrator/TrainerList"
import TrainerSpeciality from "./administrator/TrainerSpeciality"
import Vendorlist from "./administrator/Vendorlist"
import Voucher from "./administrator/Voucher"
import FixSteps from "./FixSteps"
import NutritionistOrder from "./nutritionist/NutritionistOrder"
import TrainerOrder from "./trainer/TrainerOrder"
import CateringOrder from "./vendor/CateringOrder"
import Oauth2Management from "./Oauth2Management"
import ImportParticipant from "./ImportParticipant"
import VoucherSodexo from "./administrator/VoucherSodexo"
import BulkVoucherSodexo from "./administrator/BulkVoucherSodexo"

function AdminDashboard(props: StoreProps) {
  const { privilege } = props

  const menuList: Array<MenuListInterface> = [
    {
      label: 'Trainer',
      links: [
        {
          title: 'Trainer List',
          path: '/admin/trainer'
        },
        {
          title: 'Speciality List',
          path: '/admin/trainer/speciality'
        },
        {
          title: 'Pesanan Online',
          path: '/admin/trainer/order/online'
        },
        {
          title: 'Pesanan Offline',
          path: '/admin/trainer/order/offline'
        }
      ]
    },
    {
      label: 'Nutritionist',
      links: [
        {
          title: 'Nutritionist List',
          path: '/admin/nutritionist'
        },
        {
          title: 'Speciality List',
          path: '/admin/nutritionist/speciality'
        },
        {
          title: 'Pesanan Online',
          path: '/admin/nutritionist/order/online'
        },
        {
          title: 'Pesanan Offline',
          path: '/admin/nutritionist/order/offline'
        }
      ]
    },
    {
      label: 'Catering',
      links: [
        {
          title: 'Food Category',
          path: '/admin/food-category'
        },
        {
          title: 'Vendor List',
          path: '/admin/vendor'
        },
        {
          title: 'Pesanan',
          path: '/admin/catering/order'
        }
      ]
    },
    {
      label: 'Voucher',
      links: [
        {
          title: 'Voucher',
          path: '/admin/voucher'
        },
        {
          title: 'Bulk Voucher Sodexo',
          path: '/admin/bulk-voucher-sodexo'
        },
        {
          title: 'Voucher Sodexo',
          path: '/admin/voucher-sodexo'
        }
      ]
    },
    {
      label: 'Lainnya',
      links: [
        {
          title: 'OAuth 2',
          path: '/admin/oauth2'
        },
        {
          title: 'Import Peserta',
          path: '/admin/import-participant'
        },
        {
          title: 'Fix Step Counter',
          path: '/admin/fix-steps'
        }
      ]
    }
  ]

  useEffect(() => {
    document.title = 'Admin Dashboard'
  }, [])

  if (privilege !== 'admin') {
    return (
      <Redirect to="/auth" />
    )
  }

  return (
    <Dashboard menuList={menuList}>
      <Switch>
        <Route path="/admin/voucher">
          <Voucher/>
        </Route>
        <Route path="/admin/voucher-sodexo">
          <VoucherSodexo/>
        </Route>
        <Route path="/admin/bulk-voucher-sodexo">
          <BulkVoucherSodexo />
        </Route>
        <Route path="/admin/food-category">
          <FoodCategory/>
        </Route>
        <Route path="/admin/catering/order">
          <CateringOrder />
        </Route>
        <Route path="/admin/vendor">
          <Vendorlist/>
        </Route>
        <Route path="/admin/trainer/order/:type">
          <TrainerOrder />
        </Route>
        <Route path="/admin/trainer/speciality">
          <TrainerSpeciality/>
        </Route>
        <Route path="/admin/trainer">
          <TrainerList/>
        </Route>
        <Route path="/admin/nutritionist/order/:type">
          <NutritionistOrder />
        </Route>
        <Route path="/admin/nutritionist/speciality">
          <NutritionistSpeciality/>
        </Route>
        <Route path="/admin/nutritionist">
          <NutritionistList/>
        </Route>
        <Route path="/admin/fix-steps">
          <FixSteps />
        </Route>
        <Route path="/admin/oauth2">
          <Oauth2Management />
        </Route>
        <Route path="/admin/import-participant">
          <ImportParticipant />
        </Route>
      </Switch>
    </Dashboard>
  )
}

export default StoreConnector(AdminDashboard)
