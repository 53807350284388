import { faPencilAlt, faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { API1Response } from "../../interfaces/APIResponse"
import { StoreConnector, StoreProps } from "../../redux/Actions"

const API2 = process.env.REACT_APP_API2

export interface FoodItemListInterface {
  id: number
  name: string
  description: string
  img?: string
  price: number
  deleted: 'Y'|'N'
}

function FoodList(props: StoreProps) {
  const { profile, token } = props

  const [foodList, setFoodList] = useState<Array<FoodItemListInterface>>([])  
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(Date.now())

  const deleteFood = (id: number) => {
    if (!token || !window.confirm('Anda yakin akan menghapus makanan ini?')) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    fetch(`${API2}/catering/food/${id}`, fetchInitOpt)
      .then(response => response.json())
      .then((response: API1Response) => {
        if (response.message !== 'OK') {
          toast('Gagal menghapus data!', {type: 'error'})
          return
        }

        toast('Berhasil menghapus data!', {type: 'success'})
        setUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Kegagaln jaringan!', {type: 'error'}))
  }

  useEffect(() => {
    if (!profile?.vendor_id) {
      return
    }

    fetch(`${API2}/catering/food/${profile.vendor_id}`)
      .then(response => response.json())
      .then((response: Array<FoodItemListInterface>) => {
        setFoodList(response)
      })
      .catch(() => toast('Gagal mengambil daftar makanan!', {type: 'error'}))
  }, [profile?.vendor_id, updateTimestamp])

  return (
    <div className="mt-3">
      <div className="box">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h4 className="title is-size-4">Daftar Makanan</h4>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <Link to="/vendor/food/form" className="button is-small is-primary">
                <div className="icon is-small is-left">
                  <FontAwesomeIcon icon={faPlusCircle}/>
                </div>
                <span>Tambah Makanan</span>
              </Link>
            </div>
          </div>
        </div>
        <div style={{overflowX: 'auto'}}>
          <table className="table is-bordered is-fullwidth" style={{whiteSpace: 'nowrap'}}>
            <thead>
              <tr>
                <th>Nama</th>
                <th>Deskripsi</th>
                <th>Harga</th>
                <th style={{width: 150}}>Aksi</th>
              </tr>
            </thead>
            <tbody>
              {foodList.map(food => (
                <tr key={food.id}>
                  <td>{food.name}</td>
                  <td>{food.description}</td>
                  <td>Rp {Intl.NumberFormat('id-ID').format(food.price)}</td>
                  <td>
                    <Link to={`/vendor/food/form/${food.id}`} className="button is-small is-primary">
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faPencilAlt}/>
                      </div>
                      <span>Edit</span>
                    </Link>
                    <button className="button is-small is-danger ml-1" onClick={() => deleteFood(food.id)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTrashAlt}/>
                      </div>
                      <span>Hapus</span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default StoreConnector(FoodList)