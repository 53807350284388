import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StoreConnector, StoreProps } from "../../redux/Actions";
import { faDownload, faTimesCircle, faUpload } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { Workbook } from 'exceljs'
import { toast } from "react-toastify";
import { DateTime } from "luxon";

const API2 = process.env.REACT_APP_API2

interface ImportVoucherInterface {
  email: string
  voucher: string
  nominal: number
  expired: string
}

interface ICompleteCourses {
  id: number
  user_id: number
  email: string
  course_id: number
  course_title: string
  voucher_id?: number
  voucher_code?: string
  voucher_file?: string
  nominal?: number
  expire_date?: string
}

function BulkVoucherSodexo(props: StoreProps) {
  const { token } = props
  const [voucherList, setVoucherList] = useState<ICompleteCourses[]>([])
  const [importVoucher, setImportVoucher] = useState<Array<ImportVoucherInterface>>([])
  const [previewVoucherDialogVisible, setPreviewVoucherDialogVisible] = useState<boolean>(false)
  const [voucherUpdateTimestamp, setVoucherUpdateTimestamp] = useState<number>(0)
  const uploadVoucherFieldRef = useRef<HTMLInputElement>(null)

  const uploadVoucher = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (!file) {
      return
    }

    const reader = new FileReader()

    reader.onload = async (e) => {
      const buffer = e.target?.result as ArrayBuffer
      const workbook = new Workbook()
      await workbook.xlsx.load(buffer)

      const worksheet = workbook.getWorksheet(1)
      const data: Array<ImportVoucherInterface> = []

      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1) {
          return
        }

        const email = row.getCell(1).text ?? ''
        const voucher = row.getCell(2).text ?? ''
        const nominal = parseInt(row.getCell(3).text ?? '0')
        const expired = new Date(row.getCell(4).text ?? Date.now()).toISOString().substring(0, 10)

        if (email && voucher && nominal && expired) {
          data.push({
            email,
            voucher,
            nominal,
            expired
          })
        }
      })

      setImportVoucher(data)
      setPreviewVoucherDialogVisible(true)
    }

    reader.readAsArrayBuffer(file)
  }, [])

  const downloadTemplate = useCallback(() => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Sheet 1')

    worksheet.columns = [
      { header: 'Email', key: 'email' },
      { header: 'Voucher', key: 'voucher' },
      { header: 'Nominal', key: 'nominal' },
      { header: 'Expired', key: 'expired' }
    ]

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')

      a.href = url
      a.download = 'bulk-voucher-sodexo.xlsx'
      a.click()
    })
  }, [])

  const doImportExcel = useCallback(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        vouchers: importVoucher
      })
    }

    fetch(API2 + '/sodexo/bulk', fetchInitOpt)
      .then((response) => {
        if (response.ok) {
          alert('Voucher berhasil diimport')
          setPreviewVoucherDialogVisible(false)
        } else {
          alert('Gagal import voucher')
        }
      })
      .catch((error) => {
        alert('Gagal import voucher')
      })
      .finally(() => {
        setImportVoucher([])
        setPreviewVoucherDialogVisible(false)
      })
  }, [importVoucher, token])

  const removeVoucher = useCallback((id: number) => {
    if (!token || !window.confirm('Apakah Anda yakin?')) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    fetch(`${API2}/sodexo/remove-voucher/${id}`, fetchInitOpt)
      .then(res => res.json())
      .then(() => {
        setVoucherUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Gagal menghapus voucher', {type: 'error'}))
  }, [token])

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    fetch(`${API2}/sodexo/user-list`, fetchInitOpt)
      .then(res => res.json())
      .then(setVoucherList)
      .catch(() => toast('Gagal mengambil data peserta', {type: 'error'}))
  }, [token, voucherUpdateTimestamp])
  
  return (
    <>
      <input
        ref={uploadVoucherFieldRef}
        type="file"
        accept=".xlsx"
        onChange={uploadVoucher}
        style={{ display: 'none' }}
      />
      <div className="mt-3">
        <div className="box">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">Bulk Voucher Sodexo</h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <button className="button is-primary is-small mr-1" onClick={downloadTemplate}>
                  <span className="icon">
                    <FontAwesomeIcon icon={faDownload} />
                  </span>
                  <span>Template</span>
                </button>
                <button className="button is-small is-primary" onClick={() => uploadVoucherFieldRef.current?.click()}>
                  <span className="icon is-small is-left">
                    <FontAwesomeIcon icon={faUpload} />
                  </span>
                  <span>Import</span>
                </button>
              </div>
            </div>
          </div>
          <div style={{overflowX: 'auto'}}>
            <table className="table is-fullwidth is-striped is-hoverable">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Email</th>
                  <th>Kode Voucher</th>
                  <th>Nominal</th>
                  <th>Tgl. Kadaluarsa</th>
                  <th style={{width: 180}}>Aksi</th>
                </tr>
              </thead>
              <tbody>
                {voucherList.map((voucher, idx) => (
                  <tr key={voucher.id}>
                    <td>{idx + 1}</td>
                    <td>{voucher.email}</td>
                    <td>{voucher.voucher_code ?? '-'}</td>
                    <td>{voucher.nominal ?? '-'}</td>
                    <td>{voucher.expire_date ? DateTime.fromISO(voucher.expire_date).toLocaleString() : '-'}</td>
                    <button
                      className="button is-small is-danger"
                      onClick={() => removeVoucher(voucher.id)}
                    >
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                      <span>Hapus</span>
                    </button>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {previewVoucherDialogVisible && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setPreviewVoucherDialogVisible(false)}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Preview Voucher</p>
              <button className="delete" aria-label="close" onClick={() => setPreviewVoucherDialogVisible(false)}></button>
            </header>
            <section className="modal-card-body">
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Voucher</th>
                    <th>Nominal</th>
                    <th>Expired</th>
                  </tr>
                </thead>
                <tbody>
                  {importVoucher.map((voucher, index) => (
                    <tr key={index}>
                      <td>{voucher.email}</td>
                      <td>{voucher.voucher}</td>
                      <td>{voucher.nominal}</td>
                      <td>{voucher.expired}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-success" onClick={doImportExcel}>Save</button>
              <button className="button" onClick={() => setPreviewVoucherDialogVisible(false)}>Cancel</button>
            </footer>
          </div>
        </div>
      )}
    </>
  )
}

export default StoreConnector(BulkVoucherSodexo)
