import { faCheckCircle, faPencilAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"

const API2 = process.env.REACT_APP_API2

interface SpecialityListItem {
  id: number
  deleted: 1|0
  max: number
  name: string
  background: string|null
  cover: string|null
}

interface AssignedSpecialityList {
  id: number
  price_online: number
  price_offline: number
  speciality_id: number
  deleted: 0|1
  equipment: string|null
  max_people: number|null
  online: boolean
  offline: boolean
}

function SpecialityList(props: StoreProps) {
  const { token } = props

  const [specialityList, setSpecialityList] = useState<Array<SpecialityListItem>>([])
  const [assignedSpecialityList, setAssignedSpecialityList] = useState<Array<AssignedSpecialityList>>([])
  const [focusedSpeciality, setFocusedSpeciality] = useState<number>()
  const [priceOnline, setPriceOnline] = useState<number>(0)
  const [priceOffline, setPriceOffline] = useState<number>(0)
  const [equipment, setEquipment] = useState<string>('')
  const [maxPeople, setMaxPeople] = useState<number>(1)
  const [online, setOnline] = useState<boolean>(true)
  const [offline, setOffline] = useState<boolean>(true)
  const [showEditAssignedSpecialityModal, setEditAsignedSpecialityModalVisibility] = useState<boolean>(false)
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(Date.now())

  const submitData = () => {
    if (!token) {
      return
    }

    const form = new URLSearchParams()
    
    assignedSpecialityList.map(data => {
      form.append(`speciality[${data.speciality_id}][price_online]`, String(data.price_online))
      form.append(`speciality[${data.speciality_id}][price_offline]`, String(data.price_offline))
      form.append(`speciality[${data.speciality_id}][equipment]`, data.equipment ?? '')
      form.append(`speciality[${data.speciality_id}][max_people]`, String(data.max_people ?? 1))
      form.append(`speciality[${data.speciality_id}][deleted]`, String(data.deleted))
      form.append(`speciality[${data.speciality_id}][online]`, String(data.online ? 1 : 0))
      form.append(`speciality[${data.speciality_id}][offline]`, String(data.offline ? 1 : 0))

      return undefined
    })

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: form
    }

    fetch(`${API2}/trainer/speciality/assigned`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.message === 'ERROR') {
          throw new Error('Gagal menyimpan data!')
        }

        setUpdateTimestamp(Date.now())
        toast('Data berhasil disimpan!', {type: 'success'})
      })
      .catch(() => toast('Gagal menyimpan data!', {type: 'error'}))
  }

  const updateAssignedSpeciality = (specialityId: number) => {
    setEditAsignedSpecialityModalVisibility(true)
    setFocusedSpeciality(specialityId)

    const foundAssignedData = assignedSpecialityList.find(data => data.speciality_id === specialityId && data.deleted === 0)

    setPriceOnline(foundAssignedData?.price_online ?? 0)
    setPriceOffline(foundAssignedData?.price_offline ?? 0)
    setEquipment(foundAssignedData?.equipment ?? '')
    setMaxPeople(foundAssignedData?.max_people ?? 1)
    setOnline(foundAssignedData?.online ?? true)
    setOffline(foundAssignedData?.offline ?? true)
  }

  const submitAssignedSpeciality = () => {
    if (focusedSpeciality === undefined) {
      return
    }

    const foundAssignedDataIndex = assignedSpecialityList.findIndex(data => data.speciality_id === focusedSpeciality)
    const assignedSpecialityListCopy = [...assignedSpecialityList]

    if (foundAssignedDataIndex > -1) {
      assignedSpecialityListCopy[foundAssignedDataIndex] = {
        ...assignedSpecialityList[foundAssignedDataIndex],
        deleted: 0,
        equipment: equipment,
        max_people: maxPeople,
        price_online: priceOnline,
        price_offline: priceOffline,
        online: online,
        offline: offline
      }
    } else {
      assignedSpecialityListCopy.push({
        id: 0 - assignedSpecialityList.length,
        price_online: priceOnline,
        price_offline: priceOffline,
        equipment: equipment,
        max_people: maxPeople,
        deleted: 0,
        speciality_id: focusedSpeciality,
        online: online,
        offline: offline
      })
    }

    setAssignedSpecialityList(assignedSpecialityListCopy)
    setEditAsignedSpecialityModalVisibility(false)
    setFocusedSpeciality(undefined)
  }

  const deleteAssignedSpeciality = () => {
    if (focusedSpeciality === undefined) {
      return
    }

    const foundAssignedDataIndex = assignedSpecialityList.findIndex(data => data.speciality_id === focusedSpeciality)
    const assignedSpecialityListCopy = [...assignedSpecialityList]

    if (foundAssignedDataIndex > -1) {
      assignedSpecialityListCopy[foundAssignedDataIndex].deleted = 1
    }

    setAssignedSpecialityList(assignedSpecialityListCopy)
    setEditAsignedSpecialityModalVisibility(false)
    setFocusedSpeciality(undefined)
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        Authorization: token
      }
    }

    const getSpeciality = fetch(`${API2}/trainer/speciality`, fetchInitOpt)
    const getAssignedSpeciality = fetch(`${API2}/trainer/speciality/assigned`, fetchInitOpt)

    Promise.all([getSpeciality, getAssignedSpeciality])
      .then(async responses => await Promise.all(responses.map(response => response.json())))
      .then(responses => {
        const [specialityListResponse, assignedSpecialityListResponse] = responses

        setSpecialityList(specialityListResponse)
        setAssignedSpecialityList(assignedSpecialityListResponse)
      })
      .catch(() => toast('Gagal mengambil data!', {type: 'error'}))
  }, [token, updateTimestamp])

  return (
    <Fragment>
      <div className="mt-3">
        <div className="box">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h4 className="title is-size-4">Speciality List</h4>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <button className="button is-small is-primary" onClick={submitData}>
                  <div className="icon is-small is-left">
                    <FontAwesomeIcon icon={faCheckCircle}/>
                  </div>
                  <span>Simpan</span>
                </button>
              </div>
            </div>
          </div>
          <div style={{overflowX: 'auto'}}>
            <table className="table is-bordered is-fullwidth" style={{whiteSpace: 'nowrap'}}>
              <thead>
                <tr>
                  <th>Nama</th>
                  <th>Tersedia</th>
                  <th>Online</th>
                  <th>Offline</th>
                  <th>Equipment</th>
                  <th>Peserta Max.</th>
                  <th>Harga Online</th>
                  <th>Harga Offline</th>
                  <th style={{width: 90}}>Aksi</th>
                </tr>
              </thead>
              <tbody>
                {specialityList.map(speciality => {
                  const foundAssignedSpeciality = assignedSpecialityList.find(data => data.speciality_id === speciality.id && data.deleted === 0)

                  return (
                    <tr key={speciality.id}>
                      <td>{speciality.name}</td>
                      <td>{(foundAssignedSpeciality?.deleted ?? 1) === 1 ? 'Tidak' : 'Ya'}</td>
                      <td>{foundAssignedSpeciality?.online ? 'Ya' : 'Tidak'}</td>
                      <td>{foundAssignedSpeciality?.offline ? 'Ya' : 'Tidak'}</td>
                      <td>{foundAssignedSpeciality?.equipment ?? '-'}</td>
                      <td>{foundAssignedSpeciality?.max_people ?? 0}</td>
                      <td>Rp {Intl.NumberFormat('id-ID').format(foundAssignedSpeciality?.price_online ?? 0)}</td>
                      <td>Rp {Intl.NumberFormat('id-ID').format(foundAssignedSpeciality?.price_offline ?? 0)}</td>
                      <td>
                        <button className="button is-small is-primary" onClick={() => updateAssignedSpeciality(speciality.id)}>
                          <div className="icon is-small is-left">
                            <FontAwesomeIcon icon={faPencilAlt}/>
                          </div>
                          <span>Ubah</span>
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showEditAssignedSpecialityModal && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setEditAsignedSpecialityModalVisibility(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">Ubah Speciality Data</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-small is-primary" onClick={submitAssignedSpeciality}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faCheckCircle}/>
                      </div>
                      <span>Simpan</span>
                    </button>
                    <button className="button is-small is-danger ml-1" onClick={deleteAssignedSpeciality}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTimesCircle}/>
                      </div>
                      <span>Nonaktifkan</span>
                    </button>
                    <button className="button is-small is-default ml-1" onClick={() => setEditAsignedSpecialityModalVisibility(false)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTimesCircle}/>
                      </div>
                      <span>Batal</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Speciality</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={specialityList.find(data => data.id === focusedSpeciality)?.name}
                    disabled
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column is-narrow">
                  <label htmlFor="">Harga Online</label>
                  <div className="field has-addons">
                    <div className="control">
                      <button className="button is-static">
                        Rp
                      </button>
                    </div>
                    <div className="control">
                      <input
                        type="number"
                        className="input"
                        value={String(priceOnline)}
                        onChange={ev => setPriceOnline(Number(ev.target.value))}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-narrow">
                  <label htmlFor="">Harga Offline</label>
                  <div className="field has-addons">
                    <div className="control">
                      <button className="button is-static">
                        Rp
                      </button>
                    </div>
                    <div className="control">
                      <input
                        type="number"
                        className="input"
                        value={String(priceOffline)}
                        onChange={ev => setPriceOffline(Number(ev.target.value))}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Equipment</label>
                <div className="control">
                  <textarea
                    className="textarea"
                    value={equipment}
                    onChange={ev => setEquipment(ev.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Peserta Maksimal</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    value={maxPeople}
                    onChange={ev => setMaxPeople(Number(ev.target.value))}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column is-3">
                  <div className="field">
                    <label className="checkbox">
                      <input type="checkbox" checked={online} onChange={ev => setOnline(ev.target.checked)} /> Online
                    </label>
                  </div>
                </div>
                <div className="column is-3">
                  <div className="field">
                    <label className="checkout">
                      <input type="checkbox" checked={offline} onChange={ev => setOffline(ev.target.checked)} /> Offline
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default StoreConnector(SpecialityList)