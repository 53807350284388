import { faCheckCircle, faPencilAlt, faPlusCircle, faTimesCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DateTime } from "luxon"
import { Fragment, useEffect, useState } from "react"
import ReactQuill from "react-quill"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"
import { NutritionistListItemInterface } from "./NutritionistList"
import { TrainerListItemInterface } from "./TrainerList"
import { VendorListItem } from "./Vendorlist"

const API2 = process.env.REACT_APP_API2

interface VoucherListItem {
  id: number
  code: string
  description: string
  start_date: string
  end_date: string
  quantity: number
  type: string
  value: number
  usage_type: 'public'|'private'
  url_banner?: string
  offline_trainer: number
  online_trainer: number
  offline_nutritionist: number
  online_nutritionist: number
  food: number
  food_package: number
  new_member: number
  is_per_user_quota: number
  per_user_quota: number
  assigned_trainer?: string
  assigned_nutritionist?: string
  assigned_catering_vendor?: string
}

function Voucher(props: StoreProps) {
  const { token } = props

  const [voucherList, setVoucherList] = useState<Array<VoucherListItem>>([])
  const [focusedVoucher, setFocusedVoucher] = useState<number>()
  const [code, setCode] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [startDate, setStartDate] = useState<string>(DateTime.now().toFormat('yyyy-MM-dd'))
  const [endDate, setEndDate] = useState<string>(DateTime.now().toFormat('yyyy-MM-dd'))
  const [quantity, setQuantity] = useState<number>(1)
  const [type, setType] = useState<string>('percent')
  const [value, setValue] = useState<number>(10)
  const [banner, setBanner] = useState<string>()
  const [uploadedBanner, setUploadedBanner] = useState<File>()
  const [usageType, setUsageType] = useState<'public'|'private'>('public')
  const [onlineTrainer, setOnlineTrainer] = useState<number>(1)
  const [offlineTrainer, setOfflineTrainer] = useState<number>(1)
  const [onlineNutritionist, setOnlineNutritionist] = useState<number>(1)
  const [offlineNutritionist, setOfflineNutritionist] = useState<number>(1)
  const [food, setFood] = useState<number>(1)
  const [foodPackage, setFoodPackage] = useState<number>(1)
  const [newMember, setNewMember] = useState<number>(0)
  const [selectiveTrainer, setSelectiveTrainer] = useState<boolean>(false)
  const [selectiveNutritionist, setSelectiveNutritionist] = useState<boolean>(false)
  const [selectiveCateringVendor, setSelectiveCateringVendor] = useState<boolean>(false)
  const [findTrainerQuery, setFindTrainerQuery] = useState<string>('')
  const [findNutritionistQuery, setFindNutritionistQuery] = useState<string>('')
  const [findCateringVendorQuery, setFindCateringVendorQuery] = useState<string>('')
  const [assignedTrainer, setAssignedTrainer] = useState<Array<number>>([])
  const [assignedNutritionist, setAssignedNutritionist] = useState<Array<number>>([])
  const [assignedCateringVendor, setAssignedCateringVendor] = useState<Array<number>>([])
  const [trainerList, setTrainerList] = useState<Array<TrainerListItemInterface>>([])
  const [nutritionistList, setNutritionistList] = useState<Array<NutritionistListItemInterface>>([])
  const [cateringVendorList, setCateringVendorList] = useState<Array<VendorListItem>>([])
  const [isPerUserQuota, setIsPerUserQuota] = useState<number>(0)
  const [perUserQuota, setPerUserQuota] = useState<number>(0)
  const [voucherFormVisible, setVoucherFormVisibility] = useState<boolean>(false)
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(Date.now())
  const [findQuery, setFindQuery] = useState<string>('')

  const unassignTrainer = (id: number) => {
    const assignedTrainerCopy = [...assignedTrainer]
    const foundIndex = assignedTrainerCopy.findIndex(trainerId => trainerId === id)

    if (foundIndex < 0) {
      return
    }

    assignedTrainerCopy.splice(foundIndex, 1)
    setAssignedTrainer(assignedTrainerCopy)
  }

  const unassignNutritionist = (id: number) => {
    const assignedNutritionistCopy = [...assignedNutritionist]
    const foundIndex = assignedNutritionistCopy.findIndex(nutritionistId => nutritionistId === id)

    if (foundIndex < 0) {
      return
    }

    assignedNutritionistCopy.splice(foundIndex, 1)
    setAssignedNutritionist(assignedNutritionistCopy)
  }

  const unassignCateringVendor = (id: number) => {
    const assignedCateringVendorCopy = [...assignedCateringVendor]
    const foundIndex = assignedCateringVendorCopy.findIndex(cateringVendorId => cateringVendorId === id)

    if (foundIndex < 0) {
      return
    }

    assignedCateringVendorCopy.splice(foundIndex, 1)
    setAssignedCateringVendor(assignedCateringVendorCopy)
  }

  const voucherForm = (id?: number) => {
    setVoucherFormVisibility(true)
    setFocusedVoucher(id)

    const foundVoucher = voucherList.find(voucher => voucher.id === id)
    
    let parsedAssignedTrainer: Array<number>
    let parsedAssignedNutritionist: Array<number>
    let parsedCateringVendor: Array<number>

    try {
      parsedAssignedTrainer = JSON.parse(foundVoucher?.assigned_trainer ?? '[]') as Array<number>
      parsedAssignedNutritionist = JSON.parse(foundVoucher?.assigned_nutritionist ?? '[]') as Array<number>
      parsedCateringVendor = JSON.parse(foundVoucher?.assigned_catering_vendor ?? '[]') as Array<number>
    } catch (error) {
      parsedAssignedTrainer = []
      parsedAssignedNutritionist = []
      parsedCateringVendor = []
    }

    setCode(foundVoucher?.code ?? '')
    setDescription(foundVoucher?.description ?? '')
    setStartDate(foundVoucher?.start_date ?? DateTime.now().toFormat('yyyy-MM-dd'))
    setEndDate(foundVoucher?.end_date ?? DateTime.now().plus({days: 7}).toFormat('yyyy-MM-dd'))
    setQuantity(foundVoucher?.quantity ?? 1)
    setType(foundVoucher?.type ?? 'percent')
    setValue(foundVoucher?.value ?? 10)
    setBanner(foundVoucher?.url_banner)
    setUsageType(foundVoucher?.usage_type ?? 'public')
    setOnlineTrainer(foundVoucher?.online_trainer ?? 1)
    setOfflineTrainer(foundVoucher?.offline_trainer ?? 1)
    setOnlineNutritionist(foundVoucher?.online_nutritionist ?? 1)
    setOfflineNutritionist(foundVoucher?.offline_nutritionist ?? 1)
    setAssignedTrainer(parsedAssignedTrainer)
    setAssignedNutritionist(parsedAssignedNutritionist)
    setAssignedCateringVendor(parsedCateringVendor)
    setFood(foundVoucher?.food ?? 1)
    setFoodPackage(foundVoucher?.food_package ?? 1)
    setNewMember(foundVoucher?.new_member ?? 0)
    setSelectiveTrainer(parsedAssignedTrainer.length > 0)
    setSelectiveNutritionist(parsedAssignedNutritionist.length > 0)
    setSelectiveCateringVendor(parsedCateringVendor.length > 0)
    setIsPerUserQuota(foundVoucher?.is_per_user_quota ?? 0)
    setPerUserQuota(foundVoucher?.per_user_quota ?? 0)

    setUploadedBanner(undefined)
  }

  const uploadImageHandler = (files: FileList) => {
    if (files.length < 1) {
      return
    }

    if (files[0].size > 1000000) {
      toast('Ukuran file maksimal adalah 1MB', {type: 'error'})
      return
    }

    const reader = new FileReader()

    reader.onload = (ev: ProgressEvent<FileReader>) => {
      const result = ev.target?.result

      if (!result) {
        return
      }

      setUploadedBanner(files[0])
      setBanner(result.toString())
    }

    reader.readAsDataURL(files[0])
  }

  const submitVoucher = () => {
    if (!token) {
      return
    }

    const form = uploadedBanner ? new FormData() : new URLSearchParams()

    if (focusedVoucher) {
      form.append('id', String(focusedVoucher))
    }

    form.append('code', code)
    form.append('description', description)
    form.append('start_date', startDate)
    form.append('end_date', endDate)
    form.append('quantity', String(quantity))
    form.append('type', type)
    form.append('value', String(value))
    form.append('usage_type', usageType)
    form.append('online_trainer', onlineTrainer.toString())
    form.append('offline_trainer', offlineTrainer.toString())
    form.append('online_nutritionist', onlineNutritionist.toString())
    form.append('offline_nutritionist', offlineNutritionist.toString())
    form.append('food', food.toString())
    form.append('food_package', foodPackage.toString())
    form.append('new_member', newMember.toString())
    form.append('selective_trainer', selectiveTrainer ? 'yes' : 'no')
    form.append('selective_nutritionist', selectiveNutritionist ? 'yes' : 'no')
    form.append('selective_catering_vendor', selectiveCateringVendor ? 'yes' : 'no')
    form.append('assigned_trainer', JSON.stringify(assignedTrainer))
    form.append('assigned_nutritionist', JSON.stringify(assignedNutritionist))
    form.append('assigned_catering_vendor', JSON.stringify(assignedCateringVendor))
    form.append('is_per_user_quota', String(isPerUserQuota))
    form.append('per_user_quota', String(perUserQuota))

    if (uploadedBanner) {
      // @ts-ignore
      form.append('banner', uploadedBanner)
    }

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: form
    }

    fetch(`${API2}/administration/voucher`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal menyimpan data!', {type: 'error'})
          return
        }

        toast('Berhasil menyimpan data!', {type: 'success'})
        setUpdateTimestamp(Date.now())
        setVoucherFormVisibility(false)
        setFindTrainerQuery('')
        setFindNutritionistQuery('')
        setFindCateringVendorQuery('')
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }

  const removeVoucher = (id: number) => {
    if (!token || !window.confirm('Anda yakin akan  menghapus voucher ini?')) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'DELETE',
      headers: {
        Authorization: token
      }
    }

    fetch(`${API2}/administration/voucher/${id}`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal menghapus data!', {type: 'error'})
          return
        }

        toast('Berhasil menghapus data!', {type: 'success'})
        setUpdateTimestamp(Date.now())
      })
      .catch(() => setUpdateTimestamp(Date.now()))
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        Authorization: token
      }
    }

    const tasks = [
      'voucher',
      'trainer',
      'nutritionist',
      'vendor'
    ]

    Promise.all(tasks.map(task => fetch(`${API2}/administration/${task}`, fetchInitOpt)))
      .then(async responses => await Promise.all(responses.map(response => response.json())))
      .then(responses => {
        const [voucherResponse, trainerResponse, nutritionistResponse, vendorResponse] = responses

        setVoucherList(voucherResponse)
        setTrainerList(trainerResponse)
        setNutritionistList(nutritionistResponse)
        setCateringVendorList(vendorResponse)
      })
      .catch(() => toast('Terjadi kegagalan jarignan!', {type: 'error'}))
  }, [token, updateTimestamp])

  useEffect(() => {
    if (type === 'percent' && value > 100) {
      setValue(100)
    }
  }, [type, value])

  return (
    <Fragment>
      <div className="mt-3">
        <div className="box">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h4 className="title is-size-4">Daftar Voucher</h4>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <input type="text" className="input is-small mr-1" placeholder="Cari" value={findQuery} onChange={ev => setFindQuery(ev.target.value)} />
                <button className="button is-small is-primary" onClick={() => voucherForm()}>
                  <div className="icon is-small is-left">
                    <FontAwesomeIcon icon={faPlusCircle}/>
                  </div>
                  <span>Tambah</span>
                </button>
              </div>
            </div>
          </div>
          <div style={{overflowX: 'auto'}}>
            <table className="table is-bordered is-fullwidth" style={{whiteSpace: 'nowrap'}}>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Mulai</th>
                  <th>Selesai</th>
                  <th>Kuantitas</th>
                  <th>Tipe</th>
                  <th>Nilai</th>
                  <th>Ketersediaan</th>
                  <th style={{width: 171}}>Aksi</th>
                </tr>
              </thead>
              <tbody>
                {voucherList.filter(voucher => voucher.code.match(new RegExp(findQuery, 'ig')) || voucher.description.match(new RegExp(findQuery, 'ig'))).map(voucher => (
                  <tr key={voucher.id}>
                    <td>{voucher.code}</td>
                    <td>{DateTime.fromISO(voucher.start_date).setLocale('id-ID').toLocaleString(DateTime.DATE_FULL)}</td>
                    <td>{DateTime.fromISO(voucher.end_date).setLocale('id-ID').toLocaleString(DateTime.DATE_FULL)}</td>
                    <td>{voucher.quantity}</td>
                    <td>{voucher.type}</td>
                    <td>{voucher.value}</td>
                    <td>{voucher.usage_type === 'public' ? 'Umum' : 'Privat'}</td>
                    <td>
                      <button className="button is-small is-primary" onClick={() => voucherForm(voucher.id)}>
                        <div className="icon is-small is-left">
                          <FontAwesomeIcon icon={faPencilAlt}/>
                        </div>
                        <span>Ubah</span>
                      </button>
                      <button className="button is-small is-danger ml-1" onClick={() => removeVoucher(voucher.id)}>
                        <div className="icon is-small is-left">
                          <FontAwesomeIcon icon={faTrashAlt}/>
                        </div>
                        <span>Hapus</span>
                      </button>
                    </td>
                  </tr>
                ))}
                {voucherList.length < 1 && (
                  <tr>
                    <td colSpan={8} className="has-text-centered">Belum ada voucher</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {voucherFormVisible && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setVoucherFormVisibility(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">Form Voucher</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-small is-primary" onClick={submitVoucher}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faCheckCircle}/>
                      </div>
                      <span>Simpan</span>
                    </button>
                    <button className="button is-small is-default ml-1" onClick={() => setVoucherFormVisibility(false)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTimesCircle}/>
                      </div>
                      <span>Batal</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Kode Voucher</label>
                <div className="control">
                  <input type="text" className="input" value={code} onChange={ev => setCode(ev.target.value)} />
                </div>
              </div>
              <div className="field" style={{marginBottom: 50}}>
                <label htmlFor="">Deskripsi</label>
                <div className="control">
                  <ReactQuill
                    value={description}
                    onChange={val => setDescription(val)}
                    style={{height: '90pt'}}
                  />
                </div>
              </div>
              <div className="columns is-multiline mt-5">
                <div className="column is-6">
                  <div className="field">
                    <label htmlFor="">Tanggal Mulai</label>
                    <div className="control">
                      <input type="date" className="input" value={startDate} onChange={ev => setStartDate(ev.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label htmlFor="">Tanggal Selesai</label>
                    <div className="control">
                      <input type="date" className="input" value={endDate} onChange={ev => setEndDate(ev.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="column is-4">
                  <div className="field">
                    <label htmlFor="">Tipe</label>
                    <div className="select is-fullwidth">
                      <select value={type} onChange={ev => setType(ev.target.value)}>
                        <option value="percent">Percent</option>
                        <option value="nominal">Nominal</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="column is-4">
                  <div className="field">
                    <label htmlFor="">Nilai</label>
                    <div className="control">
                      <input
                        type="number"
                        className="input"
                        min={0}
                        max={type === 'percent' ? 100 : undefined}
                        value={value}
                        onChange={ev => setValue(Number(ev.target.value))}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-4">
                  <div className="field">
                    <label htmlFor="">Kuantitas</label>
                    <div className="control">
                      <input type="number" className="input" value={quantity} onChange={ev => setQuantity(Number(ev.target.value))} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Gambar Banner</label>
                <div className="control">
                  <input type="file" className="input" accept="image/*" onChange={ev => ev.target.files !== null && uploadImageHandler(ev.target.files)} />
                </div>
              </div>
              {banner && (
                <img src={banner} alt="" className="image" />
              )}
              <h4 className="tile is-size-4 mt-3 mb-1">Ketersediaan:</h4>
              <div className="field">
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={usageType === 'public'}
                    onChange={ev => setUsageType(ev.target.checked ? 'public' : 'private')}
                  />
                  Ketersediaan (centang untuk umum, atau sebaliknya untuk privat)
                </label>
              </div>
              <div className="field">
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={onlineTrainer === 1}
                    onChange={ev => setOnlineTrainer(ev.target.checked ? 1 : 0)}
                  />
                  Online trainer
                </label>
              </div>
              <div className="field">
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={offlineTrainer === 1}
                    onChange={ev => setOfflineTrainer(ev.target.checked ? 1 : 0)}
                  />
                  Offline trainer
                </label>
              </div>
              <div className="field">
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={onlineNutritionist === 1}
                    onChange={ev => setOnlineNutritionist(ev.target.checked ? 1 : 0)}
                  />
                  Online Nutritionist
                </label>
              </div>
              <div className="field">
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={offlineNutritionist === 1}
                    onChange={ev => setOfflineNutritionist(ev.target.checked ? 1 : 0)}
                  />
                  Offline Nutritionist
                </label>
              </div>
              <div className="field">
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={food === 1}
                    onChange={ev => setFood(ev.target.checked ? 1 : 0)}
                  />
                  Food
                </label>
              </div>
              <div className="field">
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={foodPackage === 1}
                    onChange={ev => setFoodPackage(ev.target.checked ? 1 : 0)}
                  />
                  Food Package
                </label>
              </div>
              <div className="field">
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={newMember === 1}
                    onChange={ev => setNewMember(ev.target.checked ? 1 : 0)}
                  />
                  Member Baru
                </label>
              </div>
              <div className="field">
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={isPerUserQuota === 1}
                    onChange={ev => setIsPerUserQuota(ev.target.checked ? 1 : 0)}
                  />
                  Kuota Per User
                </label>
              </div>
              {isPerUserQuota === 1 && (
                <div className="field">
                  <label htmlFor="">Kuota</label>
                  <div className="form-control">
                    <input type="number" className="input" value={perUserQuota} onChange={ev => setPerUserQuota(Number(ev.target.value))} />
                  </div>
                </div>
              )}
              <hr />
              <div className="field">
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={selectiveTrainer}
                    onChange={ev => setSelectiveTrainer(ev.target.checked)}
                  />
                  Trainer Khusus
                </label>
              </div>
              {selectiveTrainer && (
                <Fragment>
                  {assignedTrainer.map(trainerId => (
                    <span className="tag is-success mr-1" key={trainerId}>
                      {trainerList.find(trainer => trainer.id === trainerId)?.name ?? '-'}
                      <button className="delete is-small" onClick={() => unassignTrainer(trainerId)}></button>
                    </span>
                  ))}
                  <div className="box">
                    <div className="field">
                      <label htmlFor="">Cari Trainer</label>
                      <div className="form-control">
                        <input type="text" className="input" value={findTrainerQuery} onChange={ev => setFindTrainerQuery(ev.target.value)} />
                      </div>
                    </div>
                  </div>
                  {trainerList.filter(trainer => findTrainerQuery.length > 0 && assignedTrainer.findIndex(data => data === trainer.id) < 0 && trainer.name.match(new RegExp(findTrainerQuery, 'ig'))).map(trainer => (
                    <div className="box" key={trainer.id}>
                      <div className="columns">
                        <div className="column">
                          {trainer.name}
                        </div>
                        <div className="column is-narrow">
                          <button className="button is-small is-primary">
                            <div className="icon is-small" onClick={() => setAssignedTrainer([...assignedTrainer, Number(trainer.id)])}>
                              <FontAwesomeIcon icon={faPlusCircle} />
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </Fragment>
              )}
              <div className="field">
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={selectiveNutritionist}
                    onChange={ev => setSelectiveNutritionist(ev.target.checked)}
                  />
                  Nutrisionis Khusus
                </label>
              </div>
              {selectiveNutritionist && (
                <Fragment>
                  {assignedNutritionist.map(nutritionistId => (
                    <span className="tag is-success mr-1">
                      {nutritionistList.find(nutritionist => nutritionist.id === nutritionistId)?.full_name ?? '-'}
                      <button className="delete is-small" type="button" onClick={() => unassignNutritionist(nutritionistId)}></button>
                    </span>
                  ))}
                  <div className="box">
                    <div className="field">
                      <label htmlFor="">Cari Nutrisionis</label>
                      <div className="form-control">
                        <input type="text" className="input" value={findNutritionistQuery} onChange={ev => setFindNutritionistQuery(ev.target.value)} />
                      </div>
                    </div>
                  </div>
                  {nutritionistList.filter(nutritionist => findNutritionistQuery.length > 0 && assignedNutritionist.findIndex(data => data === nutritionist.id) < 0 && nutritionist.full_name.match(new RegExp(findNutritionistQuery, 'ig'))).map(nutritionist => (
                    <div className="box" key={nutritionist.id}>
                      <div className="columns">
                        <div className="column">
                          {nutritionist.full_name}
                        </div>
                        <div className="column is-narrow">
                          <button className="button is-small is-primary" type="button" onClick={() => setAssignedNutritionist([...assignedNutritionist, Number(nutritionist.id)])}>
                            <div className="icon is-small">
                              <FontAwesomeIcon icon={faPlusCircle} />
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </Fragment>
              )}
              <div className="field">
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={selectiveCateringVendor}
                    onChange={ev => setSelectiveCateringVendor(ev.target.checked)}
                  />
                  Vendor Catering Khusus
                </label>
              </div>
              {selectiveCateringVendor && (
                <Fragment>
                  {assignedCateringVendor.map(cateringVendorId => (
                    <span className="tag is-success mr-1" key={cateringVendorId}>
                      {cateringVendorList.find(cateringVendor => cateringVendor.id === cateringVendorId)?.name ?? '-'}
                      <button className="delete is-small" type="button" onClick={() => unassignCateringVendor(cateringVendorId)}></button>
                    </span>
                  ))}
                  <div className="box">
                    <div className="field">
                      <label htmlFor="">Cari Vendor Catering</label>
                      <div className="form-control">
                        <input type="text" className="input" value={findCateringVendorQuery} onChange={ev => setFindCateringVendorQuery(ev.target.value)} />
                      </div>
                    </div>
                  </div>
                  {cateringVendorList.filter(cateringVendor => findCateringVendorQuery.length > 0 && assignedCateringVendor.findIndex(cateringVendorId => cateringVendorId === cateringVendor.id) < 0 && cateringVendor.name.match(new RegExp(findCateringVendorQuery, 'ig'))).map(cateringVendor => (
                    <div className="box">
                      <div className="columns">
                        <div className="column">
                          {cateringVendor.name}
                        </div>
                        <div className="column is-narrow">
                          <button className="button is-small" type="button" onClick={() => setAssignedCateringVendor([...assignedCateringVendor, Number(cateringVendor.id)])}>
                            <div className="icons is-small">
                              <FontAwesomeIcon icon={faPlusCircle} />
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default StoreConnector(Voucher)
