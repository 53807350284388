import { useEffect } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import Dashboard, { MenuListInterface } from "../layouts/Dashboard"
import { StoreConnector, StoreProps } from "../redux/Actions"
import SpecialityList from "./trainer/SpecialityList"
import ShiftList from "./trainer/ShiftList"
import TrainerOrder from "./trainer/TrainerOrder"
import TrainerProfile from "./trainer/TrainerProfile"

function TrainerDashboard(props: StoreProps) {
  const { privilege } = props

  const menuList: Array<MenuListInterface> = [
    {
      label: 'Management',
      links: [
        // {
        //   title: 'Speciality',
        //   path: '/trainer/speciality'
        // },
        {
          title: 'Schedule',
          path: '/trainer/schedule'
        }
      ]
    },
    {
      label: 'Order',
      links: [
        {
          title: 'Online Order',
          path: '/trainer/order/online'
        },
        {
          title: 'Offline Order',
          path: '/trainer/order/offline'
        }
      ]
    },
    {
      label: 'Account',
      links: [
        {
          title: 'Profile',
          path: '/trainer/profile'
        }
      ]
    }
  ]

  useEffect(() => {
    document.title = 'Trainer Dashboard'
  }, [])

  if (privilege !== 'trainer') {
    return (
      <Redirect to="/auth" />
    )
  }

  return (
    <Dashboard menuList={menuList}>
      <Switch>
        <Route path="/trainer/schedule">
          <ShiftList/>
        </Route>
        <Route path="/trainer/speciality">
          <SpecialityList/>
        </Route>
        <Route path="/trainer/order/:type">
          <TrainerOrder/>
        </Route>
        <Route path="/trainer/profile">
          <TrainerProfile/>
        </Route>
      </Switch>
    </Dashboard>
  )
}

export default StoreConnector(TrainerDashboard)