import { ActionInterface } from "../Actions"
import { ActionTypes } from "../ActionTypes"

export default function Auth(state: string|null = null, action: ActionInterface<string>): string|null {
  switch (action.type) {
    case ActionTypes.Login:
      sessionStorage.setItem('token', action.payload)
      state = action.payload
      break

    case ActionTypes.Logout:
      sessionStorage.removeItem('token')
      state = null
      break

    default:
      state = sessionStorage.getItem('token')
  }

  return state
}