import { faCheckCircle, faEye, faLink, faMap, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"
import ConvertStatus from "../../Tools.ts/ConvertStatus"

const API2 = process.env.REACT_APP_API2

interface OrderListItem {
  id: number
  customer_phone: string
  customer_email: string
  customer_name: string
  trainer_name: string
  speciality_name: string
  zoom_link: string
  price: string
  service_fee: string
  type: string
  date: string
  start_time: string
  end_time: string
  latitude: string
  longitude: string
  voucher_code: string
  payment_method: string
  status: string
}

function TrainerOrder(props: StoreProps) {
  const { token } = props
  const { type } = useParams<{type: string}>()
  const history = useHistory()

  const [orderList, setOrderList] = useState<Array<OrderListItem>>([])
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(Date.now())
  const [focusedOrderId, setFocusedOrderId] = useState<number>()
  const [focusedOrder, setFocusedOrder] = useState<OrderListItem>()
  const [focusedStatus, setFocusedStatus] = useState<string>('PAID')
  const [status, setStatus] = useState<string>('')
  const [showUpdateStatusModal, setUpdateModalVisibility] = useState<boolean>(false)
  const [showDetailModal, setDetailModalVisibility] = useState<boolean>(false)

  const updateStatus = (id: number) => {
    setUpdateModalVisibility(true)
    setFocusedOrderId(id)
  }

  const doUpdateStatus = () => {
    if (status === '' || !token) {
      return
    }

    const form = new URLSearchParams()
    form.append('id', String(focusedOrderId))
    form.append('status', status)

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        'Authorization': token
      },
      body: form
    }

    fetch(`${API2}/trainer/order/status`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.message === 'ERROR') {
          toast(response.result, {type: 'error'})
          return
        }
        setUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Gagal update data!', {type: 'error'}))
      .finally(() => setUpdateModalVisibility(false))
  }

  const displayDetail = (id: number) => {
    setDetailModalVisibility(true)
    setFocusedOrder(orderList.find(order => order.id === id))
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        'Authorization': token
      }
    }

    fetch(`${API2}/trainer/order`, fetchInitOpt)
      .then(response => response.json())
      .then((response: Array<OrderListItem>) => {
        setOrderList(response)
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }, [token, updateTimestamp])

  useEffect(() => {
    const url = new URLSearchParams(history.location.search)
    const open = url.get('open')

    if (open !== null && orderList.length > 0) {
      setFocusedOrder(orderList.find(data => data.id === Number(open)))
      setDetailModalVisibility(true)
    }
  }, [history.location.search, orderList])

  return (
    <div className="mt-3">
      <div className="box">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h4 className="title is-size-4">Daftar Pesanan {type.toUpperCase()}</h4>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <a href="http://dashboard.mufit.id/public/question/cc5ff608-62d0-4668-8ecb-605c0c25019b" className="button is-small" target="_blank" rel="noreferrer">
                Laporan Pemesanan Trainer
              </a>
            </div>
          </div>
        </div>
        <div className="my-1">
          {['AWAITING_PAYMENT', 'PAID', 'ACCEPT', 'REJECT', 'ON_GOING', 'DONE'].map(status => (
            <button key={status} className={`button is-small mr-1 ${status === focusedStatus ? 'is-primary' : ''}`} onClick={() => setFocusedStatus(status)}>
              <span>{ConvertStatus(status)}</span>
            </button>
          ))}
        </div>
        <div style={{overflowX: 'auto'}}>
          <table className="table is-bordered is-fullwidth" style={{whiteSpace: 'nowrap'}}>
            <thead>
              <tr>
                <th>Customer</th>
                <th>No. HP</th>
                <th>{type === 'online' ? 'Link Zoom' : 'Lokasi'}</th>
                <th>Tanggal</th>
                <th>Jadwal</th>
                <th>Metode Pembayaran</th>
                <th>Status</th>
                <th className="is-narrow"></th>
              </tr>
            </thead>
            <tbody>
              {orderList.filter(order => order.type === type.toUpperCase() && order.status === focusedStatus).length < 1 && (
                <tr>
                  <td colSpan={8} className="has-text-centered">Belum Ada Data</td>
                </tr>
              )}
              {orderList.filter(order => order.type === type.toUpperCase() && order.status === focusedStatus).map(order => (
                <tr key={order.id}>
                  <td>{order.customer_name}</td>
                  <td>{order.customer_phone}</td>
                  {type === 'online' && (
                    <td><a href={order.zoom_link} target="_blank" rel="noreferrer">Link Zoom <FontAwesomeIcon icon={faLink}/></a></td>
                  )}
                  {type === 'offline' && (
                    <td><a href={`https://www.google.com/maps/search/?api=1&query=${order.latitude},${order.longitude}&zoom=12`} target="_blank" rel="noreferrer">Google Maps <FontAwesomeIcon icon={faMap}/></a></td>
                  )}
                  <td>{order.date}</td>
                  <td>{order.start_time} - {order.end_time}</td>
                  <td>{order.payment_method}</td>
                  <td>{ConvertStatus(order.status)}</td>
                  <td>
                    <button className="button is-small is-primary mr-1" onClick={() => displayDetail(order.id)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faEye}/>
                      </div>
                      <span>Detail</span>
                    </button>
                    <button className="button is-small is-primary" onClick={() => updateStatus(order.id)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faCheckCircle}/>
                      </div>
                      <span>Update Status</span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showUpdateStatusModal && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setUpdateModalVisibility(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">Ubah Status</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-small is-danger" onClick={() => setUpdateModalVisibility(false)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTimes}/>
                      </div>
                      <span>Tutup</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="field">
                <div className="select is-fullwidth">
                  <select value={status} onChange={ev => setStatus(ev.target.value)}>
                    <option value="">Pilih Status</option>
                    <option value="ACCEPT">Terima</option>
                    <option value="REJECT">Tolak</option>
                    <option value="ON_GOING">Berlangsung</option>
                    <option value="DONE">Selesai</option>
                  </select>
                </div>
              </div>
              <div className="has-text-centered">
                <button className="button is-primary" onClick={doUpdateStatus}>
                  <div className="icon is-small is-left">
                    <FontAwesomeIcon icon={faCheckCircle}/>
                  </div>
                  <span>Terapkan</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showDetailModal && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setDetailModalVisibility(false)}></div>
          <div className="modal-content">
            <div className="box m-3">
              <div className="columns is-mobile">
                <div className="column">
                  <strong>Detail Pesanan {focusedOrder?.type.toUpperCase()}</strong>
                </div>
                <div className="column is-narrow">
                  <button className="button is-small is-left is-primary" onClick={() => setDetailModalVisibility(false)}>
                    <div className="icon is-small is-left">
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </div>
                    <span>Tutup</span>
                  </button>
                </div>
              </div>
              <table className="table is-fullwidth">
                <tbody>
                  <tr>
                    <td className="is-narrow">Nama Trainer&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    <td className="is-narrow">:</td>
                    <td>{focusedOrder?.trainer_name}</td>
                  </tr>
                  <tr>
                    <td>Speciality</td>
                    <td>:</td>
                    <td>{focusedOrder?.speciality_name}</td>
                  </tr>
                  <tr>
                    <td>Nama Customer</td>
                    <td>:</td>
                    <td>{focusedOrder?.customer_name}</td>
                  </tr>
                  <tr>
                    <td>No. HP Customer</td>
                    <td>:</td>
                    <td>{focusedOrder?.customer_phone}</td>
                  </tr>
                  <tr>
                    <td>Email Customer</td>
                    <td>:</td>
                    <td>{focusedOrder?.customer_email}</td>
                  </tr>
                  {focusedOrder?.type === 'ONLINE' && (
                    <tr>
                      <td>Link Zoom</td>
                      <td>:</td>
                      <td><a href={focusedOrder?.zoom_link} target="_blank" rel="noreferrer">Buka Zoom</a></td>
                    </tr>
                  )}
                  {focusedOrder?.type === 'OFFLINE' && (
                    <tr>
                      <td>Lokasi</td>
                      <td>:</td>
                      <td><a href={`https://www.google.com/maps/search/?api=1&query=${focusedOrder?.latitude},${focusedOrder?.longitude}&zoom=12`} target="_blank" rel="noreferrer">Google Maps <FontAwesomeIcon icon={faMap}/></a></td>
                    </tr>
                  )}
                  <tr>
                    <td>Tanggal</td>
                    <td>:</td>
                    <td>{DateTime.fromJSDate(new Date(focusedOrder?.date ?? '')).setLocale('id-ID').toLocaleString(DateTime.DATE_FULL)}</td>
                  </tr>
                  <tr>
                    <td>Mulai</td>
                    <td>:</td>
                    <td>{focusedOrder?.start_time}</td>
                  </tr>
                  <tr>
                    <td>Selesai</td>
                    <td>:</td>
                    <td>{focusedOrder?.end_time}</td>
                  </tr>
                  <tr>
                    <td>Jenis Kelas</td>
                    <td>:</td>
                    <td>{type.toUpperCase()}</td>
                  </tr>
                  <tr>
                    <td>Voucher</td>
                    <td>:</td>
                    <td>{focusedOrder?.voucher_code}</td>
                  </tr>
                  <tr>
                    <td>Metode Pembayaran</td>
                    <td>:</td>
                    <td>{focusedOrder?.payment_method}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>:</td>
                    <td>{ConvertStatus(focusedOrder?.status ?? '-')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default StoreConnector(TrainerOrder)