import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormEvent, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import { API1Response } from "../interfaces/APIResponse"
import { StoreConnector, StoreProps } from "../redux/Actions"

const API2 = process.env.REACT_APP_API2

function Auth(props: StoreProps) {
  const { setAuth, setPrivilege: setPrivilegeRedux } = props
  const history = useHistory()

  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [privilege, setPrivilege] = useState<string>('')
  const [redirect, setRedirect] = useState<string>()

  const doLogin = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault()

    if (!ev.currentTarget.reportValidity()) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password
      })
    }

    if (privilege === 'vendor') {
      fetch(`${API2}/vendor/admin`, fetchInitOpt)
        .then(response => response.json())
        .then((response: API1Response) => {
          if (response.message === 'ERROR') {
            toast(response.result, {type: 'error'})
            return
          }

          setAuth(response.result)
          setPrivilegeRedux('vendor')

          history.push(redirect ?? '/vendor')
        })
        .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))

      return
    }

    fetch(`${API2}/login`, fetchInitOpt)
      .then(response => response.json())
      .then((response: API1Response<{accessToken: string, clientId: string}>) => {
        if (response.message !== 'OK') {
          toast('Username atau password salah!', {type: 'error'})
          return
        }

        if (response.result.clientId !== privilege) {
          toast('User tidak bisa login!', {type: 'error'})
          return
        }

        setAuth(response.result.accessToken)
        setPrivilegeRedux(response.result.clientId)
        history.push(redirect ?? `/${privilege}`)
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }

  useEffect(() => {
    document.title = 'Authentication Page'
    const url = new URLSearchParams(history.location.search)
    const type = url.get('type')
    const method = url.get('method')
    const id = url.get('id')
    const pathname = history.location.pathname

    if (pathname === '/order') {
      let urlRedirect: string

      switch (type) {
        case 'catering':
          urlRedirect = `/vendor/catering-order?open=${id}`
          break
        
        default:
          urlRedirect = `/${type}/order/${method}?open=${id}`
      }
      
      setRedirect(urlRedirect)
    }
  }, [history.location.pathname, history.location.search])

  return (
    <div className="section">
      <div className="columns is-centered">
        <div className="column is-4">
          <div className="box" style={{border: 'solid 1px #dfdfdf'}}>
            <h4 className="title is-size-4 has-text-centered">Halaman Login MUFIT</h4>
            <form onSubmit={doLogin}>
              <div className="field">
                <label htmlFor="">Username/Email</label>
                <div className="control">
                  <input
                    className="input"
                    placeholder="Username/Email"
                    autoFocus
                    value={username}
                    onChange={ev => setUsername(ev.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Password</label>
                <div className="control">
                  <input
                    type="password"
                    className="input"
                    placeholder="Password"
                    value={password}
                    onChange={ev => setPassword(ev.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Privilege</label>
                <div className="select is-fullwidth">
                  <select value={privilege} onChange={ev => setPrivilege(ev.target.value)} required>
                    <option value="" disabled>Select the privilege</option>
                    <option value="admin">Admin</option>
                    <option value="vendor">Vendor</option>
                    <option value="trainer">Trainer</option>
                    <option value="nutritionist">Nutritionist</option>
                  </select>
                </div>
              </div>
              <div className="columns">
                <div className="column"></div>
                <div className="column is-narrow">
                  <button className="button is-primary">
                    <div className="icon is-small is-left">
                      <FontAwesomeIcon icon={faCheckCircle}/>
                    </div>
                    <span>Masuk</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  )
}

export default StoreConnector(Auth)
