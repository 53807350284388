import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"

const API2 = process.env.REACT_APP_API2

interface BankListItem {
  id: number
  name: string
}

function TrainerProfile(props: StoreProps) {
  const { token } = props

  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [description, setDesscription] = useState<string>('')
  const [gender, setGender] = useState<string>('M')
  const [address, setAddress] = useState<string>('')
  const [latitude, setLatitude] = useState<string>('')
  const [longitude, setLongitude] = useState<string>('')
  const [noKTP, setNoKTP] = useState<string>('')
  const [noNPWP, setNoNPWP] = useState<string>('')
  const [noRek, setNoRek] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [photoCover, setPhotoCover] = useState<string>()
  const [photoTrainer, setPhotoTrainer] = useState<string>()
  const [photoKTP, setPhotoKTP] = useState<string>()
  const [photoNPWP, setPhotoNPWP] = useState<string>()
  const [bankId, setBankId] = useState<number>()
  const [linkZoom, setLinkZoom] = useState<string|null>(null)

  const [uploadedPhotoKTP, setUploadedPhotoKTP] = useState<File>()
  const [uploadedPhotoNPWP, setUploadedPhotoNPWP] = useState<File>()
  const [uploadedCover, setUploadedCover] = useState<File>()
  const [uploadedPhotoTrainer, setUploadedPhotoTrainer] = useState<File>()

  const [bankList, setBankList] = useState<Array<BankListItem>>([])

  const uploadHandler = (
    file: FileList,
    previewHandler: Dispatch<SetStateAction<string|undefined>>,
    stateHandler: Dispatch<SetStateAction<File|undefined>>
  ) => {
    if (file[0].size > 1000000) {
      toast('Ukuran file maksimal adalah 1MB', {type: 'error'})
      return
    }

    const reader = new FileReader()

    reader.onload = (ev: ProgressEvent<FileReader>) => {
      const result = ev.target?.result

      if (!result) {
        return
      }

      previewHandler(result.toString())
      stateHandler(file[0])
    }

    reader.readAsDataURL(file[0])
  }

  const submit = () => {
    if (!token) {
      return
    }

    const hasUploadedFile = uploadedPhotoKTP || uploadedPhotoNPWP || uploadedCover || uploadedPhotoTrainer

    const form = hasUploadedFile ? new FormData() : new URLSearchParams()

    form.append('name', name)
    form.append('email', email)
    form.append('password', password)
    form.append('address', address)
    form.append('latitude', latitude)
    form.append('longitude', longitude)
    form.append('description', description)
    form.append('gender', gender)
    form.append('no_ktp', noKTP)
    form.append('no_npwp', noNPWP)
    form.append('no_rek', noRek)
    form.append('bank_id', String(bankId))
    form.append('phone', phone)

    if (linkZoom) {
      form.append('link_zoom', linkZoom)
    }

    if (uploadedCover) {
      form.append('cover', uploadedCover)
    }

    if (uploadedPhotoTrainer) {
      form.append('photo_trainer', uploadedPhotoTrainer)
    }

    if (uploadedPhotoKTP) {
      form.append('photo_ktp', uploadedPhotoKTP)
    }

    if (uploadedPhotoNPWP) {
      form.append('photo_npwp', uploadedPhotoNPWP)
    }

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: form
    }

    fetch(`${API2}/trainer/profile`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal menyimpan data!', {type: 'error'})
          return
        }

        toast('Data berhasil disimpan!', {type: 'success'})
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        Authorization: token
      }
    }

    fetch(`${API2}/trainer/profile`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal mengambil data!', {type: 'error'})
          return
        }

        setName(response.name)
        setEmail(response.email)
        setDesscription(response.description)
        setGender(response.gender)
        setAddress(response.address)
        setLatitude(response.latitude)
        setLongitude(response.longitude)
        setNoKTP(response.no_ktp)
        setNoNPWP(response.no_npwp)
        setNoRek(response.no_rek)
        setPhone(response.phone)
        setPhotoCover(response.photo_cover)
        setPhotoTrainer(response.photo_trainer)
        setPhotoKTP(response.photo_ktp)
        setPhotoNPWP(response.photo_npwp)
        setBankId(response.bank_id)
        setLinkZoom(response.link_zoom)
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }, [token])

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        Authorization: token
      }
    }

    fetch(`${API2}/administration/bank`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal mengambil data!', {type: 'error'})
          return
        }

        setBankList(response)
      })
      .catch(() => toast('Terjadi kesalahan jaringan!', {type: 'error'}))
  }, [token])

  return (
    <div className="mt-3">
      <div className="columns is-centered">
        <div className="column is-8">
          <div className="box">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h4 className="title is-size-4">Ubah Profile</h4>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <button className="button is-small is-primary" onClick={submit}>
                    <div className="icon is-small is-left">
                      <FontAwesomeIcon icon={faCheckCircle}/>
                    </div>
                    <span>Simpan</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="field">
              <label htmlFor="">Nama</label>
              <div className="control">
                <input type="text" className="input" value={name} onChange={ev => setName(ev.target.value)}/>
              </div>
            </div>
            <div className="field">
              <label htmlFor="">Email</label>
              <div className="control">
                <input type="email" className="input" value={email} onChange={ev => setEmail(ev.target.value)} disabled />
              </div>
            </div>
            <div className="field">
              <label htmlFor="">Password</label>
              <div className="control">
                <input type="password" className="input" onChange={ev => setPassword(ev.target.value)} />
                <span className="has-text-info">Masukkan untuk mengganti password</span>
              </div>
            </div>
            <div className="field">
              <label htmlFor="">Deskripsi</label>
              <div className="control">
                <textarea className="textarea" value={description} onChange={ev => setDesscription(ev.target.value)}/>
              </div>
            </div>
            <div className="field">
              <label htmlFor="">Link Zoom</label>
              <div className="control">
                <input type="text" className="input" value={linkZoom ?? ''} onChange={ev => setLinkZoom(ev.target.value)} />
              </div>
            </div>
            <div className="field">
              <label htmlFor="">Gender</label>
              <div className="select is-fullwidth">
                <select value={gender} onChange={ev => setGender(ev.target.value)}>
                  <option value="M">Pria</option>
                  <option value="F">Wanita</option>
                </select>
              </div>
            </div>
            <div className="field">
              <label htmlFor="">Alamat</label>
              <div className="control">
                <input type="text" className="input" value={address} onChange={ev => setAddress(ev.target.value)} />
              </div>
            </div>
            <div className="columns mt-0">
              <div className="column">
                <label htmlFor="">Latitude</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    value={latitude}
                    onChange={ev => setLatitude(ev.target.value)}
                    step={0.000001}
                  />
                </div>
              </div>
              <div className="column">
                <label htmlFor="">Longitude</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    value={longitude}
                    onChange={ev => setLongitude(ev.target.value)}
                    step={0.000001}
                  />
                </div>
              </div>
            </div>
            <div className="field">
              <label htmlFor="">No. HP</label>
              <div className="control">
                <input type="text" className="input" value={phone} onChange={ev => setPhone(ev.target.value)}/>
              </div>
            </div>
            <div className="columns mt-1">
              <div className="column">
                <div className="field">
                  <label htmlFor="">No. Rekening</label>
                  <div className="control">
                    <input type="text" className="input" value={noRek} onChange={ev => setNoRek(ev.target.value)} />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label htmlFor="">Bank</label>
                  <div className="select is-fullwidth">
                    <select value={bankId} onChange={ev => setBankId(Number(ev.target.value))}>
                      <option value="">Pilih Bank</option>
                      {bankList.map(bank => (
                        <option value={bank.id} key={bank.id}>{bank.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns mt-0">
              <div className="column">
                <div className="field">
                  <label htmlFor="">No. KTP</label>
                  <div className="control">
                    <input type="text" className="input" value={noKTP} onChange={ev => setNoKTP(ev.target.value)} />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label htmlFor="">No. NPWP</label>
                  <div className="control">
                    <input type="text" className="input" value={noNPWP} onChange={ev => setNoNPWP(ev.target.value)} />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns mt-0">
              <div className="column">
                <div className="field">
                  <label htmlFor="">Foto KTP</label>
                  <div className="control">
                    <input
                      type="file"
                      className="input"
                      accept="image/*"
                      onChange={ev => ev.target.files !== null && uploadHandler(ev.target.files, setPhotoKTP, setUploadedPhotoKTP)}
                    />
                  </div>
                </div>
                {photoKTP && (
                  <img src={photoKTP} alt="" className="image mt-1" />
                )}
              </div>
              <div className="column">
                <div className="field">
                  <label htmlFor="">Foto NPWP</label>
                  <div className="control">
                    <input
                      type="file"
                      className="input"
                      accept="image/*"
                      onChange={ev => ev.target.files !== null && uploadHandler(ev.target.files, setPhotoNPWP, setUploadedPhotoNPWP)}
                    />
                  </div>
                </div>
                {photoNPWP && (
                  <img src={photoNPWP} alt="" className="image mt-1" />
                )}
              </div>
            </div>
            <div className="columns mt-0">
              <div className="column">
                <div className="field">
                  <label htmlFor="">Cover</label>
                  <div className="control">
                    <input
                      type="file"
                      className="input"
                      accept="image/*"
                      onChange={ev => ev.target.files !== null && uploadHandler(ev.target.files, setPhotoCover, setUploadedCover)}
                    />
                  </div>
                </div>
                {photoCover && (
                  <img src={photoCover} alt="" className="image mt-1" />
                )}
              </div>
              <div className="column">
                <div className="field">
                  <label htmlFor="">Foto Trainer</label>
                  <div className="control">
                    <input
                      type="file"
                      className="input"
                      accept="image/*"
                      onChange={ev => ev.target.files !== null && uploadHandler(ev.target.files, setPhotoTrainer, setUploadedPhotoTrainer)}
                    />
                  </div>
                </div>
                {photoTrainer && (
                  <img src={photoTrainer} alt="" className="image mt-1" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoreConnector(TrainerProfile)