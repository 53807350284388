import { faCheckCircle, faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"

const API2 = process.env.REACT_APP_API2

interface CateringPackageOrderItem {
  id: number
  full_name: string
  phone: string
  email: string
  photo_selfie?: string
  price: number
  payment_fee: number
  session_start_date: string
  longitude: string
  latitude: string
  status: string
}

function CateringPackageOrder(props: StoreProps) {
  const { token } = props

  const [cateringOrderList, setCateringOrderList] = useState<Array<CateringPackageOrderItem>>([])
  const [focusedId, setFocusedId] = useState<number>(0)
  const [updateStatusModal, setUpdateStatusModal] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('')
  const [updateData, setUpdateData] = useState<number>(Date.now())

  const updateStatus = (id: number) => {
    setFocusedId(id)
    setUpdateStatusModal(true)
  }

  const submitStatus = () => {
    if (!token) {
      return
    }

    const form = new URLSearchParams()
    form.append('id', String(focusedId))
    form.append('status', status)

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: form
    }

    fetch(`${API2}/vendor/food-package-order/status`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal menyimpan status!', {type: 'error'})
          return
        }

        toast('Status berhasil disimpan!', {type: 'success'})
        setUpdateData(Date.now())
        setUpdateStatusModal(false)
      })
      .catch(() => toast('Gagal menyimpan status!', {type: 'error'}))
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    fetch(`${API2}/vendor/food-package-order`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        setCateringOrderList(response)
      })
      .catch(() => toast('Gagal mengambil data!', {type: 'error'}))
  }, [token, updateData])

  return (
    <Fragment>
      <div className="mt-3">
        <div className="box">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h4 className="title is-size-4">Pesanan Paket Makanan</h4>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                
              </div>
            </div>
          </div>
          <div style={{overflowX: 'auto'}}>
            <table className="table is-bordered is-fullwidth" style={{whiteSpace: 'nowrap'}}>
              <thead>
                <tr>
                  <th>Pemesan</th>
                  <th>No. HP</th>
                  <th>Email</th>
                  <th>Harga</th>
                  <th>Lokasi</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {cateringOrderList.map(order => (
                  <tr key={order.id}>
                    <td>{order.full_name}</td>
                    <td>{order.phone}</td>
                    <td>{order.email}</td>
                    <td>{order.price}</td>
                    <td>{order.latitude},{order.longitude}</td>
                    <td>{order.status}</td>
                    <td>
                      <button className="button is-small is-primary" onClick={() => updateStatus(order.id)}>
                        <div className="icon is-small is-left">
                          <FontAwesomeIcon icon={faPencilAlt}/>
                        </div>
                        <span>Update Status</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {updateStatusModal && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setUpdateStatusModal(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">Ubah Status</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-small" onClick={() => setUpdateStatusModal(false)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTimes}/>
                      </div>
                      <span>Batal</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="">Pilih Status</label>
                <div className="select is-fullwidth">
                  <select onChange={ev => setStatus(ev.target.value)}>
                    <option value="">Pilih status</option>
                    <option value="PROCESSED">PROCESSED</option>
                    <option value="SENT">SENT</option>
                    <option value="DONE">DONE</option>
                  </select>
                </div>
              </div>
              <div className="has-text-centered">
                <button className="button is-small is-primary" onClick={submitStatus}>
                  <div className="icon is-small is-left">
                    <FontAwesomeIcon icon={faCheckCircle}/>
                  </div>
                  <span>Terapkan</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default StoreConnector(CateringPackageOrder)