import { useEffect } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { toast } from "react-toastify"
import { API1Response } from "../interfaces/APIResponse"
import UserInterface from "../interfaces/UserInterface"
import Dashboard, { MenuListInterface } from "../layouts/Dashboard"
import { StoreConnector, StoreProps } from "../redux/Actions"
import CateringOrder from "./vendor/CateringOrder"
import CateringPackageOrder from "./vendor/CateringPackageOrder"
import FoodForm from "./vendor/FoodForm"
import FoodList from "./vendor/FoodList"
import FoodPackageForm from "./vendor/FoodPackageForm"
import FoodPackageList from "./vendor/FoodPackageList"
import StoreProperties from "./vendor/StoreProperties"
import VendorOffDay from "./vendor/VendorOffDay"

const API2 = process.env.REACT_APP_API2

function CateringVendorDashboard(props: StoreProps) {
  const { token, privilege, setProfile } = props

  const menuList: Array<MenuListInterface> = [
    {
      label: 'Menu',
      links: [
        {
          title: 'Dashboard',
          path: '/vendor'
        },
        {
          title: 'Daftar Makanan',
          path: '/vendor/food'
        },
        // {
        //   title: 'Daftar Menu',
        //   path: '/vendor/package'
        // },
        {
          title: 'Hari Libur',
          path: '/vendor/offday'
        }
      ]
    },
    {
      label: 'Pesanan',
      links: [
        {
          title: 'Pesanan Makanan',
          path: '/vendor/catering-order'
        },
        // {
        //   title: 'Pesanan Paket',
        //   path: '/vendor/catering-package-order'
        // }
      ]
    }
  ]

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    fetch(`${API2}/vendor/profile`, fetchInitOpt)
      .then(response => response.json())
      .then((response: API1Response<UserInterface>) => {
        if (response.message !== 'OK') {
          toast('Gagal mengambil data pengguna!', {type: 'error'})
          return
        }

        setProfile(response.result)
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    document.title = 'Catering Vendor Admin Dashboard'
  }, [])

  if (privilege !== 'vendor') {
    return (
      <Redirect to="/auth" />
    )
  }

  return (
    <Dashboard menuList={menuList}>
      <Switch>
        <Route path="/vendor/offday">
          <VendorOffDay />
        </Route>
        <Route path="/vendor/catering-package-order">
          <CateringPackageOrder/>
        </Route>
        <Route path="/vendor/catering-order">
          <CateringOrder/>
        </Route>
        <Route path={['/vendor/package/form/:id', '/vendor/package/form']}>
          <FoodPackageForm/>
        </Route>
        <Route path="/vendor/package">
          <FoodPackageList/>
        </Route>
        <Route path={['/vendor/food/form/:id', '/vendor/food/form']}>
          <FoodForm/>
        </Route>
        <Route path="/vendor/food">
          <FoodList/>
        </Route>
        <Route path="/vendor">
          <StoreProperties/>
        </Route>
      </Switch>
    </Dashboard>
  )
}

export default StoreConnector(CateringVendorDashboard)