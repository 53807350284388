import { ActionInterface } from "../Actions"
import { ActionTypes } from "../ActionTypes";

export default function Privilege(state: string|null = null, action: ActionInterface<string>): string|null {
  switch (action.type) {
    case ActionTypes.SetPrivilege:
      sessionStorage.setItem('privilege', action.payload)
      state = action.payload
      break

    default:
      state = sessionStorage.getItem('privilege')
  }

  return state
}
