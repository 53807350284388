import { useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import AdminDashboard from "./pages/AdminDashboard"
import Auth from "./pages/Auth"
import CateringVendorDashboard from "./pages/CateringVendorDashboard"
import DoMonitor from "./pages/DoMonitor"
import Main from "./pages/Main"
import NutritionistDashboard from "./pages/NutritionistDashboard"
import TrainerDashboard from "./pages/TrainerDashboard"
import { StoreConnector, StoreProps } from "./redux/Actions"
import 'react-quill/dist/quill.snow.css'

function App(props: StoreProps) {
  const { setAuth } = props

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const token = sessionStorage.getItem('token')

    if (token) {
      setAuth(token)
    }

    setLoading(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-content">
          <progress className="progress is-primary" max={100}>Loading...</progress>
        </div>
      </div>
    )
  }

  return (
    <Router>
      <Switch>
        <Route path={['/auth', '/order']}>
          <Auth/>
        </Route>
        <Route path="/vendor">
          <CateringVendorDashboard/>
        </Route>
        <Route path="/nutritionist">
          <NutritionistDashboard/>
        </Route>
        <Route path="/trainer">
          <TrainerDashboard/>
        </Route>
        <Route path="/admin/do-monitor/:prefix/:user_id">
          <DoMonitor/>
        </Route>
        <Route path="/admin">
          <AdminDashboard/>
        </Route>
        <Route path="/">
          <Main/>
        </Route>
      </Switch>
    </Router>
  )
}

export default StoreConnector(App)
