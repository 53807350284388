import { faPencilAlt, faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"

const API2 = process.env.REACT_APP_API2

interface PackageItem {
  id: number
  name: string
  description: string
}

function FoodPackageList(props: StoreProps) {
  const { token, profile } = props

  const [packageList, setPackageList] = useState<Array<PackageItem>>([])
  const [updateData, setUpdateData] = useState<number>(Date.now())

  const remove = (id: number) => {
    if (!token || !window.confirm('Anda yakin akan menghapus data ini?')) {
      return
    }

    const fetchInitOpt: RequestInit = {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    fetch(`${API2}/vendor/catering/menu/${id}`, fetchInitOpt)
      .then(response => response.json())
      .then(() => {
        setUpdateData(Date.now())
      })
      .catch(() => toast('Gagal menghapus data!', {type: 'error'}))
  }

  useEffect(() => {
    if (!profile?.vendor_id) {
      return
    }

    fetch(`${API2}/catering/menu/${profile.vendor_id}`)
      .then(response => response.json())
      .then((response: Array<PackageItem>) => {
        setPackageList(response)
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }, [profile?.vendor_id, updateData])

  return (
    <div className="mt-3">
      <div className="box">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h4 className="title is-size-4">Food Package List</h4>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <Link to="/vendor/package/form" className="button is-small is-primary">
                <div className="icon is-small is-left">
                  <FontAwesomeIcon icon={faPlusCircle}/>
                </div>
                <span>Tambah Menu</span>
              </Link>
            </div>
          </div>
        </div>
        <div style={{overflowX: 'auto'}}>
          <table className="table is-fullwidth is-bordered" style={{whiteSpace: 'nowrap'}}>
            <thead>
              <tr>
                <th>Nama</th>
                <th>Keterangan</th>
                <th style={{width: 150}}>Aksi</th>
              </tr>
            </thead>
            <tbody>
              {packageList.map(packageData => (
                <tr key={packageData.id}>
                  <td>{packageData.name}</td>
                  <td>{packageData.description}</td>
                  <td>
                    <Link to={`/vendor/package/form/${packageData.id}`} className="button is-small is-primary">
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faPencilAlt}/>
                      </div>
                      <span>Edit</span>
                    </Link>
                    <button className="button is-small is-danger ml-1" onClick={() => remove(packageData.id)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTrashAlt}/>
                      </div>
                      <span>Hapus</span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default StoreConnector(FoodPackageList)