import { faCheckCircle, faFileUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useRef, useState } from "react"
import { StoreConnector, StoreProps } from "../redux/Actions"
import { Workbook } from "exceljs"
import { toast } from "react-toastify"

const API2 = process.env.REACT_APP_API2

interface RevisionListItem {
  email: string
  date: string
  revision: string
}

function FixSteps(props: StoreProps) {
  const { token } = props

  const [revisionList, setRevisionList] = useState<Array<RevisionListItem>>([])

  const uploadFieldRef = useRef<HTMLInputElement>(null)

  const readFile = () => {
    const files = uploadFieldRef.current?.files

    if (!files) {
      return
    }

    const reader = new FileReader()
    
    reader.onload = async (ev: ProgressEvent<FileReader>) => {
      const result = ev.target?.result

      if (!result || typeof result === 'string') {
        return
      }

      const workbook = new Workbook()

      workbook.xlsx.load(result)
        .then(loadedData => {
          const worksheet = loadedData.getWorksheet(1)
          const tmpArray: Array<RevisionListItem> = []

          for (let i = 2; i <= worksheet.rowCount; i++) {
            const row = worksheet.getRow(i)

            tmpArray.push({
              email: row.getCell(1).text,
              date: row.getCell(3).text,
              revision: row.getCell(5).text
            })
          }

          setRevisionList(tmpArray)
        })
        .catch(() => toast('Gagal memuat file!', {type: 'error'}))
    }

    reader.readAsArrayBuffer(files[0])
  }

  const doRevision = () => {
    if (!token) {
      return
    }

    const form = new URLSearchParams()

    revisionList.map((revision, index) => {
      form.append(`revision[${index}][email]`, revision.email)
      form.append(`revision[${index}][date]`, revision.date)
      form.append(`revision[${index}][revision]`, revision.revision)

      return undefined
    })

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: form
    }

    fetch(`${API2}/administration/steps-revision`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          toast('Gagal menyimpan data!', {type: 'error'})
          return
        }

        toast('Berhasil merevisi langkah!', {type: 'success'})
      })
      .catch(() => toast('Terjadi kegagalan jaringan!', {type: 'error'}))
  }

  return (
    <Fragment>
      <input ref={uploadFieldRef} type="file" accept=".xlsx" style={{display: 'none'}} onChange={readFile} />
      <div className="mt-3">
        <div className="columns is-centered">
          <div className="column is-8">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">Fix Steps Counter</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    {revisionList.length > 0 && (
                      <button className="button is-small is-primary mr-1" onClick={doRevision}>
                        <div className="icon is-small">
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </div>
                        <span>Lanjutkan</span>
                      </button>
                    )}
                    <button className="button is-small is-primary" onClick={() => uploadFieldRef.current?.click()}>
                      <div className="icon is-small">
                        <FontAwesomeIcon icon={faFileUpload} />
                      </div>
                      <span>Upload Revisi</span>
                    </button>
                  </div>
                </div>
              </div>
              <div style={{overflowX: 'auto'}}>
                <table className="table is-bordered is-fullwidth" style={{whiteSpace: 'nowrap'}}>
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Tanggal</th>
                      <th>Revisi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {revisionList.map(revision => (
                      <tr key={revision.email + revision.date}>
                        <td>{revision.email}</td>
                        <td>{revision.date}</td>
                        <td>{revision.revision}</td>
                      </tr>
                    ))}
                    {revisionList.length < 1 && (
                      <tr>
                        <td colSpan={3} className="has-text-centered">Belum ada data</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default StoreConnector(FixSteps)
