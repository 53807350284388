import { connect, ConnectedProps } from "react-redux"
import UserInterface from "../interfaces/UserInterface"
import { ActionTypes } from "./ActionTypes"
import Store from "./Store"

const mapStateToProps = (state: ReturnType<typeof Store.getState>) => ({
  token: state.Auth,
  urlPrefix: state.UrlPrefix,
  profile: state.UserProfile,
  privilege: state.Privilege
})

const mapDispatchToProps = {
  setAuth: (token: string) => ({
    type: ActionTypes.Login,
    payload: token
  }),
  setLogout: () => ({
    type: ActionTypes.Logout
  }),
  setUrlPrefix: (urlPrefix: string) => ({
    type: ActionTypes.SetURLPrefix,
    payload: urlPrefix
  }),
  setProfile: (profile: UserInterface) => ({
    type: ActionTypes.SetProfile,
    payload: profile
  }),
  setPrivilege: (privilege: string) => ({
    type: ActionTypes.SetPrivilege,
    payload: privilege
  })
}

export const StoreConnector = connect(mapStateToProps, mapDispatchToProps)
export type StoreProps = ConnectedProps<typeof StoreConnector>

export interface ActionInterface<T = {[title: string]: any}|null> {
  type: ActionTypes
  payload: T
}