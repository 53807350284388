import { faCheckCircle, faEye, faLink, faMap, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DateTime } from "luxon"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { StoreConnector, StoreProps } from "../../redux/Actions"
import ConvertStatus from "../../Tools.ts/ConvertStatus"

const API2 = process.env.REACT_APP_API2

interface OrderListItem {
  id: number
  nutritionist_name: string
  customer_phone: string
  customer_email: string
  customer_name: string
  zoom_link: string
  price: string
  service_fee: string
  type: string
  date: string
  speciality: string
  start_time: string
  end_time: string
  latitude: string
  longitude: string
  voucher_code: string
  evidence?: string
  payment_method: string
  status: string
}

function NutritionistOrder(props: StoreProps) {
  const { token } = props
  const { type } = useParams<{type: string}>()
  const history = useHistory()

  const [orderList, setOrderList] = useState<Array<OrderListItem>>([])
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(Date.now())
  const [focusedOrderId, setFocusedOrderId] = useState<number>()
  const [focusedOrder, setFocusedOrder] = useState<OrderListItem>()
  const [focusedStatus, setFocusedStatus] = useState<string>('PAID')
  const [displayOrderDetail, setDisplayOrderDetail] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('')
  const [evidencePreview, setEvidencePreview] = useState<string>()
  const [evidenceFile, setEvidenceFile] = useState<File>()
  const [showUpdateStatusModal, setUpdateModalVisibility] = useState<boolean>(false)
  const statusFormRef = useRef<HTMLFormElement>(null)

  const handleEvidenceUpload = (ev: ChangeEvent<HTMLInputElement>) => {
    const files = ev.target.files

    if (!files || files.length < 1) {
      return
    }

    const reader = new FileReader()

    reader.onload = (ev: ProgressEvent<FileReader>) => {
      const result = ev.target?.result

      if (!result || typeof result !== 'string') {
        return
      }

      setEvidenceFile(files[0])
      setEvidencePreview(result)
    }

    reader.readAsDataURL(files[0])
  }

  const updateStatus = (id: number) => {
    setUpdateModalVisibility(true)
    setFocusedOrderId(id)
  }

  const doUpdateStatus = () => {
    if (status === '' || !token || !statusFormRef.current?.reportValidity()) {
      return
    }

    const form = evidenceFile ? new FormData() : new URLSearchParams()

    form.append('id', String(focusedOrderId))
    form.append('status', status)

    if (evidenceFile) {
      form.append('evidence', evidenceFile)
    }

    const fetchInitOpt: RequestInit = {
      method: 'POST',
      headers: {
        'Authorization': token
      },
      body: form
    }

    fetch(`${API2}/nutritionist/order/status`, fetchInitOpt)
      .then(response => response.json())
      .then(response => {
        if (response.error === true) {
          toast(response.result, {type: 'error'})
          return
        }

        setUpdateTimestamp(Date.now())
      })
      .catch(() => toast('Gagal update data!', {type: 'error'}))
      .finally(() => setUpdateModalVisibility(false))
  }

  const displayDetail = (id: number) => {
    setDisplayOrderDetail(true)
    setFocusedOrder(orderList.find(order => order.id === id))
  }

  useEffect(() => {
    if (!token) {
      return
    }

    const fetchInitOpt: RequestInit = {
      headers: {
        'Authorization': token
      }
    }

    fetch(`${API2}/nutritionist/order`, fetchInitOpt)
      .then(response => response.json())
      .then((response: Array<OrderListItem>) => {
        setOrderList(response)
      })
      .catch(() => toast('Kegagalan jaringan!', {type: 'error'}))
  }, [token, updateTimestamp])

  useEffect(() => {
    const url = new URLSearchParams(history.location.search)
    const open = url.get('open')

    if (open !== null && orderList.length > 0) {
      setFocusedOrder(orderList.find(data => data.id === Number(open)))
      setDisplayOrderDetail(true)
    }
  }, [history.location.search, orderList])

  useEffect(() => {
    setEvidenceFile(undefined)
    setEvidencePreview('')
    setStatus('')
  }, [showUpdateStatusModal])

  return (
    <div className="mt-3">
      <div className="box">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h4 className="title is-size-4">Daftar Pesanan {type.toUpperCase()}</h4>
            </div>
          </div>
        </div>
        <div className="my-1">
          {['AWAITING_PAYMENT', 'PAID', 'ACCEPT', 'REJECT', 'ON_GOING', 'DONE'].map(status => (
            <button key={status} className={`button is-small mr-1 ${status === focusedStatus ? 'is-primary' : ''}`} onClick={() => setFocusedStatus(status)}>
              <span>{ConvertStatus(status)}</span>
            </button>
          ))}
        </div>
        <div style={{overflowX: 'auto'}}>
          <table className="table is-bordered is-fullwidth" style={{whiteSpace: 'nowrap'}}>
            <thead>
              <tr>
                <th>Customer</th>
                <th>No. HP</th>
                <th>{type === 'online' ? 'Link Zoom' : 'Lokasi'}</th>
                <th>Tanggal</th>
                <th>Jadwal</th>
                <th>Spesialis</th>
                <th>Status</th>
                <th className="is-narrow"></th>
              </tr>
            </thead>
            <tbody>
              {orderList.filter(order => order.type === type && order.status === focusedStatus).map(order => (
                <tr key={order.id}>
                  <td>{order.customer_name}</td>
                  <td>{order.customer_phone}</td>
                  {type === 'online' && (
                    <td><a href="/" target="_blank" rel="noreferrer">Link Zoom <FontAwesomeIcon icon={faLink}/></a></td>
                  )}
                  {type === 'offline' && (
                    <td><a href={`https://www.google.com/maps/search/?api=1&query=${order.latitude},${order.longitude}&zoom=12`} target="_blank" rel="noreferrer">Google Maps <FontAwesomeIcon icon={faMap}/></a></td>
                  )}
                  <td>{order.date}</td>
                  <td>{order.start_time} - {order.end_time}</td>
                  <td>{order.speciality}</td>
                  <td>{ConvertStatus(order.status)}</td>
                  <td>
                    <button className="button is-small is-primary mr-1" onClick={() => displayDetail(order.id)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faEye}/>
                      </div>
                      <span>Detail</span>
                    </button>
                    <button className="button is-small is-primary" onClick={() => updateStatus(order.id)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faCheckCircle}/>
                      </div>
                      <span>Update Status</span>
                    </button>
                  </td>
                </tr>
              ))}
              {orderList.filter(order => order.type === type && order.status === focusedStatus).length < 1 && (
                <tr>
                  <td className="has-text-centered" colSpan={9}>Belum adata pesanan</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {showUpdateStatusModal && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setUpdateModalVisibility(false)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h4 className="title is-size-4">Ubah Status</h4>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button className="button is-small is-danger" onClick={() => setUpdateModalVisibility(false)}>
                      <div className="icon is-small is-left">
                        <FontAwesomeIcon icon={faTimes}/>
                      </div>
                      <span>Tutup</span>
                    </button>
                  </div>
                </div>
              </div>
              <form ref={statusFormRef} className="mb-4">
                <div className="field">
                  <label htmlFor="">Status</label>
                  <div className="select is-fullwidth">
                    <select value={status} onChange={ev => setStatus(ev.target.value)} required>
                      <option value="">Pilih Status</option>
                      <option value="ACCEPT">Terima</option>
                      <option value="REJECT">Tolak</option>
                      <option value="ON_GOING">Berlangsung</option>
                      <option value="DONE">Selesai</option>
                    </select>
                  </div>
                </div>
                {status === 'DONE' && (
                  <div className="field">
                    <label htmlFor="">Bukti Kegiatan</label>
                    <div className="control">
                      <input
                        type="file"
                        className="input"
                        accept=".jpg"
                        required
                        onChange={handleEvidenceUpload}
                      />
                    </div>
                  </div>
                )}
                {evidencePreview && (
                  <img
                    src={evidencePreview}
                    alt=""
                    className="image"
                    style={{
                      width: 200,
                      height: 300,
                      objectFit: 'contain',
                      backgroundColor: '#f1f1f1'
                    }}
                  />
                )}
              </form>
              <button className="button is-primary" onClick={doUpdateStatus}>
                <div className="icon is-small is-left">
                  <FontAwesomeIcon icon={faCheckCircle}/>
                </div>
                <span>Terapkan</span>
              </button>
            </div>
          </div>
        </div>
      )}
      {displayOrderDetail && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setDisplayOrderDetail(false)}></div>
          <div className="modal-content">
            <div className="box m-3">
              <div className="columns is-mobile">
                <div className="column">
                  <strong>Detail Pesanana {type.toUpperCase()}</strong>
                </div>
                <div className="column is-narrow">
                  <button className="button is-small is-primary" onClick={() => setDisplayOrderDetail(false)}>
                    <div className="icon is-small is-left">
                      <FontAwesomeIcon icon={faTimesCircle}/>
                    </div>
                    <span>Tutup</span>
                  </button>
                </div>
              </div>
              <table className="table is-fullwidth">
                <tbody>
                  <tr>
                    <td className="is-narrow">Nama Nutritionist</td>
                    <td className="is-narrow">:</td>
                    <td>{focusedOrder?.nutritionist_name}</td>
                  </tr>
                  <tr>
                    <td>Speciality</td>
                    <td>:</td>
                    <td>{focusedOrder?.speciality}</td>
                  </tr>
                  <tr>
                    <td>Nama Customer</td>
                    <td>:</td>
                    <td>{focusedOrder?.customer_name}</td>
                  </tr>
                  <tr>
                    <td>No. HP Customer</td>
                    <td>:</td>
                    <td>{focusedOrder?.customer_phone}</td>
                  </tr>
                  <tr>
                    <td>Email Customer</td>
                    <td>:</td>
                    <td>{focusedOrder?.customer_email}</td>
                  </tr>
                  {focusedOrder?.type === 'online' && (
                    <tr>
                      <td>Link Zoom</td>
                      <td>:</td>
                      <td><a href={focusedOrder?.zoom_link} target="_blank" rel="noreferrer">Buka Zoom</a></td>
                    </tr>
                  )}
                  {focusedOrder?.type === 'offline' && (
                    <tr>
                      <td>Lokasi</td>
                      <td>:</td>
                      <td><a href={`https://www.google.com/maps/search/?api=1&query=${focusedOrder?.latitude},${focusedOrder?.longitude}&zoom=12`} target="_blank" rel="noreferrer">Google Maps <FontAwesomeIcon icon={faMap}/></a></td>
                    </tr>
                  )}
                  <tr>
                    <td>Tanggal</td>
                    <td>:</td>
                    <td>{DateTime.fromJSDate(new Date(focusedOrder?.date ?? '')).setLocale('id-ID').toLocaleString(DateTime.DATE_FULL)}</td>
                  </tr>
                  <tr>
                    <td>Mulai</td>
                    <td>:</td>
                    <td>{focusedOrder?.start_time}</td>
                  </tr>
                  <tr>
                    <td>Selesai</td>
                    <td>:</td>
                    <td>{focusedOrder?.end_time}</td>
                  </tr>
                  <tr>
                    <td>Jenis Kelas</td>
                    <td>:</td>
                    <td>{type.toUpperCase()}</td>
                  </tr>
                  <tr>
                    <td>Voucher</td>
                    <td>:</td>
                    <td>{focusedOrder?.voucher_code}</td>
                  </tr>
                  <tr>
                    <td>Metode Pembayaran</td>
                    <td>:</td>
                    <td>{focusedOrder?.payment_method}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>:</td>
                    <td>{ConvertStatus(focusedOrder?.status ?? '-')}</td>
                  </tr>
                </tbody>
              </table>
              {focusedOrder?.status === 'DONE' && (
                <a href={focusedOrder.evidence} target="_blank" rel="noreferrer">
                  <img
                    src={focusedOrder.evidence}
                    alt=""
                    style={{
                      width: 200,
                      height: 300,
                      objectFit: 'contain',
                      backgroundColor: '#1f1f1f'
                    }}
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default StoreConnector(NutritionistOrder)